import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { OnBoardingService } from '../../services/on-boarding.service';

@Component({
  selector: 'app-launch',
  templateUrl: './launch.component.html',
  styleUrls: ['./launch.component.css']
})
export class LaunchComponent implements OnInit {

  constructor(private authService: AuthenticationService,
    private onBoardingService: OnBoardingService) { }

  ngOnInit() {
    this.onBoardingService.removeOnboarding();
    this.authService.startAuthentication();
  }

}
