export class OAuthConnectResponse{
    Code : string;
    RealmId : string;
    State : string;
    Scope: string;
    Error: string;
    ErrorDescription: string;
    avaTaxUserId: string;
    isAlreadyConnected: boolean;
  }


  export class OAuthSessionResponse{
    session_Id : string;
    userId : string;
    isQboConnected : boolean;
    qboRealmId : string;
    realmId : string;
    isSetupComplete : boolean;
    source: string;
    userName: string;
    isAIConnected: boolean;
    isAvaTaxCompanyConfigured:boolean;
    env: string;
    val: string;
    ava: string;
    avau: string;
    avat : string;
    avai : string;
    avaut : string;
    isUsrAlw: boolean;
    curSen: string;
  }

  export class ExpireTokens{
    token : string;
    tokenType : string;
  }

