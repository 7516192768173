import { Injectable } from '@angular/core';
import { NgbDateAdapter, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class CustomAdapter extends NgbDateAdapter<string> {

  readonly DELIMITER = '/';

  fromModel(value: string | null): NgbDateStruct | null {
    if (value) {
      let date = value.split(this.DELIMITER);
      return {
        day: parseInt(date[1], 10),
        month: parseInt(date[0], 10),
        year: parseInt(date[2], 10)
      };
    }
    return null;
  }

  toModel(date: NgbDateStruct | null): string | null {
    //return date ? date.day + this.DELIMITER + date.month + this.DELIMITER + date.year : null;
    return date ? (date.month.toString().length == 1 ? date.month : date.month) + this.DELIMITER + (date.day.toString().length == 1 ? date.day : date.day) + this.DELIMITER + date.year : null;

  }
}


@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {

  readonly DELIMITER = '/';

  parse(value: string): NgbDateStruct | null {
    if (value) {
      let date = value.split(this.DELIMITER);
      return {
        day: parseInt(date[1], 10),
        month: parseInt(date[0], 10),
        year: parseInt(date[2], 10)
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    //return date ? date.month + this.DELIMITER + date.day + this.DELIMITER + date.year : '';
    return date ? (date.month.toString().length == 1 ? date.month : date.month) + this.DELIMITER + (date.day.toString().length == 1 ? date.day : date.day) + this.DELIMITER + date.year : '';

  }
}