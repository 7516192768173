import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { HomeComponent } from './home/home.component';
import { ConnectorSettingsComponent } from './connectorsetting/connectorsettings.component';
import { NexusSetupComponent } from './nexussetup/nexussetup.component';
import { CompanyPickerComponent } from './companypicker/companypicker.component';
import { LinkCompanyComponent } from './linkcompany/linkcompany.component';
import { AvaTaxLoginComponent } from './avataxlogin/avataxlogin.component';
import { InvalidSessionComponent } from './invalidsession/invalidsession.component';
import { ConnectToQBOComponent } from './ConnectToQBO/ConnectToQBO.component';
import { RouterModule } from '@angular/router';
import { AvataxlandingComponent } from './avataxlanding/avataxlanding.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NexusFilterPipe } from './nexussetup/nexusfilter.pipe';
import { NexusWhereContent } from './nexussetup/nexuswherecontent.pipe';
import { NexusWhyContent } from './nexussetup/nexuswhycontent.pipe';
//import { ReconciliationComponent } from './reconciliation/reconciliation.component'
import { NgbModule, NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import { DataTablesModule } from 'angular-datatables';
import { BatchAddressComponent } from './batchaddress/batchaddress.component';
import { ConnectorsbasicsettingsComponent } from './connectorsbasicsettings/connectorsbasicsettings.component';

@NgModule({
  declarations: [ 
    HomeComponent,
    ConnectorSettingsComponent,
    NexusSetupComponent,
    CompanyPickerComponent,
    LinkCompanyComponent,
    AvaTaxLoginComponent,
    InvalidSessionComponent,
    ConnectToQBOComponent,
    AvataxlandingComponent,
    NexusFilterPipe,
    NexusWhereContent,
    NexusWhyContent,
    ConnectorsbasicsettingsComponent
  ],
  imports: [
    CommonModule,
    RouterModule,   
    FormsModule,
    ReactiveFormsModule,
    DataTablesModule,
    NgbModule,
    NgbTooltipModule
  ],
  providers: [ DatePipe ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class ConnectorModule { }

