import { IHttpService } from 'src/app/shared/services/IHttpService';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { AppConfig } from '../../app.config';
import { Observable, throwError } from 'rxjs';
import { NexusSetup, AdditionalNexuses } from '../models/NexusSetup';
import { catchError } from 'rxjs/operators';
import { CommonService } from '../services/common.service';

@Injectable({
    providedIn:'root'
})
export class NexusSetupService implements IHttpService{
    private nexusSetupBaseUrl =  `${AppConfig.settings.avatax.api_base_url}/api/AvaTax`;

    constructor(private http: HttpClient, private commonService: CommonService) { }

    fetchNexus(id:string, authToken:string):Observable<NexusSetup>{
        const url = `${this.nexusSetupBaseUrl}/nexus/${id}`;
        const headers = new HttpHeaders().set('Authorization', `Bearer ${authToken}`)
        return this.http.get<NexusSetup>(url,{headers: headers}).pipe(catchError((e:any)=>this.handleError(e)));
    }

    enableSelectedNexus(id:string, selectedNexusSetupData : NexusSetup, authToken:string):Observable<any>{
        const url = `${this.nexusSetupBaseUrl}/nexus/${id}`;
        const headers = new HttpHeaders().set('Authorization', `Bearer ${authToken}`)
       
        return this.http.post(url,selectedNexusSetupData,{headers: headers}).pipe(catchError((e:any)=>this.handleError(e)));
    }

    handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('An error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          // The response body may contain clues as to what went wrong,
          console.error(
            `Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);

          this.commonService.unauthorizationRedirect(error);
          return throwError('unauthorized');
        }
        // return an observable with a user-facing error message
        return throwError(
          'Something went wrong, please try again later.');
      };
}