import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../shared/services/authentication.service';

@Component({
  selector: 'app-apphealth',
  templateUrl: './apphealth.component.html',
  styleUrls: ['./apphealth.component.css']
})
export class ApphealthComponent implements OnInit {

  AppHealthStatus : string = "Pending..."

  constructor(private authService : AuthenticationService) { 
    this.authService.showHeader(false);
  }

  ngOnInit() {
    this.authService.getAppHealthStatus().then((res)=>{
      this.AppHealthStatus = res
    }).catch((err)=>{
      this.AppHealthStatus = err
    })    
  }

}
