import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { AuthGuard } from 'src/app/shared/gaurds/auth.gaurds';
import { FormGroup,FormBuilder, ReactiveFormsModule, FormArray, Validators } from '@angular/forms';
import { configurationModel } from 'src/app/Connector/models/ConfigurationModel';
import { AvaTaxCompany, userStatusBody, calculationStatusBody,HomeConfigLanding } from 'src/app/Connector/models/avataxcompany';
import { avaTaxDetails, intuitDetails, configuration, avaTaxCompaniesModel, salesTaxItem } from '../models/QBOUsers';
import { Router, ActivatedRoute } from '@angular/router';
import { Constants } from '../../shared/models/Constants';
import { OAuthSessionResponse } from '../../shared/models/OAuthToken';
import { SpinnerService } from '../../shared/spinner/spinner.service';
import { CommonService } from '../services/common.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class HomeComponent implements OnInit {
  homeSettingForm: FormGroup;
  userStatusBody: userStatusBody = new userStatusBody;
  userConfig: HomeConfigLanding;
  avaTaxDetails: avaTaxDetails;
  intuitDetails: intuitDetails;
  configuration: configuration;
  avaTaxCompaniesModel: avaTaxCompaniesModel;
  salesTaxItem: salesTaxItem;
  calculationStatus: calculationStatusBody = new calculationStatusBody;
  oAuthToken: OAuthSessionResponse;

  errorMessage: any;
  constructor(private fb: FormBuilder, private router: Router, private route: ActivatedRoute, private authService: AuthenticationService, private authGuard: AuthGuard, private commonService: CommonService, private spinnerService: SpinnerService) { }
  isSetupComplete: boolean = false;
  checkedStatus: boolean = false;
  calculationActiveMessage: string = "Integration status (Calculation active) must be enabled for this feature.";
  sourceParam: string;
  isAccessAllowed: boolean;

  ngOnInit() {

    let realmId = localStorage.getItem('realmId');

    this.sourceParam = this.route.snapshot.paramMap.get("source");
    this.sourceParam = this.sourceParam == null ? "" : this.sourceParam;

    //let realm = this.route.snapshot.paramMap.get('realm');
    this.initiliazeFormModel();
    this.selectCompanyFromUtilityLink(realmId, true);


    localStorage.removeItem("search-text");
  }


  openHelpLink(url: string) {
    window.open(Constants[url], "_blank");
  }

  initiliazeFormModel() {
    this.homeSettingForm = this.fb.group({
      chkIntegrationStatus: [{ value: true, disabled: false }],
      hiddenIntegrationStatus: [{ value: '', disabled: false }],
      isEnableAddressValidation: [{ value: true, disabled: false }],

    });
  }

  checkUserCalculationStatus(userId: string, authToken: string) {
    this.authGuard.canActivate().then((res) => {
      this.authService.fetchUserConfigurationModel(userId, authToken).subscribe(
        (userConfig: HomeConfigLanding) => this.displayConfigSettings(userConfig),
        (error: any) => { this.errorMessage = <any>error; alert(this.errorMessage) },
        () => { }
      );
    })
  }

  displayConfigSettings(userConfig: HomeConfigLanding): void {
    if (userConfig) {

      this.userConfig = userConfig;
      if (this.userConfig.editConfig) {
        this.authGuard.canActivate().then((res) => {
          // Add Connector Setting page path here
          this.router.navigate(['/connectorsettings']);
        })
      }
      this.homeSettingForm.patchValue({
        chkIntegrationStatus: this.checkUserStatustoUpdate(this.userConfig.status),
        isEnableAddressValidation: this.userConfig.isEnableAddressValidation
      })

      this.authService.emitChange(true, userConfig.avaTaxUserName, userConfig.qboCompanyName)
    }
  }

  checkUserStatustoUpdate(status: string): boolean {
    if (status == "Active") {
      this.checkedStatus = true;
    }
    else {
      this.checkedStatus = false;
    }
    return this.checkedStatus;
  }

  displayCalculationSetting(calculationStatus: calculationStatusBody): void {
    if (calculationStatus) {
      this.calculationStatus = calculationStatus;
      this.homeSettingForm.patchValue({
        chkIntegrationStatus: this.checkUserStatustoUpdate(this.calculationStatus.calculationStatus),
      })
    }
  }

  toggleUserStatus() {
    let token = this.authService.fetchAIAuthTokenFromStorage()
    let calcStatus: boolean;
    var current = this;
    if (token) {
      this.authGuard.canActivate().then((res) => {
        current.spinnerService.show();
        calcStatus = !this.homeSettingForm.get('chkIntegrationStatus').value;
        this.userStatusBody.status = calcStatus;
        this.authService.UpdateCalculationStatus(token.userId, token.session_Id, this.userStatusBody).subscribe(
          (calculationStatus: calculationStatusBody) => {
            this.displayCalculationSetting(calculationStatus),
              current.spinnerService.hide();
          },
          (error: any) => {
            this.errorMessage = <any>error;
            alert(this.errorMessage);
            current.spinnerService.hide();
          },
          () => { }
        );
      })
    }
  }

  goBackToQBO(url) {
    window.open(url, "_blank");
  }

  selectCompanyFromUtilityLink(realmId, isAccessAllowed) {
    var current = this;
    this.oAuthToken = this.authService.fetchAIAuthTokenFromStorage();
    if (this.oAuthToken) {
      current.spinnerService.show();
      this.commonService.extendJwtDurationAsync(realmId, this.oAuthToken.session_Id).subscribe(
        (response: any) => {

          let tokenString = localStorage.getItem('qbo_session')
          if (tokenString != null || tokenString != undefined) {
            let token: OAuthSessionResponse = JSON.parse(tokenString)
            token.session_Id = response.jwtToken + '_' + new Date().getTime();
            token.realmId = realmId;
            token.userId = response.userId;
            token.userName = response.userName;
            token.isQboConnected = true;
            token.isSetupComplete = true;
            token.isUsrAlw = isAccessAllowed;
            localStorage.setItem('qbo_session', JSON.stringify(token));
            this.authService.emitChange(true, token.userName, '','', token.source);
            this.checkUserCalculationStatus(token.userId, response.jwtToken);
          }
          current.spinnerService.hide();
        },
        (error: any) => {
          this.errorMessage = <any>error;
          this.spinnerService.hide();
          if (this.errorMessage != "unauthorized")
            alert(this.errorMessage)
        },
        () => { }
      );

    }
  }

  reloadCurrentPage() {
    window.location.reload();

  }
}

