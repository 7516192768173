import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router"
import { AuthenticationService } from '../shared/services/authentication.service';
import { OAuthConnectResponse, OAuthSessionResponse } from '../shared/models/OAuthToken';
import { SpinnerService } from '../shared/spinner/spinner.service';
import { OnBoardingService } from '../shared/services/on-boarding.service';

@Component({
  selector: 'app-auth-callback',
  templateUrl: './auth-callback.component.html',
  styleUrls: ['./auth-callback.component.css']
})
export class AuthCallbackComponent implements OnInit {
  private state: string;
  private realmId: string;
  private code: string;
  private error: string;
  private oAuthToken: OAuthSessionResponse;
  private errorMessage: any;

  constructor(private authService: AuthenticationService, private route: ActivatedRoute,
    private spinnerService: SpinnerService, private onBoardingService: OnBoardingService) { }

  ngOnInit() {
    this.code = this.route.snapshot.queryParamMap.get("code"),
      this.error = this.route.snapshot.queryParamMap.get("error"),
      this.state = this.route.snapshot.queryParamMap.get("state"),
      this.realmId = this.route.snapshot.queryParamMap.get("realmId")

    if (this.code != null && this.state != null && this.realmId != null) {
      this.spinnerService.show();

      let tokenReq: OAuthConnectResponse = {
        Code: this.code,
        State: this.state,
        RealmId: this.realmId,
        Scope: "",
        Error: "",
        ErrorDescription: "",
        avaTaxUserId: "",
        isAlreadyConnected: null
      }

      var current = this;

      this.oAuthToken = this.authService.fetchAIAuthTokenFromStorage();
      if (this.oAuthToken) {
        tokenReq.avaTaxUserId = this.oAuthToken.avau;

        this.authService.validateIntuitCompany(tokenReq, this.oAuthToken.session_Id).subscribe(
          (response: any) => {
            if (response.isAlreadyConnected) {
              this.spinnerService.show();
              if (response.isAlreadyConnected) {
                if (confirm("This Intuit company is already connected with Avalara Connector by other administrator.\n If you want to change the administrator, then click OK, if not then click Cancel.") == true) {
                  current.authService.completeAuthentication(tokenReq).then(() => {
                  });
                }
                else {
                  window.close();
                }
              }
            }
            else {
              this.spinnerService.show();
              current.authService.completeAuthentication(tokenReq).then(() => {
              });
            }
          }
          , (error: any) => {
            this.errorMessage = <any>error;
            this.spinnerService.hide();
            if (this.errorMessage != "unauthorized")
              alert(this.errorMessage);
          },
          () => { this.spinnerService.hide() }
        );
      }
    } else if (this.error && this.state) {
      if (this.onBoardingService.isIntegrationStarted()) {
        this.onBoardingService.postMesssageToParent(JSON.stringify(this.onBoardingService.getOnboarding()));
      } else {
        //need to handle for when user using integration page to do integration
        console.log("");
      }
    }
  }
}
