
export class QboContextData{
   
    constructor(globalContext){
        this.email = globalContext.qbo.user.email;
        this.name = globalContext.qbo.user.name;
        this.isAdmin = globalContext.qbo.user.isAdmin;
        this.companyName = globalContext.qbo.companyName;
        this.realmId = globalContext.qbo.realmId;
        this.qboUserId = globalContext.params.userId;
    }
    email : string
    name : string
    isAdmin : boolean
    companyName : string
    realmId : string
    qboUserId : string

}

export class OriginAddressResponse{
    originAddress : any
    qboSalesTaxItemId : string
    qboChartOfAccountId : string
    integrationStatus : string
    errorMessage : string
    ecoSystemStatus : boolean
    avaTaxStartDate: string
}

export class QboDocumentData{

     constructor(globalModel, qboAvaTaxConfigInfo, productInfoParam, departmentInfo){
        //console.log("ProductInfo - "+JSON.stringify(this.productInfo, null, 4));
        if (globalModel.shippingInfo != null)
        this.shippingAddress = globalModel.shippingInfo.shippingAddress;

        if (globalModel.billingAddress != null)
        this.billingAddress = globalModel.billingAddress;

        this.customerId = globalModel.nameId;
        this.referenceNumber = globalModel.referenceNumber;
        this.txnType = globalModel.txnTypeId;
        this.txnId = globalModel.txnId;
        this.DocumentDate = globalModel.date;
        this.context = null;
        this.qboAvaTaxConfigInfo = qboAvaTaxConfigInfo;
        this.docDiscountInfo =new DiscountInfo(globalModel);
        this.isConsiderResalenumber = "True";
        this.DocumentLineItems = [];
        let departments:DepartmentInfo[] = departmentInfo.filter(x=>x.id == globalModel.deptId);
        if(departments && departments.length > 0){
            this.qboCustomDepartments = departments[0];
        }

        //Check if itemDetails present
        if (globalModel.itemDetails != null || globalModel.itemDetails.length > 0) {
            for (var i = 0; i < globalModel.itemDetails.length; i++) {

                var item = new DocumentLineItem(globalModel.itemDetails[i], globalModel.itemDetails[i].taxable == true);

                //var product = productInfoParam[item.itemId];
                var product = productInfoParam.filter(prd => prd.id === item.itemId);
               // console.log("ProductInfo indivisual - "+JSON.stringify(product, null, 4));
                //console.log("ItemId and name - "+ item.itemId +" "+item.description+" product "+ JSON.stringify(item.Product, null, 4));
                //item.Product = product != undefined ? product : null;
                item.Product = product && product.length > 0 ? product[0] : null

                this.DocumentLineItems.push(item);
            }
        }

        if (globalModel.shippingInfo != null && globalModel.shippingInfo != undefined) {
            if (globalModel.shippingInfo.shippingAmount != "" && globalModel.shippingInfo.shippingAmount != null) {
                var itemDetails = { itemId: null, quantity: "1", amount: globalModel.shippingInfo.shippingAmount, description: "", taxCodeId: "FR" };
                var item = new DocumentLineItem(itemDetails, false);
                item.IsShippingItem = true;
                item.isTaxableItem = true;
                this.DocumentLineItems.push(item);
            }
        }

        this.IsVoided = globalModel.isVoided;
        this.SyncToken = globalModel.editSequence ? globalModel.editSequence : '0';
        this.AccountBalance = globalModel.accountBalance;

        if(globalModel.amountPaid != undefined){
            this.AmountPaid = globalModel.amountPaid
        }
     }
    shippingAddress : any
    billingAddress : any
    customerId : string
    referenceNumber : string
    txnType : string
    txnId : string
    DocumentDate : Date
    context : any
    qboAvaTaxConfigInfo : any;
    docDiscountInfo : DiscountInfo;
    isConsiderResalenumber : string;
    DocumentLineItems = [];
    IsVoided : any;
    SyncToken : any;
    AccountBalance : any;
    AmountPaid : string = '0';
    IsEstimateRequest : boolean = false;
    Customer : any = null;
    OriginAddress : any = null;
    DestinationAddress : any = null;
    QboSalesTaxItemId : string;
    QboChartOfAccountId : string;
    IsNewTxn : boolean = false;
    IsTaxRatePresent: boolean = false;
    QbEvent = "";
    qboCustomDepartments : DepartmentInfo;
}


export class DiscountInfo {

    constructor(globalModel){
        this.discountAmount = globalModel.discountInfo.discountAmount;
        this.discountPercent = globalModel.discountInfo.discountPercent;
        this.discountTaxable = globalModel.discountInfo.discountTaxable;
    }
    discountAmount : string;
    discountPercent : string;
    discountTaxable : boolean;
}

export class DocumentLineItem{

    constructor(itemDetails, isTaxableItem){
        this.itemId = itemDetails.itemId;
        this.quantity = itemDetails.quantity;
        this.amount = itemDetails.amount;
        this.description = itemDetails.description;
        this.taxCodeId = itemDetails.taxCodeId;
        this.isTaxableItem = isTaxableItem;
        this.IsShippingItem = false;
        this.Product = null;
        this.itemType = itemDetails.itemType;
        this.salesDetailType = itemDetails.salesDetailType;
        this.isSubtotalLine = itemDetails.isSubtotalLine;
        this.serviceDate = itemDetails.serviceDate;
    }

    itemId : string;
    quantity : any;
    amount : any;
    description : string;
    taxCodeId : string;
    isTaxableItem : boolean;
    IsShippingItem = false;
    itemType : string;
    salesDetailType : string;
    isSubtotalLine : boolean;
    serviceDate : string;
    Product = {};
}


export class QboAvaTaxConfigInfo{
    avaTaxServiceUrl : string = "";
    avaTaxAccountNumber : string = "";
    avaTaxLicenseKey : string = "";
    avaTaxUserId : string = "";
    avaTaxUserName : string = "";
    avaTaxPassword : string = "";
    avaTaxCompany : string = "";
    qboId : string = "";
    realmId : string = "";
    userEmail : string = "";
}

export class CustomerInfoWithTaxRate
{
    customer : any;
    isTaxRatePresent : boolean
    isPartnerTaxEnabled : boolean
    errorMessage : string
}

export class ProductInfoResponse {
    item : ProductInfo[]
    startPosition : number
    totalCount : number     
}

export class DepartmentInfoResponse {
    department : DepartmentInfo[]
    startPosition : number
    totalCount : number     
} 

export class DocumentErrorResponse {
    id: number
    errorMessage : string 
    realmId : string
    transactionId : string
    attemptedAddress : string
    entryTimeStamp : Date
}

export class ProductInfo {
    id : string
    name : string
    description : string
   // isDiscountedItem : boolean
    taxable : boolean
    active : boolean
    unitPrice : string
    type : string
    fullyQualifiedName : string
}


export class DepartmentInfo {
    id : string
    name : string
    fullyQualifiedName : string
    active :  boolean
    subDepartment : boolean
}

export class AvaTaxResult{
    avaTax : string
    rdfAmount : string
    taxOnRdfAmount : string
    totalAmount : string
    docDate : string
    taxItemId : string
    rdfItemId: string
    rdfItemAccountId: string
    taxOnRdfItemId : string
    taxOnRdfItemAccountId : string
    accountId : string
    errorMessage : string
    attemptedAddress : string
    discountAmount : number
}

export class RdfTaxLine{
    taxType: string
    taxAmount: string
}

export class NewDocumentRequestData {
    realmId : string
    txnType : string
    docCode : string
    newDocCode : string
    totalAmount : string
    totalTax : string
    docDate : string
    qboDocumentDataPostSave : QboDocumentData
}

export class VoidOrDeleteRequestData {
    txnType : string
    txnId : string
    qboDocNumber : string    
    qboDocumentDate : string
}

export class CancelTaxResponse{
    isSuccess : boolean
    errorMessage : string
}

export class GetTaxRequestModel{
    qboDocument : QboDocumentData
    documentLineItems : any = []
}