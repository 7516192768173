<s-container>
    <form [formGroup]="searchForm" novalidate id="frmItemManagement">

        <a [routerLink]="[ '/home']" *ngIf="this.sourceParam==''">
            &#8592; back
        </a>

        <div class="row">
            <h2 class="page-title header-title"> Manage Exemption </h2>
            <h4 class="page-title header-sub-title"> Manage customer exemption certificate and send certificate request </h4>
        </div>
        <br>

        <s-tabs id="exemption-tabs" aria-label="select framework" role="tablist">
            <s-tab id="exempted-customer-tab" (click)="selectTab(1)" aria-controls="exempted-customer-panel" role="tab" tabindex="0" aria-selected="true" selected="">Exempted Customer</s-tab>
            <s-tab id="exemption-request-tab" (click)="selectTab(2)" aria-controls="exemption-request-panel" role="tab" tabindex="-1" aria-selected="false">Send Exemption Certificate Request</s-tab>
        </s-tabs>
      
        <br>
        <div  id="exempted-customer-panel"  role="tabpanel" aria-labelledby="exempted-customer-tab" tabindex="0" style="padding:0px;">
            <s-row>
                <s-col span="6" class="filter-item s-col-padding-bottom-08">
                </s-col>
                <s-col *ngIf="isExemptedCustomerListLoaded" span="6" style="float:right;" class="filter-item s-col-padding-bottom-08">
                    <label>
                        <div class="label-search-title">Filter by: </div>
                        
                        <input type="text" name="FilterExemptedCustomerText" formControlName="FilterExemptedCustomerText" 
                            class="search-text">
                        <button class="primary" (click)="filterExemptedCustomer('filter')" AutomationAttribute="Exemption_Button_Search">
                            <span class="button-text">
                                <s-icon name="search" class="small" role="img" aria-label="send"></s-icon>&nbsp;&nbsp; Search 
                            </span>
                        </button>
                    </label>
                </s-col>
            </s-row>
            <s-row>
                <s-col>
                    <div class="row table-outer">
                        <table datatable #dt1 class="card-table" [dtOptions]="dtOptions[0]" [dtTrigger]="dtTrigger">
                            <thead>
                                <tr>
                                    <th width="5%">Customer Code</th>
                                    <th width="15%">Customer Name</th>
                                    <th width="20%">Address</th>
                                    <th width="8%" style="text-align: center;">Region</th>
                                    <th width="10%" style="text-align: center;">Certificate Id</th>
                                    <th width="10%" style="text-align: center;">Exemption Reason</th>
                                    <th width="10%" style="text-align: center;">Signed Date</th>
                                    <th width="10%" style="text-align: center;">Expiration</th>
                                    <th width="10%" style="text-align: center;">Status</th>
                                    <th width="5%" style="text-align: center;">Certificate</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="row-collapse" *ngFor="let data of exemptedCustomerList">
                                    <td class="vertical-align-top">{{data.customerCode}}</td>
                                    <td class="vertical-align-top">{{data.name}}</td>
                                    <td class="vertical-align-top">{{data.line1 }} {{ data.line2}} {{ data.city}} {{ data.region}} {{ data.postalCode }} </td>
                                    <td style="text-align: center;" class="vertical-align-top">{{data.region}} </td>
                                    <td style="text-align: center;" class="vertical-align-top">{{data.certificateId}} </td>
                                    <td style="text-align: center;" class="vertical-align-top">{{data.exemptionReason}} </td>
                                    <td style="text-align: center;" class="vertical-align-top">{{data.signedDate  | date: 'MM/dd/yyyy'}} </td>
                                    <td style="text-align: center;" class="vertical-align-top">{{data.expirationDate  | date: 'MM/dd/yyyy'}} </td>
                                    <td style="text-align: center;" class="vertical-align-top">
                                       <span *ngIf="data.valid" class="exemption-status-valid">{{data.valid? "Valid" : ""}}</span>
                                     </td>
                                    <td style="text-align: center;" class="vertical-align-top">
                                       <a *ngIf="data.valid" href="javascript:void();" (click)="downloadCertificate(data.name, data.certificateId);"><s-icon name="download" class="small" role="img" aria-label="download"></s-icon></a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </s-col>
            </s-row>
        </div>

        <div id="exemption-request-panel"  role="tabpanel" aria-labelledby="exemption-request-tab" tabindex="0" class="hidden" style="padding:0px;">
            <s-row>
                <s-col span="12" class="filter-item s-col-padding-bottom-08">
                    <table style="background: #f4f4f4; padding: 10px;">
                        <tr>
                            <td>Customer Name</td>
                            <td>Company</td>
                            <td>Email</td>
                            <td>Customer with -</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td><input style="width:45%;display:inline;margin-right:5px;" placeholder="First name" name="customerFamilyName" formControlName="CustomerGivenName" AutomationAttribute="Exemption_Search_CustomerGivenName"  />
                                <input style="width:45%;display:inline;margin-top: 0px;" placeholder="Last name" name="customerFamilyName" formControlName="CustomerFamilyName" AutomationAttribute="Exemption_Search_CustomerFamilyName"  /></td>
                            <td><input name="companyName" formControlName="CompanyName" AutomationAttribute="Exemption_Search_CompanyName"  /></td>
                            <td><input name="customerEmail" formControlName="CustomerEmail" AutomationAttribute="Exemption_Search_CustomerEmail"  /></td>
                            <td>
                                <select name="selectOption" formControlName="SelectOption" AutomationAttribute="Exemption_Search_SelectOption" >
                                    <option [selected]="true" value="All">All</option>
                                    <option value="Email">Email</option>
                                    <option value="Fax">Fax</option>
                                    <option value="NoEmail">Email unavailable</option>
                                </select>
                                </td>
                            <td style="text-align: right;">
                                <button class="primary" (click)="searchQboCustomers()" AutomationAttribute="Exemption_Button_Search">
                                    <span class="button-text">
                                        <s-icon name="search" class="small" role="img" aria-label="send"></s-icon>&nbsp;&nbsp; Search 
                                    </span>
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="4">&nbsp;</td>
                        </tr>
                    </table>
                </s-col> 
            </s-row>
            <s-row>
                <s-col span="2" class="filter-item s-col-padding-bottom-08">
                    <button class="primary" (click)="sendExemptionCertificateRequest()"
                    AutomationAttribute="Exemption_Button_Send">
                        <span class="button-text">
                            Send Request &nbsp;&nbsp; <s-icon name="send" class="small" role="img" aria-label="send"></s-icon>
                        </span>
                    </button>
                    
                </s-col>
              
                <s-col span="10" class="filter-item s-col-padding-bottom-08">
                    <fieldset role="radiogroup" aria-labelledby="label2" class="requestMethod-fieldset">
                            <label style="width:150px; display: inline-block;">Request method: </label>
                            <input type="radio" id="r4" name="RequestMethodValue" value="Email" formControlName="RequestMethodValue" (change)="disableCheckbox('Email')">
                            <label for="r4" style="width:80px; display: inline-block;">Email</label>
                            <input type="radio" id="r6" name="RequestMethodValue" value="Fax" formControlName="RequestMethodValue" (change)="disableCheckbox('Fax')">
                            <label for="r6" style="width:70px; display: inline-block;">Fax</label>
                            <input type="radio" id="r5" name="RequestMethodValue" value="Download" formControlName="RequestMethodValue" (change)="disableCheckbox('Download')">
                            <label for="r5" style="width:200px; display: inline-block;">Download Link</label>
                    </fieldset>
                    <fieldset class="requestMethodInfo-infobox-fieldset">
                        <s-icon name="info-circle" role="img" aria-label="info-circle" onmouseenter="$('#requestMethodInfo').show()" onmouseleave="$('#requestMethodInfo').hide()"></s-icon>
                        <s-banner status="info" id="requestMethodInfo" nodismiss="" class="requestMethodInfo-infobox-banner">
                            <span>If the customer record does not contain an email/fax value, then the user will not see a checkbox to select the customer(s) from the list.</span>
                            <br><span>If shipping address is blank, then billing address will be used to send request.</span>
                        </s-banner>
                    </fieldset>
                </s-col>
            </s-row>
            <s-row>
                <s-col>
                    <div class="row table-outer">
                        <table datatable #dt1 class="card-table" [dtOptions]="dtOptions[0]" [dtTrigger]="dtTrigger">
                            <thead>
                                <tr>
                                    <th width="80px">
                                        
                                        <label>
                                            <input type="checkbox" [checked]="isSelectAllChecked" style="width:16px;height:16px; display: inline;margin-top: 6px;"
                                                (change)="selectAll($event)" />
                                            <span style="margin: 2px 0 0 4px; position: absolute;font-weight:bold;">SELECT</span>
                                        </label>
                                    </th>
                                    <th width="200px">Customer Name</th>
                                    <th>Company </th>
                                    <th>Email</th>
                                    <th>Shipping Address</th>
                                    <th>Billing Address</th>
                                    <th>Fax</th>
                                    <th>Phone</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="row-collapse" *ngFor="let data of qboCustomerList">
                                    <td style="text-align: left;">
                                        <fieldset role="group" aria-labelledby="label4">
                                            <input type="checkbox" id="chkAll" name="chkAll" 
                                            *ngIf="data.isDisabled!=true" [checked]="data.isSelected"
                                            style="width:16px;height:16px;margin-left: 6px;" (change)="selectCustomerValue($event.target);"
                                                value="{{data.customerId}}" />
                                        </fieldset>
                                    </td>
                                    <td class="vertical-align-top">{{data.customerName}}</td>
                                    <td class="vertical-align-top">{{data.companyName}}</td>
                                    <td class="vertical-align-top">{{data.emailAddress}}</td>
                                    <td class="vertical-align-top">{{data.shippingAddress}}</td>
                                    <td class="vertical-align-top">{{data.billingAddress}}</td>
                                    <td class="vertical-align-top">{{data.faxNumber}}</td>
                                    <td class="vertical-align-top">{{data.phoneNumber}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    
                        <s-dialog id="exemptionRequestErrorDialog" aria-labelledby="dialog-title" aria-modal="true" noscroll="">
                            <div slot="header" id="dialog-title">Exemption Certificate Request Status:</div>
                            <div slot="body">
                                <b>Message:- </b>  
                                <span *ngIf="selectedRequestMethod == 'Download'"><b> Certificate request links have been generated and downloaded in .csv file for ({{downloadListCount}}) customers.</b></span>
                                <span *ngIf="selectedRequestMethod != 'Download'"><b> Exemption certificate requests have been sent on {{selectedRequestMethod}} to ({{qboSelectedCustomer.length - sendExemptionRequestError.length}}) customers.</b></span>
                                <br><br>
                                <div class="dialog-content-header">Error list:</div>
                                <div class="dialog-content-subheader">Please check and correct the errors occured for the record of selected customers.</div>
                                <s-table-container tabindex="0">
                                    <table>
                                        <thead>
                                            <th>Customer Code</th>
                                            <th>Customer name</th>
                                            <th>Error</th>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let data of sendExemptionRequestError">
                                                <td>{{data.id}}</td>
                                                <td>{{data.customerName}}</td>
                                                <td>{{data.errorMessage}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </s-table-container> 
                            </div>
                            <div slot="footer">
                                <button class="primary medium" (click)="downloadRequestList()">Download Error List</button>
                                <button class="secondary medium" onclick="document.getElementById('exemptionRequestErrorDialog').close()">Cancel</button>
                            </div>
                        </s-dialog>
                    
                </s-col>
            </s-row> 
        </div>
    </form>
</s-container>