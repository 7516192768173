import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';
import { Router, RouterEvent, NavigationEnd, NavigationStart, ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs/operators';
import { AuthGuard } from 'src/app/shared/gaurds/auth.gaurds';
import { Location } from "@angular/common";
import { ExpireTokens } from '../models/OAuthToken';
import { DialogService } from '../../Connector/services/dialog.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})

export class HeaderComponent {
  loggedIn: boolean = false;
  isOktaLoggedIn: boolean = false;
  avaTaxUserName: string;
  avaTaxUserType: string;
  companyName: string;
  errorMessage: any;
  isSignInPage: boolean = false;
  isOktaSignin: boolean = false;
  user: any = {
      familyName: "",
      givenName: "",
      email: "",
      isLoggedIn: true,
  }
  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    private dialogService: DialogService,
    private location: Location,
    private authGuard: AuthGuard) {

    this.loadHeaderData(null);

    var subscription = router.events.subscribe((event) => {
      // If Okta signin page then hide Header section (as it is used to redirect on Okta authentication)
      if (location.path() != "") {
        this.isOktaSignin = location.path() == "/admin/signin" ? true: false;
      }

      if (event instanceof NavigationEnd) {

        console.log('header NavigationEnd', event.url);
        
        var realm = this.route.snapshot.paramMap.get('realm');
        console.log('header NavigationEnd realm', realm);

        this.isSignInPage = this.router.url.toLowerCase() == "/learnmore";
        var wasLoggedIn = this.loggedIn;
        if(this.isSignInPage) {
          this.authService.logOut(true, [], this.isSignInPage);
          this.avaTaxUserName = null;
          this.companyName = null;
          this.isOktaLoggedIn =  false;
          this.loggedIn = false;
          this.authService.emitChange(true, null, null);
        }
        else {
          this.checkUserCalculationStatus();
          this.getHeaderValue();
        }
      }

    });
  }

  closeUnauthorizedErrorDialog() {
    this.dialogService.hideDialogBox('unauthorizedErrorDialog');
    window.location.href = "/learnmore";
  }

  getHeaderValue(){
    let token = this.authService.fetchAIAuthTokenFromStorage();
    if (token) {
      this.avaTaxUserName = token.userName;
      this.avaTaxUserType = token.avaut;
      this.isOktaLoggedIn =  (token.source == 'okta')? true: false;
      this.loggedIn = true;
    }
  }

  public logOut(){
    let token = this.authService.fetchAIAuthTokenFromStorage();
      if (token) {
      let expireTokens: ExpireTokens[] = [
        { token : "Bearer " + token.session_Id, tokenType: "Jwt" },
        { token : "Bearer " + token.curSen, tokenType: "Jwt" }
      ];

        this.authService.logOut(true, expireTokens, this.isSignInPage);
      
    }
  }

  public oktaLogOut() {
    let token = this.authService.fetchAIAuthTokenFromStorage();
    if (token) {
      this.authService.oktaTokenRevoke(token.session_Id);
    }
  }

  checkUserCalculationStatus() {
    var current = this;
    let token = this.authService.fetchAIAuthTokenFromStorage();
    if (token) {
      if(token.userId){
      //console.log('checkUserCalculationStatus',token);
      this.avaTaxUserName = token.userName;
      this.isOktaLoggedIn =  (token.source == 'okta')? true: false;
      this.loggedIn = true;

      // this.authService.fetchUserConfigurationModel(token.userId, token.session_Id).subscribe(
      //   (userConfig: any) => {
      //     current.loadHeaderData(userConfig);
      //   },
      //   (error: any) => { this.errorMessage = <any>error; },
      //   () => { }
      // );
      }
    };
  }


  loadHeaderData(userConfig) {
    //console.log("userconfig:" , userConfig);
    this.isOktaLoggedIn = false;
    
    if (userConfig){
      this.authService.emitChange(true, userConfig.avaTaxUserName, userConfig.qboCompanyName);
    }
    // subscribe to loggedInStatus change so as to update loggedIn property value
    this.authService.changeEmitted$.subscribe(data => {
      
      this.loggedIn = data.isLoggedIn;
      
      if (data.userName != null && data.userName != '') {
        this.avaTaxUserName = data.userName;
      }
      if (data.companyName != null && data.companyName != '') {
        this.companyName = data.companyName;
      }
      if (data.userType != null && data.userType != '') {
        this.avaTaxUserType = data.userType;
      }
      
      this.isOktaLoggedIn =  (data.source == 'okta')? true: false;

      //console.log("changeEmitted:" , this.avaTaxUserName, this.companyName, this.isOktaLoggedIn);
    });
    
  }
}
