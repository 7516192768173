import { NgModule } from '@angular/core';
import { Routes, RouterModule, ActivatedRoute, ParamMap  } from '@angular/router';
import { HomeComponent } from './Connector/home/home.component';
import { ConnectorSettingsComponent } from './Connector/connectorsetting/connectorsettings.component';
import { ConnectorsbasicsettingsComponent } from './Connector/connectorsbasicsettings/connectorsbasicsettings.component';
import { NexusSetupComponent } from './Connector/nexussetup/nexussetup.component';
import { CompanyPickerComponent } from './Connector/companypicker/companypicker.component';
import { LinkCompanyComponent } from './Connector/linkcompany/linkcompany.component';
import { AvaTaxLoginComponent } from './Connector/avataxlogin/avataxlogin.component';
import { InvalidSessionComponent } from './Connector/invalidsession/invalidsession.component';
import { ConnectToQBOComponent } from './Connector/ConnectToQBO/ConnectToQBO.component';
import { LaunchComponent } from './shared/layout/launch/launch.component';
import { DisconnectComponent } from './shared/layout/disconnect/disconnect.component';
import { ErrorComponent } from './shared/layout/error.component';
import { AvataxlandingComponent } from './Connector/avataxlanding/avataxlanding.component';
import { AdminSigninComponent  } from './adminsign/adminsign.component';
import { AuthCallbackComponent } from './auth-callback/auth-callback.component';
import { AIAuthCallbackComponent } from './ai-auth-callback/ai-auth-callback.component';
import { AuthGuard } from './shared/gaurds/auth.gaurds';
import { CandeactivateGuard } from './shared/gaurds/candeactivate.guard';
import { ApphealthComponent } from './apphealth/apphealth.component';
import { ReconciliationComponent } from './Connector/reconciliation/reconciliation.component'
import { CustomersComponent } from './Connector/customers/customers.component';
import { ItemManagementComponent } from './Connector/itemmanagement/itemmanagement.component'
import { BatchAddressComponent } from './Connector/batchaddress/batchaddress.component';
import { ExemptionComponent } from './Connector/exemption/exemption.component'
import { LearnMoreComponent } from './Connector/learnmore/learnmore.component'
import { IntegrationComponent } from './Connector/integration/integration.component'
import { SignOutComponent } from './Connector/signout/signout.component'
import { OnBoardingComponent } from './Connector/on-boarding/on-boarding.component';
import { UtilitiesComponent } from './utilities/utilities.component';


const routes: Routes = [
  {
    path: 'launch' , component : LaunchComponent
  },
  {
    path: 'disconnect', component: DisconnectComponent,
  },
  {
    path : 'disconnect/:id', component : DisconnectComponent, 
  },
  {
    path: 'learnmore' , component : LearnMoreComponent
  },
  //{
  //  path: 'integration' , component : IntegrationComponent
  //},
  {
    path: 'on-boarding' , component : OnBoardingComponent
  },
  {
    path : 'home' , component : HomeComponent, canActivate:[AuthGuard]
  },
  //{     
  //  path: 'connectorsettings', component: ConnectorsbasicsettingsComponent , canActivate:[AuthGuard] ,canDeactivate :[CandeactivateGuard]
  //},
  //{     
  //  path : 'connectorsettings/:source' , component : ConnectorSettingsComponent , canActivate:[AuthGuard] ,canDeactivate :[CandeactivateGuard]
  //},
  {     
    path : 'advancesettings' , component : ConnectorSettingsComponent , canActivate:[AuthGuard] ,canDeactivate :[CandeactivateGuard]
  },
  {
    path : 'nexussetup' , component : NexusSetupComponent
  },
  // {
  //   path: 'companypicker',  component: CompanyPickerComponent
  // },
  {
    path: 'linkcompany' , component: LinkCompanyComponent, canActivate:[AuthGuard]
  },
  {
    path: 'avataxlogin' , component: AvaTaxLoginComponent, canActivate:[AuthGuard]
  },
  // {
  //   path: 'invalidsession', component: InvalidSessionComponent
  // },
  {
    path: 'serviceapp/AvaTaxLanding', component: AvataxlandingComponent
  },
  {
    path: 'admin/signin', component: AdminSigninComponent
  },
  {
    path: 'admin/customers', component: CustomersComponent
  },
  {
    path: 'reconciliation', component: ReconciliationComponent, canActivate:[AuthGuard]
  }, 
  {
    path: 'itemmanagement', component: ItemManagementComponent, canActivate:[AuthGuard]
  },
  {
    path: 'batchaddress', component: BatchAddressComponent, canActivate:[AuthGuard]
  },
  {
    path: 'exemption', component: ExemptionComponent, canActivate:[AuthGuard]
  },
  {
    path: 'reconciliation/:source', component: ReconciliationComponent, canActivate:[AuthGuard]
  },
  {
    path: 'health', component: ApphealthComponent
  },
  // {
  //   path: 'connecttoqbo', component: ConnectToQBOComponent
  // },
  {
    path: 'authcallback',  component: AuthCallbackComponent
  },
  {
    path: 'aiauthcallback',  component: AIAuthCallbackComponent
  },
  {
    path: 'signout',  component: SignOutComponent
  },
  {
    path:'' , redirectTo :'learnmore' , pathMatch : 'full'
  },
  {
    path: 'utilities', component: UtilitiesComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
