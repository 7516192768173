import { avaTaxDetails, intuitDetails, configuration, avaTaxCompaniesModel, salesTaxItem } from './QBOUsers';
import { userStatusBody } from './avataxcompany';

export class AdminQboCustomer
{
    id:string
    realmId : string
    qboUserName: string
    companyName : string
    lastModifiedDate : string
    configurationStatus : string
    status : string
}

export class configurationModel {
    avaTaxDetails : avaTaxDetails;
    configuration:configuration;
    avaTaxCompanies:avaTaxCompaniesModel[];
    salesTaxItems:salesTaxItem[];
    id : string;
    realmId : string;
  }

  export class customerConfiguration {
    isApiThrottleExceeded: boolean;
    apiThrottleTimeStamp: string;
    configurationModel: configurationModel;
    userStatusBody: userStatusBody;
  }