import { Injectable } from '@angular/core';
import { OnBoardingStatus } from '../enums/on-boarding-status';
import { AuthenticationService } from './authentication.service';
import { AIAuthTokenRequest } from '../models/AIAuthToken';
import { OnboardingDetails } from '../models/Onboarding';

@Injectable({
  providedIn: 'root'
})
export class OnBoardingService {

  constructor(private authService: AuthenticationService) { }

  isAIStarted = () => this.getOnboardingStatus() === OnBoardingStatus.AIStarted;
  isAISuccess = () => this.getOnboardingStatus() === OnBoardingStatus.AISuccess;
  isIntegrationStarted = () => this.getOnboardingStatus() === OnBoardingStatus.IntegrationStarted;
  isIntegrationSuccess = () => this.getOnboardingStatus() === OnBoardingStatus.IntegrationSuccess;
  isCompleted = () => this.getOnboardingStatus() === OnBoardingStatus.Completed;
  
  setAIStartedStatus = () => this.setOnboardingStatus(OnBoardingStatus.AIStarted);
  setAISuccessStatus = () => this.setOnboardingStatus(OnBoardingStatus.AISuccess);
  setIntegrationSuccessStatus = () => this.setOnboardingStatus(OnBoardingStatus.IntegrationSuccess);
  setCompletedStatus = () => this.setOnboardingStatus(OnBoardingStatus.Completed);

  setOnboardingSuccessTrue = () => this.setOnboardingSuccess(true);
  setOnboardingSuccessFalse = () => this.setOnboardingSuccess(false);

  startAI(onboardingDetails: OnboardingDetails) {
    this.setOnboarding(onboardingDetails);
    this.setAIStartedStatus();
    this.authService.startAuthentication();
  }

  startIntegration() {
    this.setOnboardingStatus(OnBoardingStatus.IntegrationStarted);
    let request: AIAuthTokenRequest = new AIAuthTokenRequest();
    this.authService.completeAIAuthenticationForOnBoarding(request);
  }

  startCompletion() {
    this.setCompletedStatus();
    this.setOnboardingSuccessTrue();
    this.postMesssageToParent(JSON.stringify(this.getOnboarding()));
  }

  postMesssageToParent(message: string) {
    window.opener.postMessage(message, "*");
    window.close();
  }

  setOnboarding(onboardingDetails: OnboardingDetails) {
    localStorage.setItem('onboardingDetails', JSON.stringify(onboardingDetails));
  }

  getOnboarding() {
    return this.retrieve<OnboardingDetails>('onboardingDetails');
  }

  removeOnboarding() {
    localStorage.removeItem('onboardingDetails');
  }

  getOnboardingStatus() {
    return this.retrieve<OnboardingDetails>('onboardingDetails')?.status;
  }

  setOnboardingSuccess(success: boolean) {
    let onboardingDetails = this.retrieve<OnboardingDetails>('onboardingDetails');
    onboardingDetails.success = success;
    localStorage.setItem('onboardingDetails', JSON.stringify(onboardingDetails));
  }

  setOnboardingStatus(onboardingStatus: OnBoardingStatus) {
    let onboardingDetails = this.retrieve<OnboardingDetails>('onboardingDetails');
    onboardingDetails.status = onboardingStatus;
    localStorage.setItem('onboardingDetails', JSON.stringify(onboardingDetails));
  }

  private retrieve<T>(key: string): T | null {
    const value = localStorage.getItem(key);

    return JSON.parse(value || 'null') as T;
  }
}
