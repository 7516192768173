import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router"

@Component({
  selector: 'app-signout',
  templateUrl: './signout.component.html',
  styleUrls: ['./signout.component.css']
})
export class SignOutComponent implements OnInit {

  constructor(private router: Router) {  }

  ngOnInit() {
    let token = localStorage.getItem('qbo_session')
    if (token != null || token != undefined) {
      localStorage.removeItem("qbo_session");
    }
    this.router.navigate(['/learnmore']);
  }
}