import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import { FetchTransactionsRequest, QboRecalculationRequest } from '../models/reconciliationModel';
import { userCredentials } from '../models/UserCredentials';
import { AvaTaxAccountValidationResponse } from '../models/AvaTaxAccountValidationResponse';
import { IHttpService } from '../../shared/services/IHttpService';
import { AppConfig } from '../../app.config';
import { CommonService } from '../services/common.service';

@Injectable({
    providedIn: 'root'
})
export class ReconciliationService implements IHttpService{
    private reconciliationBaseUrl =  `${AppConfig.settings.avatax.api_base_url}/api/Reconciliation`;

    constructor(private http: HttpClient, private commonService: CommonService) { }
 
    compareTransactions(id:string, fetchTransactionsRequest: FetchTransactionsRequest, authToken:string):Observable<any> {
      const url = `${this.reconciliationBaseUrl}/CompareTransactions/${id}`;
      const headers = new HttpHeaders().set('Authorization', `Bearer ${authToken}`)
      return this.http.post<any>(url, fetchTransactionsRequest ,{headers: headers}).pipe(catchError((e:any)=>this.handleError(e)));
    }

    fetchComparedTransactions(realmId:string, authToken:string):Observable<any> {
      const url = `${this.reconciliationBaseUrl}/FetchComparedTransactions/${realmId}`;
      const headers = new HttpHeaders().set('Authorization', `Bearer ${authToken}`)
      return this.http.post<any>(url, "" ,{headers: headers}).pipe(catchError((e:any)=>this.handleError(e)));
    }

    fetchQBOTransactions(id:string, fetchTransactionsRequest: FetchTransactionsRequest, authToken:string):Observable<any> {
        const url = `${this.reconciliationBaseUrl}/FetchQboTransactions/${id}`;
        const headers = new HttpHeaders().set('Authorization', `Bearer ${authToken}`)
        return this.http.post<any>(url, fetchTransactionsRequest ,{headers: headers}).pipe(catchError((e:any)=>this.handleError(e)));
      }

    fetchAvaTaxTransactions(id:string, fetchTransactionsRequest: FetchTransactionsRequest, authToken:string):Observable<any> {
        const url = `${this.reconciliationBaseUrl}/FetchAvaTaxTransactions/${id}`;
        const headers = new HttpHeaders().set('Authorization', `Bearer ${authToken}`)
        return this.http.post<any>(url, fetchTransactionsRequest ,{headers: headers}).pipe(catchError((e:any)=>this.handleError(e)));
      }

    recalculateQboTransactions(id:string, qboRecalculationRequest:QboRecalculationRequest[], authToken:string):Observable<any>{
        const url = `${this.reconciliationBaseUrl}/RecalculateQboTransactions/${id}`;
        const headers = new HttpHeaders().set('Authorization', `Bearer ${authToken}`)
        return this.http.post<any>(url,qboRecalculationRequest,{headers: headers}).pipe(catchError((e:any)=>this.handleError(e)));
      }

    //   saveConnectorSettings(connectorSettings : configurationModel, authToken:string): Observable<any>{
    //     const url = `${this.reconciliationBaseUrl}/config`;
    //     const headers = new HttpHeaders().set('Authorization', `Bearer ${authToken}`)
    //     return this.http.put(url,connectorSettings,{headers: headers}).pipe(catchError((e:any)=>this.handleError(e)));
    //   }
      
      handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('An error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          // The response body may contain clues as to what went wrong,
          console.error(
            `Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);

          this.commonService.unauthorizationRedirect(error);
          return throwError('unauthorized');
        }
        // return an observable with a user-facing error message
        return throwError(
          'Something went wrong, please try again later.');
      };
}