export class AIAuthTokenRequest{
    code :string
    id_token : string   
    scope : string
    state: string
    session_state : string
    realmId: string
}

export class AIAuthTokenResponse{
    session_Id : string
    userId : string
    isQBOConnected : boolean
    userName : string
    isAvaTaxCompanyConfigured : boolean
    esbOnBoardingUrl: string
}