import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { OriginAddressResponse, QboContextData, QboDocumentData, CustomerInfoWithTaxRate, 
         ProductInfoResponse, DepartmentInfoResponse, DocumentErrorResponse ,ProductInfo, DepartmentInfo, AvaTaxResult, NewDocumentRequestData,VoidOrDeleteRequestData,CancelTaxResponse,GetTaxRequestModel } from '../models/avataxlanding';
import { AppConfig } from '../../app.config';

@Injectable({
  providedIn: 'root'
})
export class AvaecosystemService {

  constructor(private http: HttpClient) { }

  public getGlobalModelFromqboXDM(qboXDM): Promise<any>{

    let promise = new Promise((resolve,reject) =>{
      try {
        qboXDM.getModel(function(model) {
          //console.log("Global model from QBO XDM - " +JSON.stringify(model, null, 4))
          resolve(model);
         });
      } catch (error) {
        reject(error)
      }
      
    })

    return promise
  }

  public getGlobalContextFromqboXDM(qboXDM): Promise<any>{

    let promise = new Promise((resolve,reject) =>{
      try {
        qboXDM.getContext(function (context) { 
          resolve(context); 
        });
      } catch (error) {
        reject(error)
      }
      
    })

    return promise
  }

  public getOriginAddress(qboContext: QboContextData): Promise<OriginAddressResponse> {

    let promise = new Promise<OriginAddressResponse>((resolve,reject) =>{
      try {
        let url : string =  `${AppConfig.settings.OAuthUrls.oAuthBaseUrl}/api/Intuit/originAddress/${qboContext.realmId}`;
        this.http.get<OriginAddressResponse>(url).toPromise()
        .then(
          res => resolve (res),
          error => reject(error)
        )
      } catch (error) {
        reject(error)
      }
      
    })

    return promise
  }

  public getUpdatedConfigInfo(qboContext: QboContextData): Promise<any> {
    let promise = new Promise<OriginAddressResponse>((resolve,reject) =>{
      try {
        let url : string =  `${AppConfig.settings.OAuthUrls.oAuthBaseUrl}/api/Intuit/getEcoSystemConfiguration/${qboContext.realmId}`;
        this.http.get<any>(url).toPromise()
        .then(
          res => resolve (res),
          error => reject(error)
        )
      } catch (error) {
        reject(error)
      }
    })
    return promise
  }

  public getCustomerInfoWithTaxRate(qboContext: QboContextData ,qboDocument : QboDocumentData ): Promise<CustomerInfoWithTaxRate> {

    let promise = new Promise<CustomerInfoWithTaxRate>((resolve,reject) =>{
      try {
        let url : string =  `${AppConfig.settings.OAuthUrls.oAuthBaseUrl}/api/Intuit/CustomerInfoWithTaxRate/${qboContext.realmId}?customerId=${qboDocument.customerId}`;
        this.http.get<CustomerInfoWithTaxRate>(url).toPromise()
        .then(
          res => resolve (res),
          error => reject(error)
        )
      } catch (error) {
        reject(error)
      }
      
    })

    return promise
  }

  public getProductInfo(totalCount: number , startPosition : number,qboContext : QboContextData) : Promise<ProductInfoResponse>{
    let promise = new Promise<ProductInfoResponse>((resolve,reject) =>{
    try {
      let url : string =  `${AppConfig.settings.OAuthUrls.oAuthBaseUrl}/api/Intuit/product/${qboContext.realmId}?startPosition=${startPosition}&totalCount=${totalCount}`;
      this.http.get<ProductInfoResponse>(url).toPromise()
      .then(
        res => resolve (res),
        error => reject(error)
      )
    } catch (error) {
      reject(error)
    }
    
  })

  return promise
  }

  public getNewProductInfo(newItem: string[],qboContext : QboContextData) : Promise<ProductInfo[]>{
    let promise = new Promise<ProductInfo[]>((resolve,reject) =>{
    try {
      let url : string =  `${AppConfig.settings.OAuthUrls.oAuthBaseUrl}/api/Intuit/newproduct/${qboContext.realmId}`;
      this.http.post<ProductInfo[]>(url,newItem).toPromise()
      .then(
        res => resolve (res),
        error => reject(error)
      )
    } catch (error) {
      reject(error)
    }
    
  })

  return promise
  }

  public getDepartmentInfo(totalCount: number , startPosition : number,qboContext : QboContextData) : Promise<DepartmentInfoResponse>{
    let promise = new Promise<DepartmentInfoResponse>((resolve,reject) =>{
    try {
      let url : string =  `${AppConfig.settings.OAuthUrls.oAuthBaseUrl}/api/Intuit/department/${qboContext.realmId}?startPosition=${startPosition}&totalCount=${totalCount}`;
      this.http.get<DepartmentInfoResponse>(url).toPromise()
      .then(
        res => resolve (res),
        error => reject(error)
      )
     } catch (error) {
      reject(error)
    }
    
  })

  return promise
  }

  public getNewDepartmentInfo(locationIds: string[],qboContext : QboContextData) : Promise<DepartmentInfo[]>{
    let promise = new Promise<DepartmentInfo[]>((resolve,reject) =>{
    try {
      let url : string =  `${AppConfig.settings.OAuthUrls.oAuthBaseUrl}/api/Intuit/newdepartments/${qboContext.realmId}`;
      this.http.post<DepartmentInfo[]>(url,locationIds ).toPromise()
      .then(
        res => resolve (res),
        error => reject(error)
      )
     } catch (error) {
      reject(error)
    }
    
  })

  return promise
  } 
      
  public getAvaTax(realmId: string,getTaxRequestModel : GetTaxRequestModel) : Promise<AvaTaxResult>{
    let promise = new Promise<AvaTaxResult>((resolve,reject)=>{
      try {
        let url : string =  `${AppConfig.settings.OAuthUrls.oAuthBaseUrl}/api/AvaTax/getTax/${realmId}`;
        this.http.post<AvaTaxResult>(url,getTaxRequestModel).toPromise()
        .then(
          res => resolve (res),
          error => reject(error)
        )      
      } 
      catch (error) {
        reject(error)
      }
    })
    return promise
  }

  public processNewDocument(newDocumentRequestData : NewDocumentRequestData) : Promise<any>{
    let promise = new Promise<AvaTaxResult>((resolve,reject)=>{
      try {
        let getTaxRequestModel: GetTaxRequestModel = new GetTaxRequestModel();
        let url : string =  `${AppConfig.settings.OAuthUrls.oAuthBaseUrl}/api/AvaTax/getTax/${newDocumentRequestData.realmId}`;
        getTaxRequestModel.qboDocument = newDocumentRequestData.qboDocumentDataPostSave;
        getTaxRequestModel.documentLineItems =  newDocumentRequestData.qboDocumentDataPostSave.DocumentLineItems;
        getTaxRequestModel.qboDocument.QbEvent = "qbo-transaction";
        getTaxRequestModel.qboDocument.txnId = newDocumentRequestData.newDocCode;
        this.http.post<any>(url,getTaxRequestModel).toPromise()
        .then(
          res => resolve (res),
          error => reject(error)
        )
      } 
      catch (error) {
        reject(error)
      }
    })
    return promise
  }

  public processVoidOrDeleteDocument(realmId:string, voidOrDeleteRequestData : VoidOrDeleteRequestData) : Promise<CancelTaxResponse>{
    let promise = new Promise<CancelTaxResponse>((resolve,reject)=>{
      try {
        let url : string =  `${AppConfig.settings.OAuthUrls.oAuthBaseUrl}/api/AvaTax/cancelTax/${realmId}`;
        this.http.post<CancelTaxResponse>(url,voidOrDeleteRequestData).toPromise()
        .then(
          res => resolve (res),
          error => reject(error)
        )
      } 
      catch (error) {
        reject(error)
      }
    })
    return promise
  }

}
