<div style="padding-left:30px;">

  <div class="row" style="margin-top:20px; display: block !important">
    <h1 class="page-title">Customers </h1>
  </div>
  <br>
  <div class="row" style="background: #f3f3f3; border: 1px solid #e6e6e6;">

    <div id="dvCustList" style="width:100%;overflow-x:auto;">
      <table datatable #dt2 class="table table-striped table-hover table-condensed" style="width:100%"
        [dtOptions]="dtOptions[1]" [dtTrigger]="dtTrigger">
        <thead>
          <tr>
            <th>RealmId</th>
            <th>QuickBooks - Company Name <br> User Name / e-mail </th>
            <th>Last Modified Date</th>
            <th>User Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of qboCustomers;">
            <td>{{data.realmId}}
            </td>
            <td>
              <span *ngIf="data.status != 'Disconnected'">
              {{data.companyName}} <br> {{data.qboUserName}}
              </span>
              <span style="color:#b1b1b1; font-style: italic;" *ngIf="data.status == 'Disconnected'">
                Intuit details removed
              </span>
            </td>
            <td style="text-align: center;">{{data.lastModifiedDate | date: 'MM/dd/yyyy' }}</td>
            <td>
              <span *ngIf="data.status == 'Active'" class="tag-active">Active</span>
              <span *ngIf="data.status == 'Down'" class="tag-down">Down</span>
              <span *ngIf="data.status == 'Disconnected'" class="tag-disconnected">Disconnected</span>
              <!-- <img *ngIf="data.status == 'Active'" src="assets/images/bullet-green.png" title="{{data.status}}" />
              <img *ngIf="data.status == 'Down'" src="assets/images/bullet-warning.png" title="{{data.status}}" />
              <img *ngIf="data.status == 'Disconnected'" src="assets/images/bullet-red.png" title="{{data.status}}" /> -->
            </td>
            <td>
              <a href="javascript:void(0);" (click)="getConfig(data.id, data.realmId);">
                <img src="assets/images/edit.png" alt="Edit configuration" title="Edit configuration" /> </a>
              &nbsp;&nbsp;
              <span style="padding:12px" *ngIf="data.status == 'Disconnected'"></span>
              <a href="javascript:void(0);" *ngIf="data.status != 'Disconnected'">
                <img src="assets/images/delete.png" alt="Disconnect customer" title="Disconnect customer"
                  (click)="disconnectCustomer(data.id, data.qboUserName);" />
              </a>
              <!-- <img src="assets/images/arrow-right.png" style="position:absolute; right: -21px; position: absolute;" /> -->
            </td>
          </tr>
        </tbody>
      </table>
    </div>


    <div id="dvEdtConfig" class="" style="display:none;width:35%;overflow-x: auto;margin-top: 52px;padding-left: 10px;">
      <form [formGroup]="configForm" (ngSubmit)="onSubmit()">
        <div style="background-color: #fff;border:1px solid #e5e6e7">

          <div class="row" style="background-color:#e8e8e8;">
            <h3 style="padding:10px 5px 15px 5px;">Configuration setting</h3>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <h5 style="font-size: 17px;text-decoration-line: underline; padding:3px;
            text-decoration-style:dotted;">Avalara account:</h5>
            </div>
          </div>
          <div class="row" style="margin-bottom:10px;">
            <div class="col-lg-12">
              AvaTax Account Number:
            </div>
            <div class="col-lg-12">
              <!-- <input type="text" formControlName="AvaTaxAccountNumber"
                AutomationAttribute="Customer_Input_AvaTaxAccountId" class="form-control" /> -->

                {{ avaTaxAccountNumber }}

              <!-- <li class="col-md-12"
                *ngIf="configForm.get('AvaTaxAccountNumber').touched && configForm.get('AvaTaxAccountNumber').errors?.required"
                style="margin-top:-15px;font-size:xx-small;color: rgb(197, 28, 28);margin-left: -22px;">Account number
                is
                required</li>
              <li class="col-md-12"
                *ngIf="configForm.get('AvaTaxAccountNumber').touched && configForm.get('AvaTaxAccountNumber').errors?.pattern"
                style="margin-top:-15px;font-size:xx-small;color: rgb(197, 28, 28);margin-left: -22px;">Only numbers are
                allowed</li> -->
            </div>
          </div>
          
          <div class="row">
            <div class="col-lg-12">
              Avalara Company:
            </div>
            <div class="col-lg-12">
              {{ avaTaxCompanyCode }}
            </div>
          </div>

          <div [style.display]="isDisconnected? 'none':'block'">
          <div class="row" style="margin-top:10px;">
            <div class="col-lg-12">
              <h5 style="font-size: 17px;text-decoration-line: underline; padding:3px;
            text-decoration-style:dotted;">Integration options:</h5>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-12">
              <span>Service:</span>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <input type="checkbox" id="chkAddress" formControlName="IsEnableAddressValidation"
                AutomationAttribute="Customer_Checkbox_AddressValidation" /> Address Validation
              <a href="http://help.avalara.com/?cid=Intg-QBO-UG-2" target="_blank">
                <img src="assets/images/HoverTT.png" class="help-icon-img" />
              </a>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-12">
              <span>Calculate Tax For: <a href="http://help.avalara.com/?cid=Intg-QBO-UG-3" target="_blank">
                  <img src="assets/images/HoverTT.png" class="help-icon-img" />
                </a></span>
            </div>
            <div class="col-lg-12">
              <input type="radio" formControlName="IsMakeAllCustomerTaxable" [value]=true checked
                style="margin-bottom: 0px;" />
              All customers
            </div>
            <div class="col-lg-12">
              <input type="radio" formControlName="IsMakeAllCustomerTaxable" [value]=false />
              Only taxable customers
            </div>
          </div>

          <div class="row">
            <div class="col-lg-12">
              Sales tax item:
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <select id="_SalesTaxItem" formControlName="SalesTaxItem" class="form-control">
                <option *ngFor="let item of salesTaxItems" value={{item.itemCode}}>
                  {{item.itemName}}
                </option>
              </select>
              <li class="col-md-12" *ngIf="configForm.controls['SalesTaxItem'].invalid"
                style="margin-top:-15px;font-size:smaller;color: rgb(197, 28, 28);margin-left: -15px;">Sales Tax item is
                required</li>

              <i> Select QuickBooks Online Tax Product/Item Name mapped with sub account of type Sales Tax Payable for
                integration</i>
            </div>
          </div>
          <br>
          <div class="row">
            <div class="col-lg-12">
              <input type="checkbox" formControlName="IsEcoSystemDisabled" /> Is eco-system disabled?
            </div>
          </div>

          <div class="row">
            <div class="col-lg-12">
              <input type="checkbox" formControlName="IsApiThrottleExceeded" style="margin:0px;" /> Is API throttling exceeded?
            </div>
            <div class="col-lg-12" style="margin-bottom:10px;padding-left: 31px;" *ngIf="isApiThrottleExceeded == true">
              Last updated on {{ apiThrottleTimeStamp }}
            </div>
            <div style="padding:5px;" *ngIf="isApiThrottleExceeded == false"></div>
          </div>

          <div class="row">
            <div class="col-lg-12">
              <input type="checkbox" formControlName="IsUserActive" /> Is calculation activated?
            </div>
          </div>

        </div>

          <div class="row" *ngIf="isDisconnected == true">
            <div class="col-lg-12" >
              <br>
              <b>As customer is disconnected, you can not update configuration setting or reconnect through admin panel.</b>
            </div>
          </div>
          <br>
          <div class="row">
            
            <div class="col-lg-12" style="text-align: right;">
              <input type="button" id="btnSave" value="Update" name="Save" class="buttonNormal"
              (click)="updateConfig()" *ngIf="isDisconnected == false" style="margin-right: 20px;">

              <input type="button" id="btnBack" value="Cancel" name="Cancel" class="buttonNormal"
                (click)="resetSelected()" />
            </div>
          </div>

        </div>
      </form>
    </div>
  </div>


  <!-- <div class="row" style="margin-top:20px; display: block !important">
    <a [routerLink]="[ '/home']" style="margin:0px;">
      <button tabindex="-1" class="buttonNormal" style="width:100px;" id="btnReturn"
        AutomationAttribute="Reconciliation_Button_Return">
        <span class="button-text">
          Back
        </span>
      </button>
    </a>
  </div> -->
  <!-- 
<ng-template #content let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Profile update</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form>
        <div class="form-group">
          <label for="dateOfBirth">Date of birth</label>
          <div class="input-group">
            <input id="dateOfBirth" class="form-control" placeholder="yyyy-mm-dd" name="dp" ngbDatepicker #dp="ngbDatepicker">
            <div class="input-group-append">
              <button class="btn btn-outline-secondary calendar" (click)="dp.toggle()" type="button"></button>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Save</button>
    </div>
  </ng-template> -->
</div>
