import { Pipe, PipeTransform } from '@angular/core';
import { AdditionalNexuses } from '../models/NexusSetup';

@Pipe({
    name: 'nexusWhyContent'
})
export class NexusWhyContent implements PipeTransform{
    transform(value:AdditionalNexuses): string{
        return ((value.corporateHeadQuarters !== null) ? value.corporateHeadQuarters : '')  + ' ' + value.totalInvoice + ' of the most recent 1000 invoices shipped to here' + ((value.totalInvoice > 0 && value.taxedInvoice > 0) ? (', with ' + (value.taxedInvoice * 100 / value.totalInvoice).toFixed(2) + '% of them including sales tax.') : '.');
        
    }
}
