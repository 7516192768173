import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { HeaderComponent } from './layout/header.component';
import { LearnMoreComponent } from '../Connector/learnmore/learnmore.component'

import { ErrorComponent } from './layout/error.component';
import { HttpClientModule } from '@angular/common/http';
import { SpinnerComponent } from './spinner/spinner.component';
import { LaunchComponent } from './layout/launch/launch.component';
import { DisconnectComponent } from './layout/disconnect/disconnect.component';

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    HttpClientModule
  ],
  declarations: [HeaderComponent, LearnMoreComponent, ErrorComponent, SpinnerComponent, LaunchComponent, DisconnectComponent],
  exports: [HeaderComponent, LearnMoreComponent, ErrorComponent, SpinnerComponent, LaunchComponent, DisconnectComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class SharedModule { }
