<aui-header id="connectorHeader" productname="&nbsp;for QuickBooks Online" alt="Avalara AvaTax" 
[user]="user"
class="hide-account-menu"
    homeurl="#">
</aui-header>

<aui-header id="avaHeader" productname="" alt="Avalara AvaTax" 
[user]="user" class="hide-account-menu displayNone"
    homeurl="#">
</aui-header>

<div id="avaHeader-content" class="displayNone">
    <img src="/assets/images/qboonline.png"  height="30px" >
</div>

<div id="dvLoginDetail" *ngIf="loggedIn" class="loginDetailHeader">
    <div *ngIf="!isOktaLoggedIn" class="loggedInContainer">
        <div *ngIf="avaTaxUserName">
            <span class="userLabel"><span class="title">AvaTax User:</span>&nbsp; {{avaTaxUserName}}</span>
            <s-pipe></s-pipe>
            {{avaTaxUserType}}
            <s-pipe></s-pipe>
            <a href="#" class="logout" (click)="logOut()">Log out</a>
            <br>
            <div *ngIf="companyName" class="qbocompanynamelabel">QuickBooks Online Company: {{companyName}}</div>
        </div>
    </div>
    <div *ngIf="isOktaLoggedIn" class="loggedInContainer">
        <span *ngIf="avaTaxUserName" class="userLabel">Support User
            : {{ avaTaxUserName }}</span>
        <s-pipe></s-pipe>
        <a href="javascript:void(0);" class="logout" (click)="oktaLogOut()">Log out</a>
    </div>
</div>

<s-dialog id="unauthorizedErrorDialog" style="z-index:500;" aria-labelledby="dialog-title" aria-modal="true" noscroll="">
    <div slot="header" id="unauthorizedErrorDialogHeader">Session timout</div>
    <div slot="body">Your session has expired. Please login to pick up where you left off.</div>
    <div slot="footer">
        <button id="unauthorizedErrorDialogOk" class="primary small" (click)="closeUnauthorizedErrorDialog()">Ok</button>
    </div>
</s-dialog>