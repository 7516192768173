import { Component, OnInit, Injectable, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute, NavigationStart, NavigationEnd, RouterEvent } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { DeactivationGuarded } from 'src/app/shared/gaurds/candeactivate.guard';
import { ItemManagementService } from '../services/itemmanagement.service'
import { FetchItemResponse, QboItemRequest } from '../models/itemManagementModel'
import { SpinnerService } from '../../shared/spinner/spinner.service';
import { AuthenticationService } from '../../shared/services/authentication.service';
import { CommonService } from '../services/common.service';
import { userCredentials } from '../models/UserCredentials';
import { OAuthSessionResponse } from '../../shared/models/OAuthToken';
import { DataTableDirective } from "angular-datatables";
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-itemmanagement',
  templateUrl: './itemmanagement.component.html',
  styleUrls: ['./itemmanagement.component.css']
})
export class ItemManagementComponent implements OnInit, DeactivationGuarded {

  searchForm: FormGroup;
  oAuthToken: OAuthSessionResponse;
  userCreds: userCredentials;
  errorMessage: any;
  showAlert: boolean = false
  alertMessage: string
  alertCssClass: string;
  scrollTop: number = 0
  showValidateAvaTaxAccountButton: boolean;
  accountValidationMessage: string;

  selectedIds: Array<string> = [];
  isSelectAllChecked: boolean = false;
  isItemManagementList: boolean = false;
  itemManagementList: Array<FetchItemResponse> = [];
  allItemManagementList: Array<FetchItemResponse> = [];
  qboItemRequest: Array<string> = [];

  statusClass: string;
  iterationCnt: number = 0;

  isItemListLoaded: boolean = false;
  filterItemText: string = '';
  filterIterationCnt: number = 0;

  @ViewChildren(DataTableDirective)
  dtElements: QueryList<DataTableDirective>;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  isPageRefreshed: boolean = false;
  mySubscription: any;
  sourceParam: string;

  constructor(private itemManagementService: ItemManagementService,
    private commonService: CommonService,
    private spinnerService: SpinnerService,
    private authService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute) {

    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this.mySubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // Trick the Router into believing it's last link wasn't previously loaded
        this.router.navigated = false;
      }
    });
  }

  canDeactivate(): boolean | Promise<boolean> {
    this.oAuthToken = this.authService.fetchAIAuthTokenFromStorage();
    if (this.oAuthToken) {
      if (this.searchForm && this.searchForm.dirty) {
        return window.confirm('There are some unsaved changes. Do you really want to navigate away?')
      }
    }
    return true;
  }
  
  async ngOnInit() {

    this.sourceParam = this.route.snapshot.paramMap.get("source");
    this.sourceParam = this.sourceParam == null ? "" : this.sourceParam;
  
    if (this.sourceParam != "") {
      $("#connectorHeader").hide();
      $("#dvLoginDetail").hide();
      $("#avaHeader").show();
      $("#avaHeader-content").show();
    }
    
    this.initializeDatatables();

    this.oAuthToken = this.authService.fetchAIAuthTokenFromStorage();
    if (this.oAuthToken) {
      this.spinnerService.show();

      // Refresh JWT Token - Extend 25mins more with new JWT Token from API
      await this.commonService.extendJwtDuration();

      if (!this.router.navigated) {
        this.searchItems();
      }
      this.spinnerService.hide();
    }
    else {
      alert('Session is timed out. Please log in again.')
      this.router.navigate(['/learnmore']);
    }
  }
  

  initializeDatatables() {
    this.dtOptions[0] = {
      retrieve: true,
      paging: true,
      lengthChange: true,
      searching: false,
      pageLength: 10,
      columnDefs: [{ targets: 3, orderable: false }],
      pagingType: 'simple_numbers',
      order: [[1, 'desc']],
      serverSide: false,
      processing: false
    }
  }

  objectToHttpParams(obj: any) {
    return Object.entries(obj || {}).reduce((params, [key, value]) => {
      return params.set(
        key,
        value === 'object' ? JSON.stringify(value) : String(value)
      );
    }, new HttpParams());
  }

  searchItems() {
    this.oAuthToken = this.authService.fetchAIAuthTokenFromStorage();
    if (this.oAuthToken) {
        this.compareItems(this.oAuthToken.userId, this.oAuthToken.session_Id);
    }
    else {
      alert('Session is timed out. Please log in again.')
      this.router.navigate(['/learnmore']);
    }
  }

  compareItems(id: string, authToken: string): void {
    try {
      var current = this;
      current.iterationCnt = 0;
      current.statusClass = "";
      current.isItemListLoaded = false;
      current.spinnerService.show();

      this.itemManagementService.compareItems(id, authToken).subscribe(
        (response: any) => {

          if (response.isSentToSearch == true) {
            console.log("Items sent to lambda for comparison")
            this.spinnerService.show();
            this.IterativeFetch();
            //this.spinnerService.hide();
          }
          else {
            console.log("Item compare sqs is not created.")
          }
        },
        (error: any) => {
          this.errorMessage = <any>error;
          this.spinnerService.hide();
          if(this.errorMessage != "unauthorized")
            alert(this.errorMessage);
        },
        () => { }
      );
    }
    catch (error) {
      console.error(error.message)
      this.spinnerService.hide()
    }
  }

  IterativeFetch() {
    this.oAuthToken = this.authService.fetchAIAuthTokenFromStorage();
    if (this.oAuthToken) {
      this.fetchComparedItems(this.oAuthToken.realmId, this.oAuthToken.session_Id, 0);
    }
  }

  fetchComparedItems(realmId: string, authToken: string, iterationCount: number): void {
    try {
      var current = this;
      this.spinnerService.show();
      this.itemManagementService.fetchComparedItems(realmId, authToken).subscribe(
        (model: any) => {
          console.log("Fetch Item count: ", model.comparedData != null ? model.comparedData.length : model);

          if (model.comparedData != null && model.comparedData != undefined) {
           
                current.allItemManagementList = model.comparedData;
                current.itemManagementList = current.allItemManagementList;
                
                console.log("itemManagementList count: ", current.itemManagementList.length);
                current.rerender();

                if (current.itemManagementList.length > 0){
                    current.isItemManagementList = true;
                    current.isItemListLoaded = true;
                }
             
              current.spinnerService.hide();
          }
          else {
            if (iterationCount <= 180) { //15mins :- 180 calls every 5secs.
              setTimeout(function () {
                console.log("iterationCount:" + iterationCount);
                current.fetchComparedItems(realmId, authToken, iterationCount++);
              }, 3000);
            }
          }
        },
        (error: any) => {
          this.errorMessage = <any>error;
          this.spinnerService.hide();
          if(this.errorMessage != "unauthorized")
            alert(this.errorMessage);
        },
        () => { }
      );
    }
    catch (error) {
      console.error(error.message)
      this.spinnerService.hide()
    }
  }

  filterItems(status){
    var current = this;
    current.spinnerService.show();
    this.iterationCnt = this.iterationCnt + 1;
    if(this.iterationCnt == 1){
      this.itemManagementList = new Array<FetchItemResponse>();
      setTimeout(function(){current.filterItems(status); }, 500);
    }
    else {
      this.iterationCnt = 0;
      if(this.allItemManagementList.length > 0) {
      if(status == "filter"){
        if(current.filterItemText === ''){
          this.itemManagementList = this.allItemManagementList.filter(o => o.itemCode == "");
          this.rerender();
        }
        else {
          this.itemManagementList = this.allItemManagementList.filter(o => o.itemCode.includes(current.filterItemText.trim()) || (o.description == null ? "": o.description).includes(current.filterItemText.trim()));
          this.rerender();
        }
      }
      else
      {
        this.filterItemText = "";
        this.itemManagementList = this.allItemManagementList.filter(o => o.status == status);
        this.rerender();
      }
    }
      current.spinnerService.hide();
    }
    this.statusClass = status;
  }

  rerender(): void {
    if (this.dtElements) {
      this.dtElements.forEach((dtElement: DataTableDirective) => {
        dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.clear();
          dtInstance.destroy();
          this.dtTrigger.next();
        });
      });
    }
  }

  ngAfterViewInit() {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
    if (this.mySubscription) {
      this.mySubscription.unsubscribe();
    }
  }

  selectAll(event) {
    if (event.target.checked) {
      this.isSelectAllChecked = true;
      var selectedCount = 0;
      for (var row of this.itemManagementList) {
        if(row.status == "New"){
          row.isSelected = true;
          selectedCount++;
        }
      }
      alert("All (" + selectedCount + ") Qbo Items will be selected.")
    }
    else {
        for (var row of this.itemManagementList) {
          row.isSelected = false;
        }
    }
  }

  selectItemValue(target) {
    let isSelected = target.checked;
    for (var row of this.itemManagementList) {
      if (row.id == target.value) {
        row.isSelected = isSelected;
        break;
      }
    }
    if (!target.checked) {
      this.isSelectAllChecked = false;
    }
  }

  clearForm() {
    this.initializeDatatables();
    this.itemManagementList = [];
    this.isItemManagementList = false;
    this.dtTrigger.next();
    this.statusClass = "";
    this.isItemListLoaded = false;
  }
 
  SendToSync() {

    this.oAuthToken = this.authService.fetchAIAuthTokenFromStorage();
    if (this.oAuthToken) {

      let isSelected = false;
      for (var row of this.itemManagementList) {
        if (row.isSelected) {
          isSelected = true;
          break;
        }
      }

      if (isSelected) {
        for (var row of this.itemManagementList) {
          if (row.isSelected) {
            if (row.itemCode != "") {
              this.qboItemRequest.push(row.id);
            }
          }
        }

        console.log("qboItemRequest", this.qboItemRequest);

        if (this.qboItemRequest.length > 0) {
          var current = this;
          current.spinnerService.show();
          this.oAuthToken = this.authService.fetchAIAuthTokenFromStorage();
          if (this.oAuthToken) {
            this.itemManagementService.resendQboItems(this.oAuthToken.userId, current.qboItemRequest, this.oAuthToken.session_Id)
              .subscribe(
                (model: any) => {
                  if (model != true) {

                    alert("QuickBooks items will be sent to IMS Catalog in the background. \n 1. It will take sometime according to the items count.\n 2. Please DO NOT PROCESS same items again to avoid conflict.\n 2. Please wait and refresh to check items are processed.");

                    current.qboItemRequest = [];
                    for (var row of current.itemManagementList) {
                      row.isSelected = false;
                    }
                  }
                  current.spinnerService.hide();
                },
                (error: any) => {
                  this.errorMessage = <any>error;
                  this.spinnerService.hide();
                  if(this.errorMessage != "unauthorized")
                    alert(this.errorMessage);
                },
                () => { }
              );
          }
        }
      }
      else {
        alert("Please select QuickBooks items for recalculation.");
      }
    }
    else {
      alert('Session is timed out. Please log in again.')
      this.router.navigate(['/learnmore']);
    }

    return false;
  }
}
