import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../shared/services/authentication.service';
import { AvaecosystemService } from '../services/avaecosystem.service';
import { QboContextData, QboDocumentData, QboAvaTaxConfigInfo, ProductInfo, DepartmentInfo, AvaTaxResult, NewDocumentRequestData, VoidOrDeleteRequestData, GetTaxRequestModel } from '../models/avataxlanding';
import { waitForAsync } from '@angular/core/testing';
//import { stringify } from '@angular/core/src/render3/util';
import { DatePipe } from '@angular/common';
import { Constants } from '../../shared/models/Constants';

declare global {
  interface Window { qboXDMReady(); qboXDMReceiveMessage(msg, successFn, errorFn); avaTaxLandingThis: any; }
  var qboXDM: any
  
  var avaTaxLandingThis
}

@Component({
  selector: 'app-avataxlanding',
  templateUrl: './avataxlanding.component.html',
  styleUrls: ['./avataxlanding.component.css']
})
export class AvataxlandingComponent implements OnInit {

  hideSpinner: boolean = true
  ShowErrorButton: boolean = false
  ShowEstimateTaxButton: boolean = true

  globalContext: any = null;
  modelIntervalHandle: any;
  isGlobalContextFound: boolean = false;
  isGlobalModelFound: boolean = false;
  globalModel: any = null;
  qbEvent: any;
  destinationAddress: any;
  originAddress: any
  qboSalesTaxItemId: string
  qboChartOfAccountId: string
  guidForNewDocNumber: string
  customerInfo: any
  avaTaxResponse: any
  productInfo: ProductInfo[] = []
  documentErrorDetails: string

  departmentInfo: DepartmentInfo[] = []
  integrationStatus: string = ''
  isEcoSystemDisabled : boolean = false;
  isActiveIntegration: boolean = true;
  isDisconnectedIntegration : boolean = false;
  isIntegrated: boolean = true;
  isDownIntegration: boolean = true;
  qboAvaTaxConfigInfo: QboAvaTaxConfigInfo = new QboAvaTaxConfigInfo()
  isTaxRatePresent: boolean;
  isPartnerTaxEnabled: boolean;
  avaTaxStartDate: any;
  preventPostSaveIfErrorInGetTax: boolean = false;

  qboDocumentPostSave: QboDocumentData

  ASTEnabledPopupText = '<br />AvaTax sales tax determination could not be used as we have detected that QuickBooks automated sales tax is currently enabled.  Your transaction will be sent to AvaTax with the QuickBooks calculation.<br /><br />To use Avalara sales tax determination, please disable QuickBooks automated sales tax and re-save the transaction.<br /><br />For more information, please go to the <a target="_blank" href="https://help.avalara.com/Frequently_Asked_Questions/QuickBooks_Online_-_Knowledge_Base/Why_is_AvaTax_for_QuickBooks_Online_and_QuickBooks_Online_calculating_tax_at_the_same_time%253F">Avalara Help Center</a>.<br />';


  constructor(private authService: AuthenticationService, private datePipe: DatePipe, private avaEcoSystemService: AvaecosystemService) {
    this.authService.showHeader(false);
    window.addEventListener("message", function (a) {
      if (a.origin.indexOf("intuit.com") >= 1 && a.data && a.data.initXDM) {
        var b = document.createElement("script"); b.setAttribute("type", "text/javascript"); b.innerHTML = a.data.initXDM;
        document.getElementsByTagName("head")[0].appendChild(b)

      }
    });
  }

  ngOnInit() {
    window.qboXDMReady = () => { }//this.Init
    window.qboXDMReceiveMessage = function (msg, successFn, errorFn) {
      window.avaTaxLandingThis.ProcessQboTxnEvent(msg)
    }

    let initIntervalHandle = setInterval(() => {
      if (qboXDM != null || qboXDM != undefined) {
        clearInterval(initIntervalHandle); // stop the interval
        this.Init()
      }
    }, 1000);
    window.avaTaxLandingThis = this
  }

  isValidDocumentDate(documentDate){
    console.log("avaTaxStartDate: "+this.avaTaxStartDate +", documentDate: "+ documentDate);
    if (typeof this.avaTaxStartDate !== 'undefined' && this.avaTaxStartDate){
      return new Date(this.avaTaxStartDate.split('T')[0]+"T00:00:00").getTime() <= new Date(documentDate+"T00:00:00").getTime();
    }  
    return true;
  }

  estimateTax() {
    //alert('Estimate tax clicked') 
    const qboContext = new QboContextData(this.globalContext)
    this.authService.getUpdatedConfigInfo(qboContext.realmId).then((config) => {
    this.avaTaxStartDate = config.avaTaxStartDate;
    if (this.isActiveIntegration) {
      if (!(this.isPartnerTaxEnabled && this.isTaxRatePresent)) {
        this.avaEcoSystemService.getGlobalModelFromqboXDM(qboXDM).then((model) => {
          this.globalModel = model;
          console.log("qboDoc:", this.globalModel);
          if(this.isValidDocumentDate(this.globalModel.date)){  
            if ((this.globalModel.nameId != "" && this.globalModel.nameId != null) || this.IsIgnorePaidStatusForDocument(this.globalModel)) {
              if (this.globalModel.amountPaid == undefined || this.IsIgnorePaidStatusForDocument(this.globalModel)) {
                if (this.globalModel.discountInfo.discountPercent == "" || this.globalModel.discountInfo.discountPercent == "0.00%") {
                  this.GetAvaTaxFromUI(this.globalModel, true);
                }
                else {
                  qboXDM.showOkDialog('Avalara - AvaTax', '<br />Selected Discount Type is “Discount Percent”!<br /><br />Documents are processed at real-time when the Discount is added as a value and takes some time when added as a percentage.<br />To view the processed document with Discount as percentage, refresh the page after some time.');
                }
              }
              else {
                qboXDM.showOkDialog('Avalara - AvaTax', '<br />Avalara AvaTax cannot calculate tax on documents with partial / full payments.<br />');
              }
            }
            else {
              qboXDM.showOkDialog('Avalara - AvaTax', '<br />Customer is not selected!<br /><br />Please select a customer.<br />');
            }
          }
          else {
            qboXDM.showOkDialog('Avalara - AvaTax', '<br />AvaTax calculation Start date: '+ this.datePipe.transform(this.avaTaxStartDate, Constants.DateInputFormat) +' set in the Connector Configuration is restricting the tax calculation.<br />');
          }
        })
      }
      else {
        qboXDM.showOkDialog('', this.ASTEnabledPopupText)
      }
    }
    else if (this.isDownIntegration && !this.isDisconnectedIntegration) {
      qboXDM.showOkDialog('Avalara - AvaTax', '<br />Your Avalara AvaTax integration is not connected to QuickBooks Online!<br /><br />Please activate the integration.<br />');
    }
    }).catch((error) => console.log(error));
  }

  showAvaTaxError() {
    qboXDM.showOkDialog('Avalara - AvaTax Error', `<br /><div>${this.documentErrorDetails}<div>`);
  }

  Init() {
    this.ProcessAvaTax()
  }

  ProcessAvaTax = () => {
    //alert('Hello from processavatax')    
    qboXDM.showSpinner();
    this.avaEcoSystemService.getGlobalModelFromqboXDM(qboXDM).then(
      (model) => this.globalModel = model
    ).catch((error) => console.log(error))
    this.avaEcoSystemService.getGlobalContextFromqboXDM(qboXDM).then(
      (context) => this.globalContext = context
    ).then(() => {
      this.GetOriginAddress()

    }).catch((error) => console.log(error))
  }

  public GetOriginAddress() {
    const qboContext = new QboContextData(this.globalContext)
    this.avaEcoSystemService.getOriginAddress(qboContext).then((qboOriginAddress) => {
      this.originAddress = qboOriginAddress.originAddress;
      this.qboSalesTaxItemId = qboOriginAddress.qboSalesTaxItemId;
      this.qboChartOfAccountId = qboOriginAddress.qboChartOfAccountId;
      this.integrationStatus = qboOriginAddress.integrationStatus;
      this.isEcoSystemDisabled = qboOriginAddress.ecoSystemStatus;

      if(this.integrationStatus == "Disconnected"){
        this.isActiveIntegration = false;
        this.isDisconnectedIntegration = true;
        this.isIntegrated = false;
        this.isDownIntegration = true;
      }
      else if (this.integrationStatus === 'Active') {
        this.isActiveIntegration = true;
        this.isIntegrated = true;
        this.isDownIntegration = false;
        this.isDisconnectedIntegration = false;
      } else if (this.integrationStatus === 'Down') {
        this.isActiveIntegration = false;
        this.isIntegrated = true;
        this.isDownIntegration = true;
        this.isDisconnectedIntegration = false;
      } else if (this.integrationStatus === 'NotIntegrated') {
        this.isActiveIntegration = false;
        this.isIntegrated = false;
        this.isDownIntegration = false;
        this.isDisconnectedIntegration = false;
      }
      
      if (this.isActiveIntegration) {
        if(this.isEcoSystemDisabled){
          this.documentErrorDetails = qboOriginAddress.errorMessage
          this.ShowEstimateTaxButton = false;
          this.ShowErrorButton = true;
        }
        else{
          this.GetCustomerInfoWithTaxRate()
          this.GetProductInfo(-1, 1)
          this.GetDepartmentInfo(-1, 1)
  
          //execute continuous thread to update client side customer info if customer is changed
          setInterval(() => {
            this.avaEcoSystemService.getGlobalModelFromqboXDM(qboXDM).then(
              (model) => {
                this.globalModel = model
                if(this.globalModel.nameId != null){
                    if (this.customerInfo != null) {
                      if (this.globalModel.nameId != this.customerInfo.id) {
                        //alert("Call customer Id not matched");
                        this.GetCustomerInfoWithTaxRate()
                      }
                    }
                    else {
                      //setTimeout(() => {this.GetCustomerInfoWithTaxRate(); //alert("customer is null")}, 20000)
                      this.GetCustomerInfoWithTaxRate(); 
                      //alert("customer is null")
                    }
                }
              }
            ).catch((error) => console.log(error))
          }, 2000)
        }
      }
      else {
        if(this.integrationStatus == "Disconnected"){
          this.ShowEstimateTaxButton = false;
          this.ShowErrorButton = false;
        }
        else if (!this.isIntegrated) {
          this.documentErrorDetails = qboOriginAddress.errorMessage
          this.ShowEstimateTaxButton = false
          let isUserRegisteredWithAvaTax = qboOriginAddress.errorMessage.indexOf("User is not registered with AvaTax!") < 0;
          this.ShowErrorButton = isUserRegisteredWithAvaTax
        }
      }
      if (this.isIntegrated) {
        //ShowCalculateTaxDiv();
        if (this.isDownIntegration) {
          this.ShowErrorButton = false
        }
      }

    }).catch((error) => console.log(error))
  }

  public GetCustomerInfoWithTaxRate() {

    let qboContext = new QboContextData(this.globalContext);
    let qboDocument = new QboDocumentData(this.globalModel, this.qboAvaTaxConfigInfo, this.productInfo, this.departmentInfo);

    this.avaEcoSystemService.getCustomerInfoWithTaxRate(qboContext, qboDocument).then((res) => {
      this.isTaxRatePresent = res.isTaxRatePresent;
      this.isPartnerTaxEnabled = res.isPartnerTaxEnabled;
      this.customerInfo = res.customer;
      //alert(JSON.stringify(this.customerInfo, null, 4));
    })
  }

  public GetProductInfo(intialTotalCount: number, initialStartPosition: number) {
    const qboContext = new QboContextData(this.globalContext);
    this.avaEcoSystemService.getProductInfo(intialTotalCount, initialStartPosition, qboContext).then((productInfoResponse) => {
      let totalcount = -1;
      let startPosition = 1;
      //console.log("Product info response - "+JSON.stringify(productInfoResponse, null, 4));
      if (productInfoResponse != null) {
        totalcount = productInfoResponse.totalCount;
        startPosition = productInfoResponse.startPosition;
        this.productInfo = this.productInfo.concat(productInfoResponse.item)
      }
      //$.extend(this.productInfo, productInfoResponse.productInfo);
      //console.log("ProductInfo array values - "+JSON.stringify(this.productInfo, null, 4));
      if (totalcount != -1 && startPosition < totalcount) {
        this.GetProductInfo(totalcount, startPosition);
      }
    })
  }

  public GetDepartmentInfo(intialTotalCount: number, initialStartPosition: number) {
    const qboContext = new QboContextData(this.globalContext);
    this.avaEcoSystemService.getDepartmentInfo(intialTotalCount, initialStartPosition, qboContext).then((departmentInfoResponse) => {
      let totalcount = -1;
      let startPosition = 1;
      if (departmentInfoResponse != null) {
        totalcount = departmentInfoResponse.totalCount;
        startPosition = departmentInfoResponse.startPosition;
      }
      this.departmentInfo = this.departmentInfo.concat(departmentInfoResponse.department)
      if (totalcount !== -1 && startPosition < totalcount) {
        this.GetDepartmentInfo(totalcount, startPosition);
      }
    })
  }

  private IsIgnorePaidStatusForDocument(globalModel) {
    return globalModel.txnTypeId === 32 || globalModel.txnTypeId === 33 || globalModel.txnTypeId === 34 || globalModel.txnTypeId === 4;
  }

  private ShowQboXdmSpinner() {

    this.hideSpinner = false;
    const timeOutCallback = function () {
      if (!this.hideSpinner) {
        // if hideSpinner is not set to true by xhr callback handler, then keep on showing the spinner.
        //qboXDM.showSpinner(showSpinnerCallback);
      }
    };
    // this callback is called right away by qboXDM.showSpinner with the argument obj containing timeoutInterval.
    // we set timer with that timeoutInterval to check if we need to continue to show spinner            
    let showSpinnerCallback = function (obj) {
      // check after the timeoutInterval/2 if we need to continue to show spinner. 
      // timeoutInterval/2 rather than timeoutInterval so that we show the spinner continously rather than showing for 5 secs, hiding for a sec and showing again
      setTimeout(timeOutCallback, obj.timeout / 2);
    };
    qboXDM.showSpinner(showSpinnerCallback);
  }

  private HideQboXdmSpinner() {
    this.hideSpinner = true;
    qboXDM.hideSpinner();
  }

  public GetAvaTaxFromUI(globalModel, isEstimateTax) {
    this.ShowQboXdmSpinner()
    this.CalulateAvaTaxFromUI(globalModel, isEstimateTax)
  }

  public CalulateAvaTaxFromUI(globalModel, isEstimateTax) {
    let modelToReurn;
    let refreshProductInfo = false;
    let refreshLocationInfo = false;
    let newItem = [];
    let locationIds = [];
    const qboContext = new QboContextData(this.globalContext);

    //Check if itemDetails present
    if (globalModel.itemDetails != null || globalModel.itemDetails.length > 0) {
      for (let item of globalModel.itemDetails) {
        let product = this.productInfo.filter((product) => product.id === item.itemId) //[item.itemId]
        
        if (!(product && product.length > 0) && !item.isSubtotalLine && item.itemType != "BUNDLE" && item.salesDetailType != "BUNDLE_END") {
          refreshProductInfo = true
          newItem.push(item.itemId)
        }
      }

      // if (globalModel.deptId != null && this.departmentInfo != undefined) {
      //   let dictionaryCount = Object.keys(this.departmentInfo).length;
      //   if (dictionaryCount > 0) {
      //     locationIds = Object.keys(this.departmentInfo);
      //     if (!(this.departmentInfo.hasOwnProperty(globalModel.deptId))) {
      //       locationIds.push(globalModel.deptId);
      //       refreshLocationInfo = true;
      //     }
      //   }
      // }

      if(globalModel.deptId != null && this.departmentInfo != null && this.departmentInfo.length > 0){
        locationIds = this.departmentInfo.map(x => x.id);
        let isDeptExists : boolean = this.departmentInfo.filter(x=>x.id == globalModel.deptId).length > 0;
        if(!isDeptExists){
          locationIds.push(globalModel.deptId);
          refreshLocationInfo = true;
        }
      }

      if (refreshProductInfo) {
        refreshProductInfo = false;
        this.avaEcoSystemService.getNewProductInfo(newItem, qboContext).then((newProducts) => {
          this.productInfo = this.productInfo.concat(newProducts)
          if (refreshLocationInfo) {
            this.avaEcoSystemService.getNewDepartmentInfo(locationIds, qboContext).then((newDepartmentInfoResponse) => {
              if (newDepartmentInfoResponse) {
                this.departmentInfo = this.departmentInfo.concat(newDepartmentInfoResponse);
              }
              this.MakeAvaTaxRequestWithNewProductOrDepartment(globalModel, isEstimateTax, this.originAddress, this.destinationAddress, this.customerInfo, this.qbEvent)
            })
          }
          else {
            this.MakeAvaTaxRequestWithNewProductOrDepartment(globalModel, isEstimateTax, this.originAddress, this.destinationAddress, this.customerInfo, this.qbEvent)
          }
        })
      }
      else if (refreshLocationInfo) {
        refreshProductInfo = false;
        this.avaEcoSystemService.getNewDepartmentInfo(locationIds, qboContext).then((newDepartmentInfoResponse) => {
          if (newDepartmentInfoResponse) {
            this.departmentInfo = this.departmentInfo.concat(newDepartmentInfoResponse);
          }
          this.MakeAvaTaxRequestWithNewProductOrDepartment(globalModel, isEstimateTax, this.originAddress, this.destinationAddress, this.customerInfo, this.qbEvent)
        })
      }
      else {
        this.MakeAvaTaxRequestWithNewProductOrDepartment(globalModel, isEstimateTax, this.originAddress, this.destinationAddress, this.customerInfo, this.qbEvent)
      }
    }

    return modelToReurn
  }

  public MakeAvaTaxRequestWithNewProductOrDepartment(globalModel, isEstimateTax, originAddress, destinationAddress, customerInfo, qbEvent) {
    this.MakeAvaTaxRequest(globalModel, isEstimateTax, originAddress, destinationAddress, customerInfo, qbEvent).then((obj) => {
      this.preventPostSaveIfErrorInGetTax = false
      if (obj.isTaxRequestSuccessful) {
        this.avaTaxResponse = obj.avaTaxResponse;
        if (obj.errorMessage !== '') {
          this.preventPostSaveIfErrorInGetTax = true;
          this.documentErrorDetails = obj.errorMessage;
          this.ShowErrorButton = true;
        } else {
          this.ShowErrorButton = false;
        }
        globalModel = obj.globalModel;
        // modelToReurn = updatedGlobalModel;
      }
      else {
        if (obj.errorMessage) {
          this.documentErrorDetails = obj.errorMessage;
          this.ShowErrorButton = true;
        }
      }
      this.HideQboXdmSpinner();
    })
  }

  public MakeAvaTaxRequest(globalModel, isEstimateRequest, originAddress, destinationAddress, customerInfo, qbEvent): Promise<any> {
    const promise = new Promise<any>((resolve, reject) => {
      try {
        let errorMessage = '';
        this.avaEcoSystemService.getGlobalModelFromqboXDM(qboXDM).then((model) => {
          let isTaxRequestSuccessful = false;
          globalModel = model;
          let avaTaxResponse;
          let avaTaxResult: AvaTaxResult;
          let shippingAddress = globalModel.shippingAddress == undefined || globalModel.shippingAddress == "" ? (globalModel.shippingInfo != undefined ? globalModel.shippingInfo.shippingAddress : globalModel.shippingAddress) : globalModel.shippingAddress;
          if (
                ((shippingAddress == "" || shippingAddress == undefined) && !this.IsAddressPresent(customerInfo.shipAddr)) 
                && 
                ((globalModel.billingAddress == "" || globalModel.billingAddress == undefined) && !this.IsAddressPresent(customerInfo.billAddr))
              ) {
                  if (isEstimateRequest) {
                    qboXDM.showOkDialog('Avalara - AvaTax', '<br />Billing or Shipping Address is blank!<br /><br />Please provide valid Billing or Shipping Address.');
                    //qboXDM.hideSpinner();
                  }
                  else {
                    qboXDM.showPageMessage("Billing or Shipping Address is blank!<br /><br />Please provide valid Billing or Shipping Address.", true);
                  }
                  isTaxRequestSuccessful = false;
                  resolve({ "isTaxRequestSuccessful": isTaxRequestSuccessful, "errorMessage": errorMessage, "globalModel": globalModel, "avaTaxResponse": avaTaxResponse })
                  //qboXDM.hideSpinner();
          }
          else
          {
            
            const qboContext = new QboContextData(this.globalContext);
            //console.log("Before sending to qbodocument creation"+JSON.stringify(this.productInfo, null, 4))
            const qboDocument = new QboDocumentData(globalModel, this.qboAvaTaxConfigInfo, this.productInfo, this.departmentInfo);

            qboDocument.QbEvent = qbEvent;
            qboDocument.IsTaxRatePresent = this.isTaxRatePresent;

            if (isEstimateRequest) {
              qboDocument.IsEstimateRequest = true;
              qboDocument.OriginAddress = originAddress;
            }
            else {
              
              // alert("this.customerInfo -- " + JSON.stringify(this.customerInfo, null, 4));
              // alert("customerInfo -- " + JSON.stringify(customerInfo, null, 4));
              qboDocument.Customer = customerInfo;
              qboDocument.OriginAddress = originAddress;
              qboDocument.DestinationAddress = destinationAddress;
              if (this.qboSalesTaxItemId !== null || this.qboSalesTaxItemId !== "") {
                qboDocument.QboSalesTaxItemId = this.qboSalesTaxItemId;
              }

              // if (this.QboChartOfAccountId !== null || this.QboChartOfAccountId !== "") {
              // qboDocument.QboChartOfAccountId = this.qboChartOfAccountId;
            }

            if (globalModel.txnId < 0) {
              qboDocument.IsNewTxn = true;
              qboDocument.txnId = this.guidForNewDocNumber;
            }

            //Check if itemDetails present
            if (qboDocument.DocumentLineItems == null || qboDocument.DocumentLineItems.length == 0) {
              if (isEstimateRequest) {
                qboXDM.showOkDialog('Avalara - AvaTax', '<br />No line item found!<br /><br />Please enter a valid line item.');
              }
              else {
                qboXDM.showPageMessage("No line item found!<br /><br />Please enter a valid line item.", true);
              }

              errorMessage = 'No line item found!<br /><br />Please enter a valid line item.';
              isTaxRequestSuccessful = false;
              resolve({ "isTaxRequestSuccessful": isTaxRequestSuccessful, "errorMessage": errorMessage, "globalModel": globalModel, "avaTaxResponse": avaTaxResponse })
              //qboXDM.hideSpinner();
              // return false;
            }
            else {
              qboDocument.context = qboContext;
              
              let getTaxRequestModel: GetTaxRequestModel = new GetTaxRequestModel();
              getTaxRequestModel.qboDocument = qboDocument;
              getTaxRequestModel.documentLineItems = qboDocument.DocumentLineItems;
              this.avaEcoSystemService.getAvaTax(qboContext.realmId, getTaxRequestModel).then((result) => {
                errorMessage = result.errorMessage;
                avaTaxResult = result
                if (avaTaxResult.errorMessage.toLowerCase().indexOf("try again") >= 0) {
                  if (isEstimateRequest) {
                    qboXDM.showOkDialog('Avalara - AvaTax', '<br />' + avaTaxResult.errorMessage);
                    qboXDM.hideSpinner();
                  }
                  isTaxRequestSuccessful = false;
                }
                else if (avaTaxResult.errorMessage == "") {
                  this.SetAvaTax(avaTaxResult);
                  isTaxRequestSuccessful = true;
                  this.qboDocumentPostSave = qboDocument;
                }
                resolve({ "isTaxRequestSuccessful": isTaxRequestSuccessful, "errorMessage": errorMessage, "globalModel": globalModel, "avaTaxResponse": avaTaxResult })
              })
                .catch((error) => {
                  isTaxRequestSuccessful = false;
                  errorMessage = "Internal server error";
                  resolve({ "isTaxRequestSuccessful": isTaxRequestSuccessful, "errorMessage": errorMessage, "globalModel": globalModel, "avaTaxResponse": avaTaxResponse })
                })
            }

          }

        })
      } catch (error) {
        reject(error)
      }

    })

    return promise
  }

  private IsAddressPresent(address:any){
    if(address != null || address != undefined){
      if(address.line1 != null || address.line2 != null || address.city != null || address.countrySubDivisionCode != null || address.postalCode != null || address.country != null)
      {
        return true;
      }
    } 
    return false;
  }

  public SetAvaTax(avaTaxResult: AvaTaxResult) {
    this.avaEcoSystemService.getGlobalModelFromqboXDM(qboXDM).then((model) => {
      this.globalModel = model;
      let result = this.globalModel.itemDetails.filter((item) => item.itemId === avaTaxResult.taxItemId);
      let salesTaxLineText = "Sales Tax calculated by AvaTax on " + new Date().toUTCString().replace(",", "").replace(new Date().getFullYear() + " ", "").replace("GMT", "UTC").concat(" " + new Date().getFullYear());

      if (result.length == 0) {
        let avaSalesTaxService = [{
          accountId: avaTaxResult.accountId, amount: avaTaxResult.avaTax, billable: false, customerId: null, depositsAccountId: null,
          description: salesTaxLineText, discountAmount: "", discountPercent: "",
          employeeId: null, isSale: false, itemId: avaTaxResult.taxItemId, journalCodeId: null, klassId: null, locationId: null,
          markup: "", nameId: null, netAmount: "", order: 2, paymentMethodId: null, quantity: "1", rate: avaTaxResult.avaTax, receivedFromId: null, ref: "",
          sourceTxnId: null, sourceTxnSequence: null, sourceTxnTypeId: null, taskStatusId: null, taxAmount: "", taxCodeId: null, taxable: false, txnDiscountAmount: "", vendorId: null
        }];

        this.globalModel.itemDetails = this.globalModel.itemDetails.concat(avaSalesTaxService);
        console.log('insert sales tax: ', this.globalModel.itemDetails);

      }
      else {
        for (let item of this.globalModel.itemDetails) {
          if (item.itemId === avaTaxResult.taxItemId) {
            item.amount = avaTaxResult.avaTax;
            item.rate = avaTaxResult.avaTax;
            item.description = salesTaxLineText;
            item.quantity = '1';
            break;
          }
        }
        console.log('update sales tax: ', this.globalModel.itemDetails);
      }

      let rdfItemIndx = this.globalModel.itemDetails.findIndex((item) => item.itemId === avaTaxResult.rdfItemId);
      console.log('rdfItemIndx', rdfItemIndx);

      let taxOnRdfItemIndx = this.globalModel.itemDetails.findIndex((item) => item.itemId === avaTaxResult.taxOnRdfItemId);
      console.log('taxOnRdfItemIndx', taxOnRdfItemIndx);

      if(avaTaxResult.rdfAmount != ""){
        let rdfLineText = "Retail Delivery Fee calculated by AvaTax on " + new Date().toUTCString().replace(",", "").replace(new Date().getFullYear() + " ", "").replace("GMT", "UTC").concat(" " + new Date().getFullYear());
        let rdfLineResult = this.globalModel.itemDetails.filter((item) => item.itemId === avaTaxResult.rdfItemId);
        
        if(rdfLineResult.length == 0){
          let rdfItem = {
            accountId: avaTaxResult.rdfItemAccountId, amount: avaTaxResult.rdfAmount, billable: false, customerId: null, depositsAccountId: null,
            description: rdfLineText, discountAmount: "", discountPercent: "",
            employeeId: null, isSale: false, itemId: avaTaxResult.rdfItemId, journalCodeId: null, klassId: null, locationId: null,
            markup: "", nameId: null, netAmount: "", order: 3, paymentMethodId: null, quantity: "1", rate: avaTaxResult.rdfAmount, receivedFromId: null, ref: "",
            sourceTxnId: null, sourceTxnSequence: null, sourceTxnTypeId: null, taskStatusId: null, taxAmount: "", taxCodeId: null, taxable: false, txnDiscountAmount: "", vendorId: null
          };

          this.globalModel.itemDetails.push(rdfItem);
        }
        else {
          for (let item of this.globalModel.itemDetails) {
            if (item.itemId === avaTaxResult.rdfItemId) {
              item.amount = avaTaxResult.rdfAmount;
              item.rate = avaTaxResult.rdfAmount;
              item.description = rdfLineText;
              item.quantity = '1';
              break;
            }
          }
        }

        if(avaTaxResult.taxOnRdfAmount == "" && taxOnRdfItemIndx == -1)
            this.setModelInqboXDM(qboXDM, this.globalModel.itemDetails);
      }
      else {
        
        rdfItemIndx = this.globalModel.itemDetails.findIndex((item) => item.itemId === avaTaxResult.rdfItemId);
        if(rdfItemIndx != -1){
          this.globalModel.itemDetails.splice(rdfItemIndx, 1);
          console.log('old rdfItemIndx removed', this.globalModel.itemDetails);         
        }

        if(avaTaxResult.taxOnRdfAmount == "" && taxOnRdfItemIndx == -1)
            this.setModelInqboXDM(qboXDM, this.globalModel.itemDetails);
      }

      if(avaTaxResult.taxOnRdfAmount != ""){
        
        let taxOnRdfResult = this.globalModel.itemDetails.filter((item) => item.itemId === avaTaxResult.taxOnRdfItemId);
        let taxOnRdfLineText = "Sales Tax calculated on Retail Delivery Fee by AvaTax on " + new Date().toUTCString().replace(",", "").replace(new Date().getFullYear() + " ", "").replace("GMT", "UTC").concat(" " + new Date().getFullYear());
    
        if(taxOnRdfResult.length == 0){
          
          let taxOnRdfItem = {
            accountId: avaTaxResult.taxOnRdfItemAccountId, amount: avaTaxResult.taxOnRdfAmount, billable: false, customerId: null, depositsAccountId: null,
            description: taxOnRdfLineText, discountAmount: "", discountPercent: "",
            employeeId: null, isSale: false, itemId: avaTaxResult.taxOnRdfItemId, journalCodeId: null, klassId: null, locationId: null,
            markup: "", nameId: null, netAmount: "", order: 4, paymentMethodId: null, quantity: "1", rate: avaTaxResult.taxOnRdfAmount, receivedFromId: null, ref: "",
            sourceTxnId: null, sourceTxnSequence: null, sourceTxnTypeId: null, taskStatusId: null, taxAmount: "", taxCodeId: null, taxable: false, txnDiscountAmount: "", vendorId: null
          };

          this.globalModel.itemDetails.push(taxOnRdfItem);
        }
        else {
          for (let item of this.globalModel.itemDetails) {
            if (item.itemId === avaTaxResult.taxOnRdfItemId) {
              item.amount = avaTaxResult.taxOnRdfAmount;
              item.rate = avaTaxResult.taxOnRdfAmount;
              item.description = taxOnRdfLineText;
              item.quantity = '1';
              break;
            }
          }
        }
        this.setModelInqboXDM(qboXDM, this.globalModel.itemDetails);
      }
      else {
        taxOnRdfItemIndx = this.globalModel.itemDetails.findIndex((item) => item.itemId === avaTaxResult.taxOnRdfItemId);
        if(taxOnRdfItemIndx != -1){
          this.globalModel.itemDetails.splice(taxOnRdfItemIndx, 1);
          console.log('old taxOnRdfItemIndx removed', this.globalModel.itemDetails);
        }

        this.setModelInqboXDM(qboXDM, this.globalModel.itemDetails);
      }
    })
  }

  public setModelInqboXDM(qboXDM, itemDetails){
    qboXDM.setModelProperty('itemDetails', itemDetails);
      
    this.avaEcoSystemService.getGlobalModelFromqboXDM(qboXDM).then((model) => {
      this.globalModel = model;
    })
  }

  public setModelToQboXDM(qboXDM, itemDetails){
    qboXDM.setModelProperty('itemDetails', itemDetails);
    console.log('update tax on rdf: ', qboXDM);
    
    this.avaEcoSystemService.getGlobalModelFromqboXDM(qboXDM).then((model) => {
      this.globalModel = model;
    })
  }

  public ProcessQboTxnEvent(msg) {
    this.avaEcoSystemService.getGlobalModelFromqboXDM(qboXDM).then((model) => {
      this.globalModel = model
      if (!this.IsSupportedTransaction(this.globalModel)) {
        return false;
      }
      this.qbEvent = msg.eventName;
      if (msg.eventName === "qbo-transaction") {
        if (this.isActiveIntegration && !this.preventPostSaveIfErrorInGetTax && !this.isEcoSystemDisabled) {
          if (this.globalModel.amountPaid == undefined || this.IsIgnorePaidStatusForDocument(this.globalModel)) {
            if (this.globalModel.discountInfo.discountPercent == "" || this.globalModel.discountInfo.discountPercent == "0.00%") {
              this.ProcessPostSaveQboTxnEvent(this.globalContext, msg, this.avaTaxResponse, this.guidForNewDocNumber);
            }
          }
        }
      }

      if (msg.eventName === "qbo-action-UniversalCrud-save") //"qbo-transaction")// 
      {
        if(this.isActiveIntegration && this.isEcoSystemDisabled){
          qboXDM.showPageMessage("Your UI integration is disabled as per your request. Our backend integration will still calculate tax on this transaction once it is saved in QuickBooks. It may take few minutes to populate tax on transaction.",true);
        }
        else if (this.isActiveIntegration) {
          const qboContext = new QboContextData(this.globalContext)
          this.authService.getUpdatedConfigInfo(qboContext.realmId).then((config) => {
          this.avaTaxStartDate = config.avaTaxStartDate;
          if (!(this.isPartnerTaxEnabled && this.isTaxRatePresent)) {
            if(this.isValidDocumentDate(this.globalModel.date)){
              //32 = Sales receipt which is always paid and can / cant have customer  
              if ((this.globalModel.nameId != "" && this.globalModel.nameId != null) || this.IsIgnorePaidStatusForDocument(this.globalModel)) {
                //prevent tax calculation for paid/partially paid docs.
                if (this.globalModel.amountPaid == undefined || this.IsIgnorePaidStatusForDocument(this.globalModel)) {
                  if (this.globalModel.discountInfo.discountPercent == "" || this.globalModel.discountInfo.discountPercent == "0.00%") {
                    if (this.globalModel.txnId < 0) {
                      this.guidForNewDocNumber = this.Guid();
                      this.globalModel.txnId = this.guidForNewDocNumber;
                    }
                    this.GetAvaTaxFromUI(this.globalModel, false);
                  } else {
                    qboXDM.showPageMessage('<br />Selected Discount Type is “Discount Percent”!<br /><br />Documents are processed at real-time when the Discount is added as a value and takes some time when added as a percentage.<br />To view the processed document with Discount as percentage, refresh the page after some time.', true);
                  }
                } else {
                  //qboXDM.showPageMessage("AvaTax can not be calculated for paid/partially paid documents", true);
                  qboXDM.showPageMessage("Avalara AvaTax cannot calculate tax on documents with partial / full payments.", true);
                }

              } else {
                qboXDM.showPageMessage("Customer is not selected!<br /><br />Please select a customer.", true);
              }
            }
          }
        });

        } else {
          if (this.isDownIntegration && !this.isDisconnectedIntegration) {
            qboXDM.showPageMessage("Your Avalara AvaTax integration is not connected to QuickBooks Online!<br /><br />Please activate the integration.", true);
          }
        }
      }

    })
  }

  public ProcessPostSaveQboTxnEvent(globalContext, msg, avaTaxResponse, guidForNewDocNumber) {
    let QBOActionDetails = msg.data.QBOActionDetails; //saveAndReturn
    let action = msg.data.action; //created //deleted //updated
    let id = msg.data.id;
    let typeId = msg.data.typeId;
    let typeName = msg.data.typeName;
    let amount = msg.data.amount;
    //console.log("Canceltax action - "+ action);
    this.avaEcoSystemService.getGlobalModelFromqboXDM(qboXDM).then((model) => {
      this.globalModel = model

      switch (action) {
        case "created":
          this.ProcessNewDocument(id, typeId, guidForNewDocNumber, avaTaxResponse);
          break;
        case "updated":
          //do nothing backend service will handle it.
          //if (this.globalModel.txnId < 0) {
            this.ProcessNewDocument(id, typeId, guidForNewDocNumber, avaTaxResponse);
          //}
          break;
        case "deleted": //you get "deleted" event in case of both void and delete
          this.ProcessVoidOrDeleteDocument(id, typeId, this.globalModel);
          break;
        default:
          break;
      }
    })
  }

  public ProcessNewDocument(txnId, txnTypeId, guidForNewDocNumber, avaTaxResponse) {
    let qboContext = new QboContextData(this.globalContext);
    let processNewDocumentRequestData = new NewDocumentRequestData()
    processNewDocumentRequestData.realmId = qboContext.realmId
    processNewDocumentRequestData.txnType = txnTypeId
    processNewDocumentRequestData.docCode = guidForNewDocNumber
    processNewDocumentRequestData.newDocCode = txnId
    processNewDocumentRequestData.totalAmount = avaTaxResponse.totalAmount
    processNewDocumentRequestData.totalTax = avaTaxResponse.avaTax
    processNewDocumentRequestData.docDate = avaTaxResponse.docDate
    processNewDocumentRequestData.qboDocumentDataPostSave = this.qboDocumentPostSave

    this.avaEcoSystemService.processNewDocument(processNewDocumentRequestData).then((model) => { }).catch((err) => console.log(err))
  }

  public ProcessVoidOrDeleteDocument(txnId, txnTypeId, globalModel) {
    let qboContext = new QboContextData(this.globalContext);
    let voidOrDeleteRequestData = new VoidOrDeleteRequestData()
    voidOrDeleteRequestData.txnId = globalModel.txnId; //txnId
    voidOrDeleteRequestData.txnType = txnTypeId;
    voidOrDeleteRequestData.qboDocNumber = globalModel.referenceNumber;
    voidOrDeleteRequestData.qboDocumentDate = globalModel.qboDocumentDate;

    this.avaEcoSystemService.processVoidOrDeleteDocument(qboContext.realmId, voidOrDeleteRequestData).then((model) => { }).catch((err) => console.log(err))
  }

  public IsSupportedTransaction(globalModel) {
    switch (globalModel.txnTypeId) {
      case 4:
      case 32:
      case 35:
      case 33:
      case 34:
        qboXDM.adjustFrameHeight("95px");
        return true;
        break;
      default:
        qboXDM.adjustFrameWidth("0px");
        qboXDM.adjustFrameHeight("0px");
        return false;
    }
  }

  public Guid() {
    let date = new Date();
    let components = [
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
      date.getMilliseconds()
    ];

    let id = components.join("");
    return id;
  }

}
