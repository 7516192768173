<s-container>
    <form [formGroup]="homeSettingForm">
    <s-row>
        <br> <br>
    </s-row>
    <s-row>
        <s-col span="sm-12 lg-8 8">
            
            <h2 class="home-manage-integration-h2"> Manage your integration </h2>

            <div class="small-6 columns padded-landing-content padding-avalara-Integration home-tiles-outercontainer">
                    <div class="home-calculateactive-container">
                        <fieldset role="group" aria-labelledby="label4">
                            <input  type="checkbox" name="chkIntegrationStatus" checked="" value="chkIntegrationStatus"
                                id="chkIntegrationStatus" AutomationAttribute="Ava_ConnectorPage_Checkbox_CalculationActive"
                                (click)="toggleUserStatus()" formControlName="chkIntegrationStatus" />


                            <label for="chkIntegrationStatus">Calculation active</label>
                        </fieldset>
                    </div>
                    <div class="home-tiles-container">

                        <!--<a *ngIf="isAccessAllowed" [routerLink]="[ '/connectorsettings' ]" class="box-action-cont">
                            <button automationattribute="Ava_ConnectorPage_Button_Setting"
                                class="secondary icon-leading" id="btnEdit" tabindex="-1">
                                <div class="img-container"><img src="/assets/images/settings.png" />
                                </div>
                                <div class="button-text title"> AvaTax connector Settings </div>
                                <div class="bottom-line"></div>
                            </button>
                        </a>-->

                        <a [routerLink]="[ '/nexussetup' ]" class="box-action-cont">
                            <button automationattribute="Ava_ConnectorPage_Button_Nexus_Setup"
                                class="secondary icon-leading" id="btnLogs" tabindex="-1">
                                <div class="img-container"><img src="/assets/images/collecttax.png" /></div>
                                <div class="button-text title home-tiles-lineheight"> Manage WHERE you
                                    collect tax! </div>
                                <div class="bottom-line"></div>
                            </button>
                        </a>

                        <a [class.disabled]="!homeSettingForm.get('chkIntegrationStatus').value"
                            [routerLink]="[ homeSettingForm.get('chkIntegrationStatus').value ? '/reconciliation' : '#' ]"
                            class="box-action-cont">

                            <div class="home-tiles-container" placement="right" [openDelay]="5"
                                [closeDelay]="50" triggers="mouseenter:mouseleave"
                                [ngbPopover]="!homeSettingForm.get('chkIntegrationStatus').value ? calculationActiveMessage: ''">

                                <button automationattribute="Ava_ConnectorPage_Button_Reconciliation"
                                    class="secondary icon-leading" id="btnReconciliation" tabindex="-1">
                                    <div class="img-container"><img src="/assets/images/reconciliation.png" /></div>
                                    <div class="button-text title home-tiles-lineheight">
                                        Reconciliation Utility </div>
                                    <div class="bottom-line"></div>
                                </button>

                            </div>
                        </a>

                        <a [class.disabled]="!homeSettingForm.get('chkIntegrationStatus').value"
                        [routerLink]="[ homeSettingForm.get('chkIntegrationStatus').value ? '/exemption' : '#' ]" class="box-action-cont">
                            <div class="home-tiles-container" placement="right" [openDelay]="5"
                                [closeDelay]="50" triggers="mouseenter:mouseleave"
                                [ngbPopover]="!homeSettingForm.get('chkIntegrationStatus').value ? calculationActiveMessage: ''">

                            <button automationattribute="Ava_ConnectorPage_Button_Manage_Exemption"
                                class="secondary icon-leading" id="btnLogs" tabindex="-1">
                                <div class="img-container"><img src="/assets/images/manage-exempt.png" /></div>
                                <div class="button-text title home-tiles-lineheight"> Manage Exemptions </div>
                                <div class="bottom-line"></div>
                            </button>
                        </div>
                        </a>

                        <a [class.disabled]="!homeSettingForm.get('chkIntegrationStatus').value"
                        [routerLink]="[ homeSettingForm.get('chkIntegrationStatus').value ? '/itemmanagement' : '#' ]"
                        class="box-action-cont">

                        <div class="home-tiles-container" placement="right" [openDelay]="5"
                            [closeDelay]="50" triggers="mouseenter:mouseleave"
                            [ngbPopover]="!homeSettingForm.get('chkIntegrationStatus').value ? calculationActiveMessage: ''">

                            <button automationattribute="Ava_ItemManagement_Button"
                                class="secondary icon-leading" id="btnItemManagement" tabindex="-1">
                                <div class="img-container"><img src="/assets/images/item-sync.png" />
                                </div>
                                <div class="button-text title"> Manage Product<br>Catalog </div>
                                <div class="bottom-line"></div>
                            </button>

                        </div>
                    </a>

                    <a [class.disabled]="!homeSettingForm.get('isEnableAddressValidation').value || !homeSettingForm.get('chkIntegrationStatus').value"                      
                       [routerLink]="homeSettingForm.get('isEnableAddressValidation').value && homeSettingForm.get('chkIntegrationStatus').value ? '/batchaddress' : null"
                       class="box-action-cont">

                        <div class="home-tiles-container" placement="right" [openDelay]="5"
                            [closeDelay]="50" triggers="mouseenter:mouseleave"
                            [ngbPopover]="!homeSettingForm.get('chkIntegrationStatus').value ? calculationActiveMessage : ''" [ngbPopover]="!homeSettingForm.get('isEnableAddressValidation').value ? 'AvaTax Connector Settings -> Address Validation must be enabled for this feature.': ''" >
                           
                           
                            <button automationattribute="Ava_BatchAddress_Button"
                                class="secondary icon-leading" id="btnBulkAddress" tabindex="-1">
                                <div class="img-container"><img src="/assets/images/Address_Sync.png" />
                                </div>
                                <div class="button-text title">Bulk Address Validation</div>
                                <div class="bottom-line"></div>
                            </button>

                        </div>
                    </a>

                    <a [routerLink]="[ '/advancesettings' ]" class="box-action-cont">
                        <div class="home-tiles-container">
                            <button automationattribute="Ava_ConnectorPage_Button_AdvanceSetting"
                                class="secondary icon-leading" id="btnEdit" tabindex="-1">
                                <div class="img-container"><img src="/assets/images/globe.png" />
                                </div>
                                <div class="button-text title"> Advance <br/>Settings </div>
                                <div class="bottom-line"></div>
                            </button>
                        </div>
                    </a>

                    </div>

                    <s-box class="home-avataxframe-sbox">

                        <label class="display-inline">
                            <h4 class="display-inline-padding-0-20">AvaTax Iframe: </h4>
                        </label>

                        <a target="popup"
                            onclick="window.open('./connectorsettings/avatax','name','width=500,height=620,resizable=0,fullscreen=0')">
                            <button class="primary icon-leading" tabindex="-1">
                                Configure
                            </button>
                        </a>

                        <a target="popup"
                            onclick="window.open('./reconciliation/avatax','name','width=1200,height=680,resizable=0,fullscreen=0')">
                            <button class="ghost icon-leading" tabindex="-1">
                                Reconciliation
                            </button>
                        </a>
                    </s-box>
            </div>
            
        </s-col>
        <s-col span="sm-12 lg-4 4">
            <br />
            <a target="_blank" class="helplink-home-1" (click)="openHelpLink('AboutAvalaraConnector');" rel="help">About Avalara Connector</a>

            <br /><br />
            <div class="small-6 columns padded-landing-content left-piped-section home-info-detail-container">
                <div class="row padding-controls-Top">
                    <h3 class="home-info-detail-h3">
                        There's more you can do in <span>AvaTax</span>
                    </h3>
                </div>
                <div class="row">
                    <a target="_blank" class="pgsmalltextleftpanelcolortext" (click)="openHelpLink('LearnMoreAboutAvatax');"
                         rel="help">Learn more about AvaTax:</a>
                </div>
                <div class="row home-info-detail-line">
                    <span class="description">
                        Changing/adding tax jurisdictions so you can collect tax in the right places
                    </span>
                </div>
                <div class="row home-info-detail-line">
                    <span class="description">
                        Configuring product taxibility so you are using the correct tax
                    </span>
                </div>
                <div class="row home-info-detail-line">
                    <span class="description">
                        Managing customer exemptions
                    </span>
                </div>
                <div class="row home-info-detail-line">
                    <span class="description">
                        Managing monthly tasks to maintain sales tax Compliance
                    </span>
                </div>
                <div class="row home-info-detail-line">
                    <span class="description">
                        Setting up returns for easy automated filing
                    </span>
                </div>
            </div>
        </s-col>
    </s-row>
</form>
</s-container>
