export class ExemptedCustomers 
{
    companyId: string
    valid: boolean
    certificateId: string
    customerCode : string
    name : string
    emailAddress : string
    faxNumber : string
    phoneNumber : string
    line1: string
    line2: string
    city: string
    region: string
    postalCode: string
    expirationDate: string
    exemptPercentage: string
    signedDate: string
    exemptionReason: string
}

export class FetchQboCustomerResponse 
{
    isDisabled: boolean
    isSelected: boolean
    customerId: string
    customerName: string
    companyName : string
    emailAddress : string
    shippingAddress : string
    billingAddress : string
    faxNumber : string
    phoneNumber : string
}

export class SearchQboCustomerRequest 
{
    CustomerGivenName : string
    CustomerFamilyName : string
    CompanyName : string
    CustomerEmail : string
    SelectOption : string
    FilterExemptedCustomerText: string
    RequestMethodValue: string
}

export class SendQboCustomerRequest 
{
    RequestMethod : string
    SelectedCustomer : Array<QboSelectedCustomer> = []
}

export class QboSelectedCustomer
{
    Id : string
    CustomerName: string
    Email : string
    Fax : string
}

export class SendExemptionRequestError
{
    id : string
    customerName : string
    errorMessage : string
}


