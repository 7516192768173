import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { DeactivationGuarded } from 'src/app/shared/gaurds/candeactivate.guard';
import { ConnectorConfigurationService } from '../services/connectorconfiguration.service'
import { SpinnerService } from '../../shared/spinner/spinner.service';
import { AuthenticationService } from '../../shared/services/authentication.service';
import { configurationModel, SelectedItemFromList } from '../models/ConfigurationModel';
import { AvaTaxCompany } from '../models/avataxcompany';
import { salesTaxItem } from '../models/QBOUsers';
import { userCredentials } from '../models/UserCredentials';
import { OAuthSessionResponse } from '../../shared/models/OAuthToken';
import { AvaTaxAccountValidationResponse } from '../models/AvaTaxAccountValidationResponse';
import { CommonService } from '../services/common.service';
import { NgbDateAdapter, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { CustomAdapter, CustomDateParserFormatter } from '../../shared/injectables/ngDatePicker.injectable';
import { DatePipe, JsonPipe } from '@angular/common';
import { Constants } from '../../shared/models/Constants';
import { Observable, OperatorFunction } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap, tap, catchError } from 'rxjs/operators';

@Component({
  selector: 'app-connectorsettings',
  templateUrl: './connectorsettings.component.html',
  styleUrls: ['./connectorsettings.component.css'],
  providers: [
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }
  ]
})
export class ConnectorSettingsComponent implements OnInit, DeactivationGuarded {

  canDeactivate(): boolean | Promise<boolean> {
    this.oAuthToken = this.authService.fetchAIAuthTokenFromStorage();

    if (this.oAuthToken) {
      if (this.configSettingForm && this.configSettingForm.dirty) {
        return window.confirm('There are some unsaved changes. Do you really want to navigate away?')
      }
    }
    return true;
  }

  configSettingForm: FormGroup;
  configModel: configurationModel;
  avaTaxCompanies: AvaTaxCompany[];
  salesTaxItems: salesTaxItem[];
  selectedRdfItemFromList: SelectedItemFromList;
  selectedTaxOnRdfItemFromList: SelectedItemFromList;
  rdfItemSearching: Boolean = false;
  taxOnRdfItemSearching: Boolean = false;
  rdfItems: salesTaxItem[];
  taxOnRdfItems: salesTaxItem[];
  oAuthToken: OAuthSessionResponse;
  userCreds: userCredentials;
  errorMessage: any;
  showAlert: boolean = false
  alertMessage: string
  alertCssClass: string;
  scrollTop: number = 0
  accountValidationMessage: string;
  sourceParam: string;
  current: any;
  isAccessAllowed: boolean;
  isEnableAddressValidation: boolean;
  isEnableRetailDeliveryFee: boolean;
  constructor(private fb: FormBuilder,
    private connectorService: ConnectorConfigurationService,
    private commonService: CommonService,
    private spinnerService: SpinnerService,
    private authService: AuthenticationService,
    private router: Router,
    private datePipe: DatePipe,
    private route: ActivatedRoute) { }

  ngOnInit() {

    this.sourceParam = this.route.snapshot.paramMap.get("source");
    this.sourceParam = this.sourceParam == null ? "" : this.sourceParam;

    if (this.sourceParam != "") {
      $("#connectorHeader").hide();
      $("#dvLoginDetail").hide();
      $("#avaHeader").show();
      $("#avaHeader-content").show();
    }

    this.initiliazeFormModel();
    this.oAuthToken = this.authService.fetchAIAuthTokenFromStorage();

    if (this.oAuthToken) {

      this.isAccessAllowed = this.oAuthToken.isUsrAlw;
      if (!this.isAccessAllowed)
        this.router.navigate(['/home']);

      this.getConnectorSettings(this.oAuthToken.userId, this.oAuthToken.session_Id);
    }
    else {
      alert('Session is timed out. Please log in again.')

      if (this.oAuthToken == null)
        this.router.navigate(['/learnmore']);
    }
  }

  rdfItemSearch: OperatorFunction<string, readonly { id; fullyQualifiedName }[]> = (text$: Observable<any>) => {
    return text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap(term => {
        this.rdfItemSearching = true;
        return this.connectorService.searchItemsFromQBO(this.oAuthToken.userId, term, this.oAuthToken.session_Id).pipe(
          tap(() => {
            this.rdfItemSearching = false;
          }),
          catchError(() => {
            this.rdfItemSearching = false;
            return Array([]);
          }))
      }),
      catchError((e: any) => this.connectorService.handleError(e))
    );
  }

  taxOnRdfItemSearch: OperatorFunction<string, readonly { id; fullyQualifiedName }[]> = (text$: Observable<any>) => {
    return text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap(term => {
        this.taxOnRdfItemSearching = true;
        return this.connectorService.searchItemsFromQBO(this.oAuthToken.userId, term, this.oAuthToken.session_Id).pipe(
          tap(() => {
            this.taxOnRdfItemSearching = false;
          }),
          catchError(() => {
            this.taxOnRdfItemSearching = false;
            return Array([]);
          }))
      }),
      catchError((e: any) => this.connectorService.handleError(e))
    );
  }

  resultFormatBandListValue(value: any) {
    return value.fullyQualifiedName;
  }
  inputFormatBandListValue(value: any) {
    if (value.fullyQualifiedName)
      return value.fullyQualifiedName
    return value;
  }

  onCheckboxChange(e) {
    console.log('selectedRdfItemFromList:', this.selectedRdfItemFromList, this.selectedTaxOnRdfItemFromList);
    if (!this.selectedRdfItemFromList || !this.selectedTaxOnRdfItemFromList) {
      alert("To enable the Retail Delivery Fee feature, please search and select the Retail delivery fee and Tax on Retail delivery fee items from search boxes.");
      e.target.checked = false;
    }
    else if (this.selectedRdfItemFromList && this.selectedTaxOnRdfItemFromList && e.target.checked) {
      alert("This will enable Colorado Retail Delivery Fee feature, please click on Save button.");
    }
  }

  isRdfFeatureEnabled(e) {
    if (e.detail.checked) {
      alert("This will enable Colorado Retail Delivery Fee feature, please click on Save button.");
    }
    this.configSettingForm.markAsDirty();
    this.isEnableRetailDeliveryFee = e.detail.checked;
  }

  rdfItemSelected($event) {
    $event.preventDefault();
    console.log('rdf item:', $event.item);
    this.selectedRdfItemFromList = $event.item;
    $("#_SearchRdfItem").val(this.selectedRdfItemFromList.fullyQualifiedName);
    this.configSettingForm.patchValue({ RdfItem: this.selectedRdfItemFromList.id });
    this.configSettingForm.markAsDirty();
  }

  taxOnRdfItemSelected($event) {
    $event.preventDefault();
    console.log('tax on rdf item:', $event.item);
    this.selectedTaxOnRdfItemFromList = $event.item;
    $("#_SearchTaxOnRdfItem").val(this.selectedTaxOnRdfItemFromList.fullyQualifiedName);
    this.configSettingForm.patchValue({ TaxOnRdfItem: this.selectedTaxOnRdfItemFromList.id });
    this.configSettingForm.markAsDirty();
  }

  openHelpLink(url: string) {
    window.open(Constants[url], "_blank");
  }

  initiliazeFormModel() {
    this.configSettingForm = this.fb.group({
      AvaTaxAccountNumber: [{ value: '', disabled: false }, [Validators.required, Validators.pattern('[0-9]*')]],
      AvaTaxCompanyCode: [{ value: '', disabled: false }, Validators.required],
      AvaTaxUserName: [{ value: '', disabled: false }, Validators.required],
      AvaTaxStartDate: [{ value: '', disabled: false }],
      IsMakeAllCustomerTaxable: [{ value: true, disabled: false }],
      SalesTaxItem: [{ value: '', disabled: false }, Validators.required],
      RdfItem: [{ value: '', disabled: false }],
      TaxOnRdfItem: [{ value: '', disabled: false }],
      ApplyParentExemptionToSubCustomer: [{ value: false, disabled: false }],
      ApplyParentExemptionToProject: [{ value: false, disabled: false }],
    });
  }

  onSubmit() {
    if (this.configSettingForm.valid) {
      console.log("Form Submitted!");
      let connectorSetting: configurationModel
      connectorSetting = this.getModifiedConfigSettings();
      this.oAuthToken = this.authService.fetchAIAuthTokenFromStorage();
      if (this.oAuthToken) {
        this.saveConnectorSettings(connectorSetting, this.oAuthToken.session_Id)
      }
      else {
        alert('Session is timed out. Please log in again.')
        this.router.navigate(['/learnmore']);
      }
    }
  }

  getConnectorSettings(id: string, authToken: string): void {
    try {
      this.spinnerService.show()
      this.connectorService.getConnectorSettings(id, authToken).subscribe(
        (configModel: configurationModel) => this.displayConnectorSettings(configModel),
        (error: any) => {
          this.errorMessage = <any>error;
          this.spinnerService.hide();
          if (this.errorMessage != "unauthorized")
            alert(this.errorMessage);
        },
        () => { this.spinnerService.hide() }
      );
    }
    catch (error) {
      console.error(error.message)
      this.spinnerService.hide()
    }
  }

  displayConnectorSettings(configModel: configurationModel): void {
    if (configModel) {
      if (this.configSettingForm) {
        this.configSettingForm.reset();
      }

      this.configModel = configModel;
      this.avaTaxCompanies = configModel.avaTaxCompanies;
      this.salesTaxItems = configModel.salesTaxItems;
      this.rdfItems = configModel.rdfItems.filter(x => x.itemCode == this.configModel.configuration.rdfItemId);
      this.taxOnRdfItems = configModel.rdfItems.filter(x => x.itemCode == this.configModel.configuration.taxOnRdfItemId);
      this.isEnableAddressValidation = (Boolean(this.configModel?.configuration?.isEnableAddressValidation)?.valueOf() == true);
      this.isEnableRetailDeliveryFee = (Boolean(this.configModel?.configuration?.isEnableRetailDeliveryFee)?.valueOf() == true);
      if (this.configModel.configuration?.rdfItemId != null) {
        let fullyQualifiedName = this.rdfItems.find(x => x.itemCode == this.configModel.configuration.rdfItemId)?.itemName;
        if (fullyQualifiedName != null) {
          this.selectedRdfItemFromList = { id: this.configModel.configuration.rdfItemId, fullyQualifiedName: fullyQualifiedName };
          $("#_SearchRdfItem").val(fullyQualifiedName);
        }
      }

      if (this.configModel.configuration?.taxOnRdfItemId != null) {
        console.log('this.rdfItems:', this.rdfItems);
        console.log('this.taxOnRdfItemId:', this.configModel.configuration.taxOnRdfItemId);
        let fullyQualifiedName = this.taxOnRdfItems.find(x => x.itemCode == this.configModel.configuration.taxOnRdfItemId)?.itemName;
        if (fullyQualifiedName != null) {
          this.selectedTaxOnRdfItemFromList = { id: this.configModel.configuration.taxOnRdfItemId, fullyQualifiedName: fullyQualifiedName };
          $("#_SearchTaxOnRdfItem").val(fullyQualifiedName);
        }
      }

      const taxItemControl = this.configSettingForm.get('SalesTaxItem');

      if (this.salesTaxItems) {
        taxItemControl.setValidators([Validators.required]);
      }
      else {
        taxItemControl.setValidators(null);
      }

      taxItemControl.updateValueAndValidity();

      //var avaTaxStartDate = null;
      //if (this.configModel.configuration.avaTaxStartDate)
      //  avaTaxStartDate = this.datePipe.transform(this.configModel.configuration.avaTaxStartDate, Constants.DateInputFormat);

      this.configSettingForm.patchValue({
        AvaTaxAccountNumber: this.configModel.avaTaxDetails.avaTaxAccountNumber,
        AvaTaxUserName: this.configModel.avaTaxDetails.avaTaxUserName,
        AvaTaxCompanyCode: this.configModel.avaTaxDetails.avaTaxCompanyCode,
        //IsMakeAllCustomerTaxable: this.configModel.configuration.isMakeAllCustomerTaxable,
        ApplyParentExemptionToSubCustomer: this.configModel.configuration.applyParentExemptionToSubCustomer,
        ApplyParentExemptionToProject: this.configModel.configuration.applyParentExemptionToProject,
        //AvaTaxStartDate: avaTaxStartDate,
        SalesTaxItem: this.configModel.configuration.taxItemId,
        RdfItem: this.configModel.configuration.rdfItemId,
        TaxOnRdfItem: this.configModel.configuration.taxOnRdfItemId,
      })

      if (this.configModel.avaTaxDetails.avaTaxUserName == null || this.configModel.avaTaxDetails.avaTaxCompanyCode == null) {
        this.displayAlert('warning', "Please complete the configuration!");
      }
    }
  }


  validateAvaTaxAccount(): void {
    try {
      this.spinnerService.show();

      this.oAuthToken = this.authService.fetchAIAuthTokenFromStorage();

      this.userCreds = new userCredentials();
      this.userCreds.username = this.configSettingForm.get('AvaTaxUserName').value
      this.connectorService.validateAvaTaxAccount(this.userCreds, this.oAuthToken.session_Id).subscribe(
        (accountValidationResponse: AvaTaxAccountValidationResponse) => this.displayAvaTaxDetails(accountValidationResponse),
        (error: any) => {
          this.errorMessage = <any>error;
          this.spinnerService.hide();
          if (this.errorMessage != "unauthorized")
            alert(this.errorMessage);
        },
        () => { this.spinnerService.hide() }
      );
    }
    catch (error) {
      console.error(error.message)
      this.spinnerService.hide()
    }
  }

  displayAvaTaxDetails(accountValidationResponse: AvaTaxAccountValidationResponse): void {
    if (accountValidationResponse) {
      if (accountValidationResponse.status) {
        this.avaTaxCompanies = accountValidationResponse.avaTaxCompanies;
        let accountNumber = parseInt(this.configSettingForm.get('AvaTaxAccountNumber').value.trim())

        if (accountValidationResponse.accountNumbers.indexOf(accountNumber) !== -1) {
          this.avaTaxCompanies = accountValidationResponse.avaTaxCompanies;
          this.accountValidationMessage = "";
        }
        else {
          this.accountValidationMessage = "Invalid AvaTax Account number.";
        }
      }
      else {
        if (accountValidationResponse.errorMessage) {
          this.accountValidationMessage = accountValidationResponse.errorMessage;
        }
      }
    }
  }

  saveConnectorSettings(modifiedConnectorSettings: configurationModel, authToken: string): void {
    try {
      this.spinnerService.show()
      this.connectorService.saveConnectorSettings(modifiedConnectorSettings, authToken).subscribe(
        (result: any) => {
          if (result) {
            console.log('Settings Saved');
            this.displayAlert('success', "Settings saved successfully !");
          }
          else {
            console.log('Error occurred while saving the setiings');
            this.displayAlert('warning', "Error occurred while saving the setiings.");
          }

          this.spinnerService.hide();
          this.configSettingForm.markAsPristine()

          if (this.oAuthToken) {
            this.getConnectorSettings(this.oAuthToken.userId, this.oAuthToken.session_Id);
          }
        },
        (error: any) => { this.errorMessage = <any>error; this.spinnerService.hide(); this.displayAlert('alert alert-danger', this.errorMessage) }
      )
    }
    catch (error) {
      console.error(error.message)
      this.spinnerService.hide()
    }
  }

  private getModifiedConfigSettings(): configurationModel {
    this.configModel.configuration.taxItemId = this.configSettingForm.get('SalesTaxItem').value;

    if (this.configModel.configuration.rdfItemId == null && this.selectedRdfItemFromList?.id != null) {
      this.configModel.configuration.rdfItemId = this.selectedRdfItemFromList.id;
      this.configModel.configuration.rdfItemCode = this.selectedRdfItemFromList.fullyQualifiedName;
    }

    if (this.configModel.configuration.taxOnRdfItemId == null && this.selectedTaxOnRdfItemFromList?.id != null) {
      this.configModel.configuration.taxOnRdfItemId = this.selectedTaxOnRdfItemFromList.id;
      this.configModel.configuration.taxOnRdfItemCode = this.selectedTaxOnRdfItemFromList.fullyQualifiedName;
    }
    this.configModel.configuration.isEnableRetailDeliveryFee = (this.isEnableRetailDeliveryFee).toString();
    return this.configModel
  }

  clearStartDate() {
    this.configSettingForm.patchValue({
      AvaTaxStartDate: null,
    })
    this.configSettingForm.controls['AvaTaxStartDate'].markAsTouched();
    this.configSettingForm.markAsDirty();
  }

  addressValidationToggle(e) {
    this.configSettingForm.markAsDirty();
    this.isEnableAddressValidation = e.detail.checked;
  }

  retailDeliveryFeeToggle(e) {
    console.log('selectedRdfItemFromList:', this.selectedRdfItemFromList, this.selectedTaxOnRdfItemFromList);
    if (!this.selectedRdfItemFromList || !this.selectedTaxOnRdfItemFromList) {
      this.isEnableRetailDeliveryFee = false;
      let toggle = document.getElementById(e.detail.id);
      toggle.removeAttribute("checked");
      alert("To enable the Retail Delivery Fee feature, please search and select the Retail delivery fee and Tax on Retail delivery fee items from search boxes.");
     
    }
    else {
      if (this.selectedRdfItemFromList && this.selectedTaxOnRdfItemFromList && e.detail.checked) {
        alert("This will enable Colorado Retail Delivery Fee feature, please click on Save button.");
      }
      this.configSettingForm.markAsDirty();
      this.isEnableRetailDeliveryFee = e.detail.checked;
    }
  }

  dismissAlert() {
    this.showAlert = false
  }

  displayAlert(cssClass: string, alertMessage: string) {
    this.showAlert = true
    this.alertMessage = alertMessage
    this.alertCssClass = cssClass
    var scrollToTop = window.setInterval(function () {
      var pos = window.pageYOffset;
      if (pos > 0) {
        window.scrollTo(0, pos - 20); // how far to scroll on each step
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 16); // how fast to scroll (this equals roughly 60 fps)
  }
}
