import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router"
import { AuthenticationService } from '../shared/services/authentication.service';
import { OAuthConnectResponse } from '../shared/models/OAuthToken';
import { SpinnerService } from '../shared/spinner/spinner.service';

@Component({
  selector: 'app-adminsign',
  templateUrl: './adminsign.component.html',
  styleUrls: ['./adminsign.component.css']
})
export class AdminSigninComponent implements OnInit {
  private state: string;
  private scope: string;
  private code: string;
  private error: string;
  private error_description: string;

  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    private spinnerService: SpinnerService) { }

  ngOnInit() {
    this.code = this.route.snapshot.queryParamMap.get("code"),
      this.state = this.route.snapshot.queryParamMap.get("state"),
      this.scope = this.route.snapshot.queryParamMap.get("scope"),
      this.error = this.route.snapshot.queryParamMap.get("error"),
      this.error_description = this.route.snapshot.queryParamMap.get("error_description")

    // console.log('this.code:',this.code);
    // console.log('this.state:',this.state);
    // console.log('this.scope:',this.scope);
    // console.log('this.error:',this.error);
    // console.log('this.error_description:',this.error_description);

    if (this.code != null && this.state != null) {
      let tokenReq: OAuthConnectResponse = {
        Code: this.code,
        State: this.state,
        Scope: this.scope,
        RealmId: "",
        Error: "",
        ErrorDescription: "",
        avaTaxUserId: "",
        isAlreadyConnected: null
      }

      this.spinnerService.show();
      this.authService.completeOktaAuthentication(tokenReq).then((resp) => {
        this.spinnerService.hide();
      });
    }
    else if(this.error != null || this.error_description != null){
      console.log('Error -->', this.error, ' | Description :', this.error_description);
    }
    else {
      this.spinnerService.show();
      this.authService.getOktaAuthUrl().then((url) => {
        window.location.href = url;
        this.spinnerService.hide();
      });
    }
  }



}