import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import { IHttpService } from '../../shared/services/IHttpService';
import { AppConfig } from '../../app.config';
import { CommonService } from '../services/common.service';

@Injectable({
    providedIn: 'root'
})
export class ItemManagementService implements IHttpService{
    private itemManagementBaseUrl =  `${AppConfig.settings.avatax.api_base_url}/api/ItemManagement`;

    constructor(private http: HttpClient, private commonService: CommonService) { }
 
    compareItems(id:string, authToken:string):Observable<any> {
      const url = `${this.itemManagementBaseUrl}/CompareItems/${id}`;
      const headers = new HttpHeaders().set('Authorization', `Bearer ${authToken}`)
      return this.http.post<any>(url, "", {headers: headers}).pipe(catchError((e:any)=>this.handleError(e)));
    }

    fetchComparedItems(realmId:string, authToken:string):Observable<any> {
      const url = `${this.itemManagementBaseUrl}/FetchComparedItems/${realmId}`;
      const headers = new HttpHeaders().set('Authorization', `Bearer ${authToken}`)
      return this.http.post<any>(url, "" ,{headers: headers}).pipe(catchError((e:any)=>this.handleError(e)));
    }

    resendQboItems(id:string, qboItems:string[], authToken:string):Observable<any>{
      const url = `${this.itemManagementBaseUrl}/ReSendQboItems/${id}`;
      const headers = new HttpHeaders().set('Authorization', `Bearer ${authToken}`)
      return this.http.post<any>(url,qboItems,{headers: headers}).pipe(catchError((e:any)=>this.handleError(e)));
    }

    handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('An error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          // The response body may contain clues as to what went wrong,
          console.error(
            `Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);

          this.commonService.unauthorizationRedirect(error);
          return throwError('unauthorized');
        }
        // return an observable with a user-facing error message
        return throwError(
          'Something went wrong, please try again later.');
      };
}