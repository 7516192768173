import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NexusSetupService } from '../services/nexussetup.service'
import { SpinnerService } from '../../shared/spinner/spinner.service';
import { AuthenticationService } from '../../shared/services/authentication.service';
import { NexusSetup, AdditionalNexuses } from '../models/NexusSetup';
import { OAuthSessionResponse } from '../../shared/models/OAuthToken';

@Component({
  selector: 'app-nexussetup',
  templateUrl: './nexussetup.component.html',
  styleUrls: ['./nexussetup.component.css']
})
export class NexusSetupComponent implements OnInit {
  nexusSetupForm:FormGroup;
  nexusSetupData:NexusSetup;
  nexusList:AdditionalNexuses[];
  errorMessage : any;
  oAuthToken : OAuthSessionResponse;
  enabledNexus:number;
  totalNexus:number;
  enabledNexusList:AdditionalNexuses[];
  nonEnabledNexusList:AdditionalNexuses[];
  showAlert : boolean = false
  alertMessage: string
  alertCssClass: string;
  alertStatus: string = "error";

  constructor(private fb:FormBuilder,
              private nexusSetupService : NexusSetupService,
              private spinnerService : SpinnerService,
              private authService : AuthenticationService,
              private router : Router){ }

  ngOnInit(){
    this.initiliazeFormModel();
    this.oAuthToken = this.authService.fetchAIAuthTokenFromStorage();
    if(this.oAuthToken){
      this.getNexusData(this.oAuthToken.userId, this.oAuthToken.session_Id);
    }
    else{
      alert('Session is timed out. Please log in again.')
      this.router.navigate(['/learnmore']);
    }
  }

  initiliazeFormModel(){
    this.nexusSetupForm = this.fb.group({
      QboCompanyName : [{value : ''}]
    });
  }

  getNexusData(id:string, authToken:string):void{
    try{
      this.spinnerService.show()
      this.nexusSetupService.fetchNexus(id,authToken).subscribe(
        (nexusSetupData:NexusSetup) => this.displayFetchedNexus(nexusSetupData),
        (error:any) => {
          this.errorMessage = <any>error;
          this.spinnerService.hide();
          if(this.errorMessage != "unauthorized")
            alert(this.errorMessage);
        },
        () => {this.spinnerService.hide()}
      );
    }
    catch(error){
      console.error(error.message)
      this.spinnerService.hide()
    }
  }

  displayFetchedNexus(nexusSetupData:NexusSetup){
    if(nexusSetupData){
      if(this.nexusSetupForm){
        this.nexusSetupForm.reset();
      }

      this.nexusSetupData = nexusSetupData;
      this.nexusList = nexusSetupData.nexusList;
      this.enabledNexus = this.nexusList.filter(x=>x.enabled).length;
      this.totalNexus = this.nexusList.length;
      this.enabledNexusList = this.nexusList.filter(x=>x.enabled);
      this.nonEnabledNexusList = this.nexusList.filter(x=>x.enabled == false);

    }
  }

  anyNexusSelected(){
    return (this.nonEnabledNexusList.filter(x=>x.checked).length < 1)
  }

  onSubmit(){
    //let selectedNexusToEnable : AdditionalNexuses[];
    let selectedNexusSetupData:NexusSetup;
    selectedNexusSetupData = Object.assign({}, this.nexusSetupData); //this.nexusSetupData;

    selectedNexusSetupData.nexusList = this.nexusList.filter(x=>x.checked);

    this.oAuthToken = this.authService.fetchAIAuthTokenFromStorage();
    if(this.oAuthToken){    
     this.enableSelectedNexus(this.oAuthToken.userId, selectedNexusSetupData, this.oAuthToken.session_Id);
    }
    else{
      alert('Session is timed out. Please log in again.')
      this.router.navigate(['/learnmore']);
    } 
  }

  enableSelectedNexus(id:string, selectedNexusSetupData : NexusSetup, authToken:string):void{
    try{
      this.spinnerService.show()
      this.nexusSetupService.enableSelectedNexus(id,selectedNexusSetupData,authToken).subscribe(
        (result : any) => {
          if(result && result.value){
            console.log('Nexus enabled'); 
            this.displayAlert("success","Selected nexus enabled successfully!");

            // if(result.value.length > 0){
            //   this.nexusSetupData.nexusList[0].enabled = true;
            //   this.displayFetchedNexus(this.nexusSetupData);
            // }
          }
          else{
            if(result && result.error && result.error.message)
            {
              console.log(result.error.message); 
              this.displayAlert("warning",result.error.message);
            }
            else{
              console.log('Error occurred while enabling the nexus'); 
              this.displayAlert("warning","Error occurred while enabling the nexus.");
            }
          }
          
          this.spinnerService.hide(); 
          //this.nexusSetupForm.markAsPristine() 
        },
        (error: any) => {this.errorMessage = <any>error; 
          this.spinnerService.hide();
          this.displayAlert("error",this.errorMessage);
        }
      )
    }
    catch (error) {
      console.error(error.message)
      this.spinnerService.hide()
    }   
  }

  dismissAlert(){
    this.showAlert = false
  }

displayAlert(alertStatus:string,alertMessage:string){
  this.showAlert = true;
  this.alertMessage = alertMessage;	
  this.alertStatus = alertStatus;
}
  
}