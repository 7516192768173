import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';

@Injectable({
  providedIn :'root'
})
export class AuthGuard implements CanActivate {

  constructor(private authService : AuthenticationService, private router : Router){

  }
  
  canActivate(): Promise<boolean> {
    return this.authService.isLoggedIn().then(isLoggedIn=>{
      if(!isLoggedIn){
        this.router.navigate(['/learnmore']);
         return false;
      }
      return true;
    });
  }
}
