<s-container>
    <form novalidate id="frmItemManagement">

        <a [routerLink]="[ '/home']" *ngIf="this.sourceParam==''">
            &#8592; back
        </a>

        <div class="row">
            <h2 class="page-title header-title"> Manage Product Catalog </h2>
            <h4 class="page-title header-sub-title"> Synchronize QuickBooks Products with Avalara Item Catalog </h4>
        </div>
        <br>
        <div style="padding:0px;">
            <s-row>
                <s-col span="3" class="s-col-padding-bottom-08">
                    <button tabindex="-1" class="primary" (click)="SendToSync()" AutomationAttribute="ItemManagement_Button_SendToSync">
                      <span class="button-text">
                        Sync QuickBooks Items
                      </span>
                    </button>
                    <a class="text-underline refresh-data" *ngIf="isItemManagementList" (click)="searchItems()">
                        <s-icon name="refresh"></s-icon> &nbsp;Refresh data
                    </a>
                </s-col>
                <s-col span="4" class="filter-item s-col-padding-bottom-08">
                    <label *ngIf="isItemListLoaded">
                        <div class="label-search-title">Search: </div>
                        
                        <input type="text" id="filterItemText" name="filterItemText" [(ngModel)]="filterItemText" 
                            class="search-text">

                        <button class="secondary search-button" (click)="filterItems('filter')" 
                            AutomationAttribute="ItemManagement_Button_filterItems">
                            <s-icon name="search"></s-icon>
                        </button>
                    </label>
                </s-col>
                <s-col span="5" class="text-right s-col-padding-bottom-08">

                    Filter items by sync status - &nbsp;&nbsp;
                    <button class="secondary" (click)="filterItems('New')"
                    [ngClass]="{'statusBtn': statusClass=='New'}"  AutomationAttribute="ItemManagement_Button_filterItems_New">
                        <span class="button-text">
                            New
                        </span>
                    </button>
                    <button class="secondary margin-left-0" (click)="filterItems('Updated')" 
                    [ngClass]="{'statusBtn': statusClass=='Updated'}"  AutomationAttribute="ItemManagement_Button_filterItems_Updated">
                        <span class="button-text">
                            Updated
                        </span>
                    </button>
                    <button class="secondary margin-left-0" (click)="filterItems('Fail')"
                    [ngClass]="{'statusBtn': statusClass=='Fail'}"   AutomationAttribute="ItemManagement_Button_filterItems_Fail">
                        <span class="button-text">
                            Fail
                        </span>
                    </button>
                </s-col>
            </s-row>
            <s-row>
                <s-col>
                    <div class="row table-outer">
                        <table datatable #dt1 class="card-table" [dtOptions]="dtOptions[0]" [dtTrigger]="dtTrigger">
                            <thead>
                                <tr>
                                    <th width="10%">
                                        <label>
                                            <input type="checkbox" [checked]="isSelectAllChecked" (change)="selectAll($event)" />
                                            <span class="checkbox-select-text"> SELECT</span>
                                        </label>
                                    </th>
                                    <th width="15%">Item Code</th>
                                    <th width="30%">Item Description</th>
                                    <th class="text-align-center" width="10%">Status</th>
                                    <th width="35%">Error Message</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="row-collapse" *ngFor="let data of itemManagementList">
                                    <td class="checkbox-chkall">
                                        <fieldset role="group" aria-labelledby="label4" *ngIf="data.status=='New'">
                                            <input type="checkbox" id="chkAll" name="chkAll" [checked]="data.isSelected"
                                            (change)="selectItemValue($event.target);"
                                            value="{{data.id}}" />
                                        </fieldset>
                                    </td>
                                    <td class="vertical-align-top">{{data.itemCode}}</td>
                                    <td class="vertical-align-top">{{data.itemDescription}}</td>
                                    <td class="status-box">
                                        <span class="status-{{data.status}}">{{data.status}}</span>
                                    </td>
                                    <td class="vertical-align-top">{{data.description}} </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </s-col>
            </s-row>
        </div>
    </form>
</s-container>
