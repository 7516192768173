import { Component, OnInit, Injectable, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute, NavigationStart, NavigationEnd, RouterEvent } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { DeactivationGuarded } from 'src/app/shared/gaurds/candeactivate.guard';
import { ExemptionService } from '../services/exemption.service'
import { ExemptedCustomers, SearchQboCustomerRequest, FetchQboCustomerResponse, QboSelectedCustomer, SendQboCustomerRequest, SendExemptionRequestError } from '../models/exemptionModel'
import { SpinnerService } from '../../shared/spinner/spinner.service';
import { AuthenticationService } from '../../shared/services/authentication.service';
import { CommonService } from '../services/common.service';
import { userCredentials } from '../models/UserCredentials';
import { OAuthSessionResponse } from '../../shared/models/OAuthToken';
import { DataTableDirective } from "angular-datatables";
import { HttpParams } from '@angular/common/http';
import { DialogService } from '../services/dialog.service';

@Component({
  selector: 'app-exemption',
  templateUrl: './exemption.component.html',
  styleUrls: ['./exemption.component.css']
})
export class ExemptionComponent implements OnInit, DeactivationGuarded {

  searchForm: FormGroup;
  oAuthToken: OAuthSessionResponse;
  userCreds: userCredentials;
  errorMessage: any;
  showAlert: boolean = false
  alertMessage: string
  alertCssClass: string;
  scrollTop: number = 0
  showValidateAvaTaxAccountButton: boolean;
  accountValidationMessage: string;

  selectedIds: Array<string> = [];
  isSelectAllChecked: boolean = false;
  isExemptedCustomerList: boolean = false;
  allExemptedCustomerList: Array<ExemptedCustomers> = [];
  exemptedCustomerList: Array<ExemptedCustomers> = [];
  qboCustomerList: Array<FetchQboCustomerResponse> = [];
  qboSelectedCustomer: Array<QboSelectedCustomer> = [];
  sendExemptionRequestError: Array<SendExemptionRequestError> = [];
  downloadListCount: number = 0;
  selectedRequestMethod: string;
  filterExemptedCustomerText: string = '';
  requestMethodValue: string = ''; 
  isExemptedCustomerListLoaded: boolean = false;


  statusClass: string;
  iterationCnt: number = 0;

  isItemListLoaded: boolean = false;
  filterItemText: string = '';
  filterIterationCnt: number = 0;

  @ViewChildren(DataTableDirective)
  dtElements: QueryList<DataTableDirective>;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  isPageRefreshed: boolean = false;
  mySubscription: any;
  sourceParam: string;

  constructor(private fb: FormBuilder,
    private exemptionService: ExemptionService,
    private commonService: CommonService,
    private dialogService: DialogService,
    private spinnerService: SpinnerService,
    private authService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute) {

    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this.mySubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // Trick the Router into believing it's last link wasn't previously loaded
        this.router.navigated = false;
      }
    });
  }

  canDeactivate(): boolean | Promise<boolean> {
    this.oAuthToken = this.authService.fetchAIAuthTokenFromStorage();
    if (this.oAuthToken) {
      if (this.searchForm && this.searchForm.dirty) {
        return window.confirm('There are some unsaved changes. Do you really want to navigate away?')
      }
    }
    return true;
  }

  async ngOnInit() {

    this.sourceParam = this.route.snapshot.paramMap.get("source");
    this.sourceParam = this.sourceParam == null ? "" : this.sourceParam;
  
    if (this.sourceParam != "") {
      $("#connectorHeader").hide();
      $("#dvLoginDetail").hide();
      $("#avaHeader").show();
      $("#avaHeader-content").show();
    }
  
    this.initiliazeFormModel();
  
    this.searchForm.patchValue({
      SelectOption: "All"
    });
    
    this.initializeDatatables();

    this.oAuthToken = this.authService.fetchAIAuthTokenFromStorage();
    if (this.oAuthToken) {
      this.spinnerService.show();
      // Refresh JWT Token - Extend 25mins more with new JWT Token from API
      await this.commonService.extendJwtDuration();

      if (!this.router.navigated) {
        this.searchExemptedCustomers();
      }

      this.requestMethodValue = "Email";
      this.spinnerService.hide();
    } else {
      alert('Session is timed out. Please log in again.');
      this.router.navigate(['/learnmore']);
    }
  }
  

  initiliazeFormModel() {
    this.searchForm = this.fb.group({
      CustomerGivenName: [{ value: '', disabled: false }],
      CustomerFamilyName: [{ value: '', disabled: false }],
      CompanyName: [{ value: '', disabled: false }],
      CustomerEmail: [{ value: '', disabled: false }],
      SelectOption: [{ value: '', disabled: false }],
      FilterExemptedCustomerText: [{ value: '', disabled: false }],
      RequestMethodValue: [{ value: 'Email', disabled: false }]
    }, {
      validator: []
    });
  }

  selectTab(tabIndex){
    var exemptedCustomer = document.getElementById("exempted-customer-panel");
    var exemptionCertificate = document.getElementById("exemption-request-panel");

    exemptedCustomer.classList.add('hidden');
    exemptionCertificate.classList.add('hidden');
    if(tabIndex == 1){
      this.searchExemptedCustomers();
      exemptedCustomer.classList.remove('hidden');
    } else {
      exemptionCertificate.classList.remove('hidden');
    }
  }
 
  initializeDatatables() {
    this.dtOptions[0] = {
      retrieve: true,
      paging: true,
      lengthChange: true,
      searching: false,
      pageLength: 10,
      columnDefs: [{ targets: 3, orderable: false }],
      pagingType: 'simple_numbers',
      order: [[1, 'desc']],
      serverSide: false,
      processing: false
    }
  }

  objectToHttpParams(obj: any) {
    return Object.entries(obj || {}).reduce((params, [key, value]) => {
      return params.set(
        key,
        value === 'object' ? JSON.stringify(value) : String(value)
      );
    }, new HttpParams());
  }

  filterExemptedCustomer(status){
    var current = this;
    this.filterExemptedCustomerText = this.searchForm.get('FilterExemptedCustomerText').value;
    console.log('filterExemptedCustomerText', this.filterExemptedCustomerText);
    current.spinnerService.show();
    this.iterationCnt = this.iterationCnt + 1;
    if(this.iterationCnt == 1){
      this.exemptedCustomerList = new Array<ExemptedCustomers>();
      setTimeout(function(){current.filterExemptedCustomer(status); }, 500);
    }
    else {
      this.iterationCnt = 0;
      if(this.allExemptedCustomerList.length > 0) {
      if(status == "filter"){
        if(current.filterExemptedCustomerText === ''){
          this.exemptedCustomerList = current.allExemptedCustomerList.filter(o => o.customerCode == "");
          this.rerender();
        }
        else {
          this.exemptedCustomerList = this.allExemptedCustomerList.filter(o => o.customerCode.includes(current.filterExemptedCustomerText.trim()) 
          || (o.name == null ? "": o.name).includes(current.filterExemptedCustomerText.trim())
          || (o.emailAddress == null ? "": o.emailAddress).includes(current.filterExemptedCustomerText.trim())
          || (o.exemptionReason == null ? "": o.exemptionReason).includes(current.filterExemptedCustomerText.trim())
          || (o.expirationDate == null ? "": o.expirationDate).includes(current.filterExemptedCustomerText.trim())
          || (o.region == null ? "": o.region).includes(current.filterExemptedCustomerText.trim())
          );
          this.rerender();
        }
      }
      else
      {
        this.filterExemptedCustomerText = "";
        this.exemptedCustomerList = this.allExemptedCustomerList.filter(o => o.customerCode == status);
        this.rerender();
      }
    }
      current.spinnerService.hide();
    }
    this.statusClass = status;
  }

  searchQboCustomers(){
    this.oAuthToken = this.authService.fetchAIAuthTokenFromStorage();
    if (this.oAuthToken) {

      var searchQboCustomerRequest = new SearchQboCustomerRequest();
      searchQboCustomerRequest.CustomerGivenName = this.searchForm.get('CustomerGivenName').value;
      searchQboCustomerRequest.CustomerFamilyName = this.searchForm.get('CustomerFamilyName').value;
      searchQboCustomerRequest.CustomerEmail = this.searchForm.get('CustomerEmail').value;
      searchQboCustomerRequest.CompanyName = this.searchForm.get('CompanyName').value;

      if(this.searchForm.get('SelectOption').value == "")
      this.searchForm.get('SelectOption').value;
      searchQboCustomerRequest.SelectOption = this.searchForm.get('SelectOption').value;
      console.log('Search qbo customers:', searchQboCustomerRequest);
      this.fetchQboCustomersRequest(this.oAuthToken.realmId, searchQboCustomerRequest, this.oAuthToken.session_Id);
    }
    else {
      alert('Session is timed out. Please log in again.')
      this.router.navigate(['/learnmore']);
    }
  }

  fetchQboCustomersRequest(realmId: string, searchQboCustomerRequest: SearchQboCustomerRequest, authToken: string): void {
    try {
      var current = this;
      this.spinnerService.show();
      this.exemptionService.fetchQboCustomersRequest(realmId, searchQboCustomerRequest, authToken).subscribe(
        (response: any) => {

          if (response.isSentToSearch == true) {
            console.log("fetch Qbo Customers Request sent to lambda for comparison")
            this.spinnerService.show();
            this.IterativeQboCustomerFetch();
          }
          else {
            console.log("fetch Qbo Customers Request sqs is not created.")
          }
        },
        (error: any) => {
          this.errorMessage = <any>error;
          this.spinnerService.hide();
          if(this.errorMessage != "unauthorized")
            alert(this.errorMessage);
        },
        () => { }
      );
    }
    catch (error) {
      console.error(error.message)
      this.spinnerService.hide()
    }
  }

  IterativeQboCustomerFetch() {
    this.oAuthToken = this.authService.fetchAIAuthTokenFromStorage();
    if (this.oAuthToken) {
      this.fetchQboCustomersResponse(this.oAuthToken.realmId, this.oAuthToken.session_Id, 0);
    }
  }

  fetchQboCustomersResponse(realmId: string, authToken: string, iterationCount: number): void {
    try {
      var current = this;
      this.spinnerService.show();
      this.exemptionService.fetchQboCustomersResponse(realmId, authToken).subscribe(
        (model: any) => {
          console.log("fetch Qbo Customers count: ", model.fetchQboCustomerData != null ? model.fetchQboCustomerData.length : model);

          if (model.fetchQboCustomerData != null && model.fetchQboCustomerData != undefined) {
           
            current.qboCustomerList = model.fetchQboCustomerData;
            current.rerender();

            if(current.searchForm.get('SelectOption').value == "All")
              current.disableCheckbox('Email');

            current.spinnerService.hide();
          }
          else {
            if (iterationCount <= 180) { 
              setTimeout(function () {
                console.log("iterationCount:" + iterationCount);
                current.fetchQboCustomersResponse(realmId, authToken, iterationCount++);
              }, 3000);
            }
          }
        },
        (error: any) => {
          this.errorMessage = <any>error;
          this.spinnerService.hide();
          if(this.errorMessage != "unauthorized")
            alert(this.errorMessage);
        },
        () => { }
      );
    }
    catch (error) {
      console.error(error.message)
      this.spinnerService.hide()
    }
  }


  searchExemptedCustomers() {
    this.oAuthToken = this.authService.fetchAIAuthTokenFromStorage();
    if (this.oAuthToken) {
        this.exemptedCustomerSearchRequest(this.oAuthToken.userId, this.oAuthToken.session_Id);
    }
    else {
      alert('Session is timed out. Please log in again.')
      this.router.navigate(['/learnmore']);
    }
  }

  exemptedCustomerSearchRequest(id: string, authToken: string): void {
    try {
      var current = this;
      current.iterationCnt = 0;
      current.statusClass = "";
      current.isExemptedCustomerListLoaded = false;
      current.spinnerService.show();

      this.exemptionService.exemptedCustomerSearchRequest(id, authToken).subscribe(
        (response: any) => {

          if (response.isSentToSearch == true) {
            console.log("Exempted customer request sent to lambda for comparison")
            this.spinnerService.show();
            this.IterativeFetch();
          }
          else {
            console.log("Exempted customer request sqs is not created.")
          }
        },
        (error: any) => {
          this.errorMessage = <any>error;
          this.spinnerService.hide();
          if(this.errorMessage != "unauthorized")
            alert(this.errorMessage);
        },
        () => { }
      );
    }
    catch (error) {
      console.error(error.message)
      this.spinnerService.hide()
    }
  }

  IterativeFetch() {
    this.oAuthToken = this.authService.fetchAIAuthTokenFromStorage();
    if (this.oAuthToken) {
      this.fetchExemptedCustomers(this.oAuthToken.realmId, this.oAuthToken.session_Id, 0);
    }
  }

  fetchExemptedCustomers(realmId: string, authToken: string, iterationCount: number): void {
    try {
      var current = this;
      this.spinnerService.show();
      this.exemptionService.fetchExemptedCustomers(realmId, authToken).subscribe(
        (model: any) => {
          console.log("Fetch Exempted customer count: ", model.exemptedCustomerData != null ? model.exemptedCustomerData.length : model);

          if (model.exemptedCustomerData != null && model.exemptedCustomerData != undefined) {
           
                current.allExemptedCustomerList = model.exemptedCustomerData;
                current.exemptedCustomerList = current.allExemptedCustomerList;
                
                console.log("exemptedCustomerList count: ", current.exemptedCustomerList.length);
                current.rerender();

                if (current.exemptedCustomerList.length > 0){
                    current.isExemptedCustomerList = true;
                    current.isExemptedCustomerListLoaded = true;
                }
             
              current.spinnerService.hide();
          }
          else {
            if (iterationCount <= 180) { //15mins :- 180 calls every 5secs.
              setTimeout(function () {
                console.log("iterationCount:" + iterationCount);
                current.fetchExemptedCustomers(realmId, authToken, iterationCount++);
              }, 3000);
            }
          }
        },
        (error: any) => {
          this.errorMessage = <any>error;
          this.spinnerService.hide();
          if(this.errorMessage != "unauthorized")
            alert(this.errorMessage);
        },
        () => { }
      );
    }
    catch (error) {
      console.error(error.message)
      this.spinnerService.hide()
    }
  }


  rerender(): void {
    if (this.dtElements) {
      this.dtElements.forEach((dtElement: DataTableDirective) => {
        dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.clear();
          dtInstance.destroy();
          this.dtTrigger.next();
        });
      });
    }
  }

  downloadCertificate(customerName, certificateId) {
    this.oAuthToken = this.authService.fetchAIAuthTokenFromStorage();
    if (this.oAuthToken) {
        this.downloadCertificateRequest(this.oAuthToken.userId, customerName, certificateId, this.oAuthToken.session_Id);
    }
    else {
      alert('Session is timed out. Please log in again.')
      this.router.navigate(['/learnmore']);
    }
  }

  downloadCertificateRequest(realmId: string, customerName: string, certificateId: string, authToken: string): void {
    try {
      var current = this;
      current.spinnerService.show();
      current.exemptionService.downloadCertificate(realmId, certificateId, authToken).subscribe(
        (response: any) => {
          current.spinnerService.hide();
          console.log("download link response : ", response)
          
          var linkSource = 'data:application/octet-stream;base64,'+ response;
          const downloadLink = document.createElement('a');
          const fileName = customerName + ' exemption certificate.pdf';
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        },
        (error: any) => {
          current.errorMessage = <any>error;
          current.spinnerService.hide();
          if(current.errorMessage != "unauthorized")
            alert(current.errorMessage);
        },
        () => { }
      );
    }
    catch (error) {
      console.error("comp:"+ error.message)
      this.spinnerService.hide()
    }
  }


  selectAll(event) {
    if (event.target.checked) {
      this.isSelectAllChecked = true;
      var cnt = 0;
      for (var row of this.qboCustomerList) {
        if(row.isDisabled != true){
          row.isSelected = true;
          cnt +=1;
        }
      }
      alert("All (" + cnt + ") customers will be selected.")
    }
    else {
        for (var row of this.qboCustomerList) {
            row.isSelected = false;
        }
    }
  }

  selectCustomerValue(target) {
    let isSelected = target.checked;
    for (var row of this.qboCustomerList) {
      if (row.customerId == target.value) {
        row.isSelected = isSelected;
        break;
      }
    }
    if (!target.checked) {
      this.isSelectAllChecked = false;
    }
  }

  disableCheckbox(value){
    console.log('value', value);
    this.isSelectAllChecked = false;
    for (var row of this.qboCustomerList) {
      row.isSelected = false;
      if(value == "Email"){
        row.isDisabled = (row.emailAddress != null) ? false: true;
      } else if(value == "Fax"){
        row.isDisabled = (row.faxNumber != null) ? false: true;
      } else {
        row.isDisabled = false;
      }
    }
  }

  sendExemptionCertificateRequest() {

    this.downloadListCount = 0;
    this.selectedRequestMethod = "";
    this.sendExemptionRequestError = [];
    this.qboSelectedCustomer = [];
    this.oAuthToken = this.authService.fetchAIAuthTokenFromStorage();
    if (this.oAuthToken) {

      let isSelected = false;
      for (var row of this.qboCustomerList) {
        if (row.isSelected && row.isDisabled != true) {
          isSelected = true;
          break;
        }
      }

      if (isSelected) {
        for (var row of this.qboCustomerList) {
          if (row.isSelected && row.isDisabled != true) {
            if (row.customerId != "") {
              var req: QboSelectedCustomer = {
                Id: row.customerId,
                CustomerName: row.customerName,
                Email: row.emailAddress,
                Fax: row.faxNumber
              }
              this.qboSelectedCustomer.push(req);
            }
          }
        }
        if (this.qboSelectedCustomer.length > 0) {
          var current = this;
          current.spinnerService.show();
          current.oAuthToken = current.authService.fetchAIAuthTokenFromStorage();
          if (current.oAuthToken) {
            current.selectedRequestMethod = this.searchForm.get('RequestMethodValue').value;
            current.exemptionService.sendExemptionRequest(current.oAuthToken.userId, current.selectedRequestMethod, current.qboSelectedCustomer, this.oAuthToken.session_Id)
              .subscribe(
                (model: any) => {

                  if(model.downloadLinkCustomer != null && model.downloadLinkCustomer.length > 0){
                    current.downloadListCount = model.downloadLinkCustomer.length;
                    current.convertJsonToCSV(current, model.downloadLinkCustomer);
                  }
                 
                  if(model.customerErrors != null && model.customerErrors.length > 0){
                    current.sendExemptionRequestError = model.customerErrors;
                    this.dialogService.showDialogBox('exemptionRequestErrorDialog');
                  }
                  else if(current.selectedRequestMethod != "Download"){
                    alert("Exemption certificate requests have been sent to "+ this.qboSelectedCustomer.length +" customers.");
                  }
                 
                  console.log("SendExemptionRequest return : ", model);
                  
                  current.spinnerService.hide();
                },
                (error: any) => {
                  this.errorMessage = <any>error;
                  this.spinnerService.hide();
                  if(this.errorMessage != "unauthorized")
                    alert(this.errorMessage);
                },
                () => { }
              );
          }
        }
      }
      else {
        alert("Please select the QuickBooks customers to send the exemption request.");
      }
    }
    else {
      alert('Session is timed out. Please log in again.')
      this.router.navigate(['/learnmore']);
    }

    return false;
  }

  downloadRequestList(){
    this.exemptionService.downloadCertRequestErrorsFile(this.sendExemptionRequestError, 'Exemption request errors for QuickBooks customers');
  }

  convertJsonToCSV(current, json){
    current.exemptionService.downloadFile(json, 'QuickBooks customers with certificate request link');
  }

  ngAfterViewInit() {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
    if (this.mySubscription) {
      this.mySubscription.unsubscribe();
    }
  }

}
