<s-container>
    
    <a [routerLink]="[ '/home']" style="margin:0px;">
        &#8592; back
    </a>

    <form [formGroup]="nexusSetupForm" (ngSubmit)="onSubmit()">
        <div style="margin-left:18px;margin-right:18px;">
        
        <!-- <div id="alert_panel" *ngIf="showAlert" class="{{alertCssClass}}" role="alert">
            <div class="panel-heading">{{alertMessage}}</div>
            <button type="button" (click)="dismissAlert()" class="close" aria-label="Close"
                style="margin-top: -20px; float:right">
                <span aria-hidden="true">&times;</span>
            </button>
        </div> -->

        <s-row>
            <h2> Where you collect tax! </h2>
        </s-row>

        <div *ngIf="showAlert">
            <s-alert status="{{alertStatus}}" id="alert_panel"  style="margin: 0px -16px;">
                <div>
                    {{alertMessage}}
                </div>
            </s-alert>
            <br />
        </div>
        <s-row>
            Where should AvaTax collect tax for you? Let's take a
            look at it together!
        </s-row>

        <div *ngIf="totalNexus > 0 && totalNexus == enabledNexus">
            <s-row>
                Good news! You have already established at least one location where you collect and remit tax.<br />
                After compairing your QuickBooks Online data for {{QboCompanyName}}, we do not
                have any additional recommended tax regions.<br />
                <br />
                Note: You may add regions other than what is already set at anytime using the AvaTax.
                <!-- <a href="#" rel="noopener" target="_blank">AvaTax</a>. -->
                <br />
            </s-row>
            <s-row>
                <table class="card-table">
                    <thead>
                        <tr>
                            <td></td>
                            <td>Where</td>
                            <td>Why</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let nexus of enabledNexusList">
                            <td>
                                <img src="/assets/images/AvaTick.png" alt="Avalara AvaTax" style="height:16px;" />
                            </td>
                            <td>
                                {{nexus | nexusWhereContent}}
                            </td>
                            <td>
                                {{nexus | nexusWhyContent}}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </s-row>
        </div>

        <div *ngIf="totalNexus > enabledNexus">
            <s-row>
                <s-col style="padding-left:0px;padding-bottom:0px;">
                <br />
                At least one region must be selected in order to start calculating tax using
                AvaTax.<br />
                We can help you select additional regions based on your data in QuickBooks
                Online for {{QboCompanyName}} company.<br />
                <span style="font-weight: 600;">Select the regions where you want to collect and remit tax, then click on "Add region(s)"</span>.
                
                </s-col>
            </s-row>
            <s-row>
                <table class="card-table">
                    <thead>
                        <tr>
                            <td></td>
                            <td>Where</td>
                            <td>Why</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let nexus of nonEnabledNexusList;let i=index;">
                            <td>
                                <!-- <input type="checkbox" [(checked)]="nexus.checked"
                                    (change)="nexus.checked = !nexus.checked"> -->

                                    <fieldset role="group" aria-labelledby="label4">
                                        <legend id="label4"></legend>
                                        <input type="checkbox" id="d1{{i}}" name="d{{i}}" checked=""
                                        [(checked)]="nexus.checked" (change)="nexus.checked = !nexus.checked" >
                                        <label for="d1{{i}}">&nbsp;</label>
                                    </fieldset>
                            </td>
                            <td>
                                <!-- {{nexus.countryName}}, {{nexus.stateName}} -->
                                <!-- {{nexus.getWhere()}} -->
                                {{nexus | nexusWhereContent}}
                            </td>
                            <td>
                                <!-- {{nexus.corporateHeadQuarters}} {{nexus.totalInvoice}} of the most recent 1000 invoices
                        shipped to here.  -->
                                {{nexus | nexusWhyContent}}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </s-row>
            <s-row>
                <s-col style="padding-left:0px;padding-bottom:0px;">
                    <button type="submit" class="button primary" value="Add region(s)" [disabled]="anyNexusSelected()" >Add region(s)</button>
                </s-col>
            </s-row>
        </div>

        <div *ngIf="(enabledNexus > 0) && (enabledNexus < totalNexus)">
            <s-row>
                <h2>You're set up to collect and remit taxes in these regions.</h2>
            </s-row>
            <s-row>
                <table class="card-table">
                    <thead>
                        <tr>
                            <td></td>
                            <td>Where</td>
                            <td>Why</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let nexus of enabledNexusList">
                            <td>
                                <img src="/assets/images/AvaTick.png" alt="Avalara AvaTax" style="height:16px;" />
                            </td>
                            <td>
                                <!-- {{nexus.countryName}}, {{nexus.stateName}} -->
                                {{nexus | nexusWhereContent}}
                            </td>
                            <td>
                                <!-- {{nexus.corporateHeadQuarters}} {{nexus.totalInvoice}} of the most recent 1000 invoices
                        shipped to here.  -->
                                {{nexus | nexusWhyContent}}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </s-row>
        </div>

      
    </div>
    </form>
</s-container>