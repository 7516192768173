import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { AuthenticationService } from '../../services/authentication.service';
import { AppConfig } from '../../../app.config';
import { SpinnerService } from '../../../shared/spinner/spinner.service';


@Component({
  selector: 'app-disconnect',
  templateUrl: './disconnect.component.html',
  styleUrls: ['./disconnect.component.css']
})
export class DisconnectComponent implements OnInit {

  realmId : string
  constructor(private route: ActivatedRoute, private spinnerService: SpinnerService, private authService: AuthenticationService) { }

  ngOnInit() {

    var realmId = this.route.snapshot.queryParams["realmId"];

    console.log('realmId', realmId);
    console.log('referrer', document.referrer);
    
    if(realmId)
    {
      console.log('Valid disconnection from Intuit -->');
      this.disconnectQBOfromApp(realmId);
    }
    

    if(localStorage.length > 0)
    {
      localStorage.clear();
      sessionStorage.clear();
      location.reload();
    }
    
  }

  disconnectQBOfromApp(realmId) {
    var current = this;
    current.spinnerService.show();
        this.authService.disconnectQBOfromApp(realmId).then(
          (res) => {
            console.log('QBO Company has been disconnected.');
            
            current.disconnectQBOfromAppSecondAttempt(realmId);
            current.spinnerService.hide();
          }
        )
      }

  disconnectQBOfromAppSecondAttempt(realmId) {
    var current = this;
    current.spinnerService.show();
    setTimeout(function(){  
          current.authService.disconnectQBOfromApp(realmId).then(
          (res) => {
            console.log('QBO Company has been disconnected.');
            current.spinnerService.hide();
          }
        )
      }, 1000);
    }

  getReconnectDocumentation() {
    window.open(AppConfig.settings.externalUrls.disconnect_ReconnectDocument, '_blank').focus();
  }

  getAvalaraSupportHelp() {
    window.open(AppConfig.settings.externalUrls.disconnect_AvalaraSupportProcess, '_blank').focus();
  }
}
