import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import { configurationModel, SelectedItemFromList } from '../models/ConfigurationModel';
import { userCredentials } from '../models/UserCredentials';
import { AvaTaxAccountValidationResponse } from '../models/AvaTaxAccountValidationResponse';
import { IHttpService } from '../../shared/services/IHttpService';
import { AppConfig } from '../../app.config';
import { CommonService } from '../services/common.service';

@Injectable({
    providedIn: 'root'
})
export class ConnectorConfigurationService implements IHttpService{
    private configSettingsBaseUrl =  `${AppConfig.settings.avatax.api_base_url}/api/Configuration`;

    constructor(private http: HttpClient, private commonService: CommonService) { }
 
    getConnectorSettings(id:string, authToken:string):Observable<configurationModel> {
        const url = `${this.configSettingsBaseUrl}/config/${id}`;
        const headers = new HttpHeaders().set('Authorization', `Bearer ${authToken}`)
        return this.http.get<configurationModel>(url,{headers: headers}).pipe(catchError((e:any)=>this.handleError(e)));
      }

      searchItemsFromQBO(id:string, searchText: string, authToken:string){
        const url = `${this.configSettingsBaseUrl}/SearchItemsFromQbo/${id}`;
        const headers = new HttpHeaders().set('Authorization', `Bearer ${authToken}`)
        return this.http.post<any>(url,{search: searchText} ,{headers: headers});
      }

      validateAvaTaxAccount(userCreds:userCredentials, authToken:string):Observable<AvaTaxAccountValidationResponse>{
        const accountValidationUrl = `${this.configSettingsBaseUrl}/validateaccount`;
        const headers = new HttpHeaders().set('Authorization', `Bearer ${authToken}`)
        return this.http.post<AvaTaxAccountValidationResponse>(accountValidationUrl,userCreds,{headers: headers}).pipe(catchError((e:any)=>this.handleError(e)));
      }

      saveConnectorSettings(connectorSettings : configurationModel, authToken:string): Observable<any>{
        const url = `${this.configSettingsBaseUrl}/config`;
        const headers = new HttpHeaders().set('Authorization', `Bearer ${authToken}`)
        return this.http.put(url,connectorSettings,{headers: headers}).pipe(catchError((e:any)=>this.handleError(e)));
      }

      handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('An error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          // The response body may contain clues as to what went wrong,
          console.error(
            `Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);

            this.commonService.unauthorizationRedirect(error);
            return throwError('unauthorized');
        }
        // return an observable with a user-facing error message
        return throwError(
          'Something went wrong, please try again later.');
      };
}