import { Component } from '@angular/core';
import { AuthenticationService } from './shared/services/authentication.service';


declare var _satellite : any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'AvaTaxForQBO';
  ShowHeader : boolean = true;
  constructor(private authService : AuthenticationService){
    this.authService.currentShowHeaderValue.subscribe(value => this.ShowHeader = value);
    _satellite.pageBottom();
  }
}
