import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import { IHttpService } from '../../shared/services/IHttpService';
import { QboSelectedCustomer, SearchQboCustomerRequest, SendQboCustomerRequest } from '../models/exemptionModel';
import { AppConfig } from '../../app.config';
import { CommonService } from '../services/common.service';

@Injectable({
    providedIn: 'root'
})
export class ExemptionService implements IHttpService{
    private exemptionsBaseUrl =  `${AppConfig.settings.avatax.api_base_url}/api/CertCapture`;

    constructor(private http: HttpClient, private commonService: CommonService) { }
 
    exemptedCustomerSearchRequest(id:string, authToken:string):Observable<any> {
      const url = `${this.exemptionsBaseUrl}/ExemptedCustomerSearchRequest/${id}`;
      const headers = new HttpHeaders().set('Authorization', `Bearer ${authToken}`)
      return this.http.post<any>(url, "", {headers: headers}).pipe(catchError((e:any)=>this.handleError(e)));
    }

    fetchExemptedCustomers(realmId:string, authToken:string):Observable<any> {
      const url = `${this.exemptionsBaseUrl}/FetchExemptedCustomers/${realmId}`;
      const headers = new HttpHeaders().set('Authorization', `Bearer ${authToken}`)
      return this.http.post<any>(url, "" ,{headers: headers}).pipe(catchError((e:any)=>this.handleError(e)));
    }

    fetchQboCustomersRequest(id:string, searchQboCustomerRequest: SearchQboCustomerRequest, authToken:string):Observable<any> {
      const url = `${this.exemptionsBaseUrl}/FetchQboCustomersRequest/${id}`;
      const headers = new HttpHeaders().set('Authorization', `Bearer ${authToken}`)
      return this.http.post<any>(url, searchQboCustomerRequest, {headers: headers}).pipe(catchError((e:any)=>this.handleError(e)));
    }

    fetchQboCustomersResponse(realmId:string, authToken:string):Observable<any> {
      const url = `${this.exemptionsBaseUrl}/FetchQboCustomersResponse/${realmId}`;
      const headers = new HttpHeaders().set('Authorization', `Bearer ${authToken}`)
      return this.http.post<any>(url, "" ,{headers: headers}).pipe(catchError((e:any)=>this.handleError(e)));
    }

    sendExemptionRequest(realmId:string, requestMethod: string, qboSelectedCustomer: QboSelectedCustomer[], authToken:string):Observable<any> {
      const url = `${this.exemptionsBaseUrl}/SendExemptionRequest/${realmId}/${requestMethod}`;
      const headers = new HttpHeaders().set('Authorization', `Bearer ${authToken}`)
      return this.http.post<any>(url, qboSelectedCustomer ,{headers: headers}).pipe(catchError((e:any)=>this.handleError(e)));
    }

    downloadCertificate(realmId:string, certificateId: string, authToken:string):Observable<string> {
      const url = `${this.exemptionsBaseUrl}/DownloadCertificate/${realmId}`;
      const headers = new HttpHeaders().set('Authorization', `Bearer ${authToken}`)
      return this.http.post(url, certificateId ,{headers: headers, observe: 'body', responseType: 'text'}).pipe(catchError((e:any)=>this.handleError(e)));
    }

    downloadCertRequestErrorsFile(data, filename='data') {
      var columnList = ['id','customerName','errorMessage'];
      var headerList = ['Customer Code','Customer Name','Error Message'];
      let csvData = this.ConvertToCSV(data, columnList, headerList);
      console.log(csvData)
      let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
      let dwldLink = document.createElement("a");
      let url = URL.createObjectURL(blob);
      let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
      if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
          dwldLink.setAttribute("target", "_blank");
      }
      dwldLink.setAttribute("href", url);
      dwldLink.setAttribute("download", filename + ".csv");
      dwldLink.style.visibility = "hidden";
      document.body.appendChild(dwldLink);
      dwldLink.click();
      document.body.removeChild(dwldLink);
  }

    downloadFile(data, filename='data') {
      var columnList = ['id','customerName','address', 'requestLink'];
      var headerList = ['Customer Code','Customer Name','Address', 'Request Link'];
      let csvData = this.ConvertToCSV(data, columnList, headerList);
      console.log(csvData)
      let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
      let dwldLink = document.createElement("a");
      let url = URL.createObjectURL(blob);
      let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
      if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
          dwldLink.setAttribute("target", "_blank");
      }
      dwldLink.setAttribute("href", url);
      dwldLink.setAttribute("download", filename + ".csv");
      dwldLink.style.visibility = "hidden";
      document.body.appendChild(dwldLink);
      dwldLink.click();
      document.body.removeChild(dwldLink);
  }

  ConvertToCSV(objArray, columnList, headerList) {
       let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
       let str = '';
       let row = 'Sr. No,';

       for (let index in headerList) {
           row += headerList[index] + ',';
       }
       row = row.slice(0, -1);
       str += row + '\r\n';
       for (let i = 0; i < array.length; i++) {
           let line = (i+1)+'';
           for (let index in columnList) {
              let head = columnList[index];

               line += ',' + array[i][head];
           }
           str += line + '\r\n';
       }
       return str;
   }

    handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('An error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          // The response body may contain clues as to what went wrong,
          console.error(
            `Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);

          this.commonService.unauthorizationRedirect(error);
          return throwError('unauthorized');
        }
        // return an observable with a user-facing error message
        return throwError(
          'Something went wrong, please try again later.');
      };
}