<s-container class="advance-configuration">
  <form [formGroup]="configSettingForm" (ngSubmit)="onSubmit()" *ngIf="this.sourceParam ==''">
    <a [routerLink]="[ '/home']" style="margin: 0px 44px;">
      &#8592; back
    </a>
    <s-row>
      <s-col span="sm-12 md-12 lg-7 7">
        <s-alert *ngIf="showAlert" status="{{alertCssClass}}">
          <div id="alert_panel" role="alert">
            <div class="panel-heading">{{alertMessage}}</div>
          </div>
        </s-alert>
        <s-box class="border-padding-0">
          <s-row>
          <s-col span="sm-12 md-12 lg-8 8" style="padding-right: 0px;padding-bottom: 0px;">
              <div class="row-left-edit row-block-0-8-0-8">
                <div class="small-12 column" style="padding-left:0px !important;">
                  <h2 class="page-Subtitle">Account settings</h2>
                </div>
              </div>
              <div class="row row-block-0-8-0-8">
                <div class="col-md-12">
                  <label class="control-label">
                    Sales tax item (based on your general ledger account)
                    <span class="tooltip" style="display: inline;float:right;padding:0px;">
                      <s-icon name="alert-circle-filled" role="img"
                              aria-label="sales tax item"
                              style="color: #0c7bab;float: right !important;"></s-icon>
                      <span class="tooltiptext"
                            style="margin-left:-150px; width:300px;">
                        Select the Tax Product/Item Name mapped with Sales Tax
                        Payable sub-account in QuickBooks Online
                      </span>
                    </span>
                  </label>
                </div>
                <div class="col-md-8">
                  <select id="_SalesTaxItem" formControlName="SalesTaxItem"
                          class="form-control">
                    <option *ngFor="let item of salesTaxItems" value={{item.itemCode}}>
                      {{item.itemName}}
                    </option>
                  </select>
                  <h4 *ngIf="this.configModel?.configuration?.taxItemId" style="color:green;margin-top:4px;">
                    <s-icon name="check-circle-filled"></s-icon>
                    Configured
                  </h4>
                  <h4 *ngIf="!this.configModel?.configuration?.taxItemId" style="color:red;margin-top:4px;">
                    <s-icon name="close-circle-filled"></s-icon>
                    Not configured
                  </h4>
                  
                    <li class="col-md-12"
                        *ngIf="configSettingForm.controls['SalesTaxItem'].invalid"
                        style="margin-top:-4px;font-size:smaller;color: rgb(197, 28, 28);margin-left: 0px;list-style: none;">
                      Sales Tax item is required
                    </li>
                 
              <!--<div class="row row-block-0-8-0-8">
                <div class="col-md-12">
                  <label class="control-label">
                    Schedule Start Date for AvaTax Calculation
                    <span class="tooltip" style="display: inline; float:right;padding:0px;">
                      <s-icon name="alert-circle-filled" role="img"
                              aria-label="schedule start date"
                              style="color: #0c7bab;float: right;"></s-icon>
                      <span class="tooltiptext"
                            style="margin-left:-150px; width:300px;">
                        Transactions created after this date will be considered for
                        Tax Calculation.
                        Leave this field blank for calculating tax on all
                        transactions.
                      </span>
                    </span>
                  </label>
                </div>
                <div class="col-md-12">
                  <input placeholder="mm/dd/yyyy" name="avaTaxStartDate" ngbDatepicker
                         readonly="readonly" #avaTaxStartDate="ngbDatepicker"
                         formControlName="AvaTaxStartDate"
                         AutomationAttribute="Ava_Settings_Input_AvaTaxStartDate"
                         style="display: inline;font-weight: 600;" />
                  <button *ngIf="configSettingForm.get('AvaTaxStartDate').value != null"
                          style="height: 30px;right:30px" class="calendar-btn"
                          (click)="clearStartDate()"
                          AutomationAttribute="ConnectorSettings_Button_StartDateCalendar"
                          type="button">
                    <s-icon name="close"></s-icon>
                  </button>
                  <button style="height: 30px;" class="calendar-btn"
                          (click)="avaTaxStartDate.toggle()"
                          AutomationAttribute="ConnectorSettings_Button_StartDateCalendar"
                          type="button">
                    <s-icon name="calendar"></s-icon>
                  </button>
                </div>
             
               </div>-->
                  </div>
                </div>
           </s-col>
          </s-row>
          <hr style="margin: 36px;" />
        </s-box>
        <!--<s-box class="border-padding-0">
          <s-row>
            <s-col span="sm-12 md-12 lg-8 8">
              <div class="row row-left-edit row-contents-0-8-0-8">
                <div class="small-12 column row-block-0-8-0-8">
                  <h2 class="page-Subtitle">Integration Settings</h2>
                </div>
              </div>
              <div class="row row-block-0-8-0-8">
                <div class="large-6 columns" style="padding-left:0px !important;margin-bottom: 24px;">
                  <div class="row row-left-edit" style="display: block !important">
                    <div class="row" style="display: block !important">
                      <div class="small-12 column">
                        <h4>Address validation</h4>
                      </div>
                    </div>
                    <div class="row">
                      Enable/disable address validation
                      <span style="padding-left: 40px;margin-right: 30px;">
                        <s-toggle aria-label="power switch" id="chkAddress"
                                  name="IsEnableAddressValidation"
                                  [attr.checked]="isEnableAddressValidation? true: null"
                                  AutomationAttribute="Ava_Settings_Checkbox_AddressValidation"
                                  (s-toggle)="addressValidationToggle($event)"
                                  style="position: absolute;margin-top: -7px;"></s-toggle>
                      </span>
                      <span style="margin-left:20px">
                        <ng-container *ngIf="(isEnableAddressValidation == true); then addrValidOn; else addrValidOff">
                        </ng-container>
                        <ng-template #addrValidOn>
                          On
                        </ng-template>
                        <ng-template #addrValidOff>
                          Off
                        </ng-template>
                      </span>
                    </div>
                  </div>
                </div>
                <div class="large-6 columns left-side-panels-ConnectorSettings">
                  <div class="row" style="display: block !important">
                    <div class="col-md-12">
                      <h4 style="margin-bottom: 0px;">Calculate tax for</h4>
                    </div>
                    <div class="col-md-12">
                      <span class="CompanyCode">
                        <fieldset role="radiogroup" aria-labelledby="label2">
                          <label style="margin:0px">
                            <input id="allCustomer"
                                   formControlName="IsMakeAllCustomerTaxable" [value]=true
                                   checked name="IsMakeAllCustomerTaxable"
                                   AutomationAttribute="Ava_Settings_RadioButton_AllCustomers"
                                   type="radio" style="margin-bottom:0px !important;">
                            <label for="allCustomer">All Customers</label>
                          </label>
                          <label style="margin:0px">
                            <input id="taxOnlyCustomer"
                                   formControlName="IsMakeAllCustomerTaxable" [value]=false
                                   name="IsMakeAllCustomerTaxable"
                                   AutomationAttribute="Ava_Settings_RadioButton_OnlyTaxableCustomers"
                                   type="radio" style="margin-bottom:0px !important;">
                            <label for="taxOnlyCustomer">Only Taxable Customers</label>
                          </label>
                        </fieldset>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </s-col>
            <s-col span="sm-12 md-12 lg-4 4" style="padding-left: 0px;">
            </s-col>
          </s-row>
          <hr style="margin: 36px;margin-top: 0px;" />
        </s-box>
        <s-box class="border-padding-0">
          <s-row>
            <s-col style="padding-bottom: 0px;">
              <div class="row row-left-edit row-contents-0-6-0-6">
                <div class="small-12 column row-block-0-6-0-6">
                  <h2 class="page-Subtitle" style="display: inline;">Exemption Settings</h2>
                  <span class="tooltip" style="display: inline;padding:0px;">
                    <s-icon name="alert-circle-filled" role="img" aria-label="help" style="color: #0c7bab; padding-left: 10px;"></s-icon>
                    <span class="tooltiptext" style="margin-left:-150px; width:300px;">
                      It is mandatory for user to select "Bill parent customer"
                      setting in QuickBooks-ERP-Sub Customer's customer card.
                    </span>
                  </span>
                </div>
              </div>
              <div class="row row-block-0-6-0-6">
                <div class="large-6 columns padding-left-0-margin-bottom-24">
                  <div class="row row-left-edit display-block">
                    <div class="row display-block">
                      <div class="small-12 column">
                        <h4 style="margin-bottom:0px;">Apply exemption</h4>
                      </div>
                    </div>
                    <div class="row">
                      <fieldset role="group" aria-labelledby="ApplyParentExemptionToSubCustomer">
                        <input type="checkbox" id="ApplyParentExemptionToSubCustomer"
                               formControlName="ApplyParentExemptionToSubCustomer"
                               name="ApplyParentExemptionToSubCustomer"
                               AutomationAttribute="Ava_Settings_Checkbox_ApplyParentExemptionToSubCustomer">
                        <label for="ApplyParentExemptionToSubCustomer">
                          Apply Parent customer’s
                          exemption to the child/sub customer’s transactions
                        </label>
                      </fieldset>
                    </div>
                    <div class="row">
                      <fieldset role="group" aria-labelledby="ApplyParentExemptionToProject">
                        <input type="checkbox" id="ApplyParentExemptionToProject"
                               formControlName="ApplyParentExemptionToProject"
                               name="ApplyParentExemptionToProject"
                               AutomationAttribute="Ava_Settings_Checkbox_ApplyParentExemptionToProject">
                        <label for="ApplyParentExemptionToProject">
                          Apply Parent customer’s
                          exemption to the project’s transactions
                        </label>
                      </fieldset>
                    </div>
                  </div>
                </div>
              </div>
            </s-col>
          </s-row>
          <hr style="margin: 36px; margin-top:0px" />
        </s-box>-->
        <s-box class="border-padding-0">
          <s-row *ngIf="this.configModel?.configuration?.rdfItemId && this.configModel?.configuration?.taxOnRdfItemId">
            <s-col span="sm-12 md-12 lg-8 8" style="padding-right: 0px;padding-bottom: 0px;">
              <div class="row-left-edit row-block-0-8-0-8">
                <div class="small-12 column" style="padding-left:0px !important;">
                  <h2 class="page-Subtitle">Retail delivery fee settings</h2>
                  Enable retail delivery fee settings
                  <span class="tooltip" style="display: inline;padding:0px;">
                    <s-icon name="alert-circle-filled" role="img" aria-label="help" style="color: #0c7bab; padding-left: 10px;"></s-icon>
                    <span class="tooltiptext" style="margin-left:-150px; width:300px;">
                      The income account of the mapped items in the RETAIL DELIVERY FEE ITEM and TAX ON RETAIL DELIVERY FEE ITEM fields will be used for account entries and to display the RDF and Tax on RDF breakup.
                      You can modify the Income Account of the automatically created items, or you can also map the preferred items in the RETAIL DELIVERY FEE ITEM and TAX ON RETAIL DELIVERY FEE ITEM fields.
                    </span>
                  </span>
                  <span style="padding-left: 40px;margin-right: 30px;">
                    <s-toggle aria-label="power switch" id="IsEnableRetailDeliveryFee"
                              name="IsEnableRetailDeliveryFee"
                              [attr.checked]="isEnableRetailDeliveryFee? true: null"
                              AutomationAttribute="Ava_Settings_Checkbox_IsEnableRetailDeliveryFee"
                              (s-toggle)="isRdfFeatureEnabled($event)"
                              style="position: absolute;margin-top: -7px;"></s-toggle>
                  </span>
                  <span style="margin-left:20px">
                    <ng-container *ngIf="(isEnableRetailDeliveryFee == true); then rdfOn; else rdfOff">
                    </ng-container>
                    <ng-template #rdfOn>
                      On
                    </ng-template>
                    <ng-template #rdfOff>
                      Off
                    </ng-template>
                  </span>
                </div>
              </div>
              <div class="row-left-edit row-block-0-8-0-8" style="display: block !important">
                <div class="large-6 columns" style="padding-left:0px !important">
                  <div class="row row-left-edit" style="display: block !important">
                    <div class="row">
                      <div>
                        <H4 style="margin-bottom: 0px;">Retail delivery fee item</H4>
                      </div>
                      <div>
                        <select id="_RdfItem" formControlName="RdfItem"
                                (change)="rdfItemSelected($event)" class="form-control">
                          <option value="">None</option>
                          <option *ngFor="let item of rdfItems" value={{item.itemCode}}>
                            {{item.itemName}}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="row">
                      <div>
                        <h4 style="margin-bottom: 0px;">Tax on Retail delivery fee item</h4>
                      </div>
                      <div>
                        <select id="_TaxOnRdfItem" formControlName="TaxOnRdfItem"
                                class="form-control">
                          <option value="">None</option>
                          <option *ngFor="let item of taxOnRdfItems" value={{item.itemCode}}>
                            {{item.itemName}}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </s-col>
          </s-row>
        </s-box>
        <s-box class="border-padding-0">
          <s-row *ngIf="!this.configModel?.configuration?.rdfItemId || !this.configModel?.configuration?.taxOnRdfItemId">
            <s-col span="sm-12 md-12 lg-10 10" style="padding-right: 0px;padding-bottom: 0px;">
              <div class="row-left-edit row-block-0-6-0-6">
                <div class="small-12 column" style="padding-left:0px !important;">
                  <h2 class="page-Subtitle">Retail delivery fee settings</h2>
                  Enable retail delivery fee settings
                  <span style="padding-left: 40px;margin-right: 30px;">
                    <s-toggle aria-label="power switch" id="IsEnableRetailDeliveryFee-Manual"
                              name="IsEnableRetailDeliveryFee-Manual"
                              [attr.checked]="isEnableRetailDeliveryFee? true: null"
                              AutomationAttribute="Ava_Settings_Checkbox_IsEnableRetailDeliveryFee-Manual"
                              (s-toggle)="retailDeliveryFeeToggle($event)"
                              style="position: absolute;margin-top: -7px;"></s-toggle>
                  </span>
                  <span style="margin-left:20px">
                    <ng-container *ngIf="(isEnableRetailDeliveryFee == true); then rdfOnManual; else rdfOffManual">
                    </ng-container>
                    <ng-template #rdfOnManual>
                      On
                    </ng-template>
                    <ng-template #rdfOffManual>
                      Off
                    </ng-template>
                  </span>
                </div>
              </div>
              <div style="display: block !important">
                <div class="large-6 columns" style="padding-left:0px !important">
                  <div class="row row-left-edit" style="display: block !important">
                    <div class="row row-block-0-6-0-6">
                      <div>
                        <h4 style="margin-bottom: 0px;"><b>Search:</b> Retail delivery fee item</h4>
                      </div>
                      <div>
                        <div class="search-container">
                          <input id="_SearchRdfItem" type="text" class="form-control"
                                 name="SearchRdfItem" (selectItem)="rdfItemSelected($event)"
                                 [(ngModel)]="selectedRdfItemFromList"
                                 [ngbTypeahead]="rdfItemSearch" [editable]="false"
                                 [resultFormatter]="resultFormatBandListValue"
                                 [inputFormatter]="inputFormatBandListValue"
                                 #instance="ngbTypeahead" />
                          <img *ngIf="rdfItemSearching==true"
                               src="/assets/images/icon_active.gif" />
                        </div>
                        <div *ngIf="selectedRdfItemFromList?.fullyQualifiedName !=null">
                          <b>Selected Item:</b>
                          {{selectedRdfItemFromList?.fullyQualifiedName}}
                        </div>
                        <div style="font-size: .775rem;">
                          The selected item will be used to display the Retail Delivery Fee on
                          the transaction, and the calculated Retail Delivery Fee will be
                          posted in the selected item's mapped income account.
                        </div>
                        <div>
                          <h4 style="margin-bottom: 0px;"><b>Search:</b> Tax on Retail delivery fee item</h4>
                        </div>
                        <div>
                          <div class="search-container">
                            <input id="_SearchTaxOnRdfItem" type="text" class="form-control"
                                   name="SearchTaxOnRdfItem"
                                   (selectItem)="taxOnRdfItemSelected($event)"
                                   [(ngModel)]="selectedTaxOnRdfItemFromList"
                                   [ngbTypeahead]="taxOnRdfItemSearch" [editable]="false"
                                   [resultFormatter]="resultFormatBandListValue"
                                   [inputFormatter]="inputFormatBandListValue"
                                   #instance="ngbTypeahead" />
                            <img *ngIf="taxOnRdfItemSearching==true"
                                 src="/assets/images/icon_active.gif" />
                          </div>
                          <div *ngIf="selectedTaxOnRdfItemFromList?.fullyQualifiedName !=null">
                            <b>Selected Item:</b>
                            {{selectedTaxOnRdfItemFromList?.fullyQualifiedName}}
                          </div>
                          <div style="font-size: .775rem;">
                            The selected item will be used to display the Tax On Retail
                            Delivery Fee on the transaction, and the calculated Tax will be
                            posted in the selected item's mapped income account.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </s-col>
          </s-row>
          <s-row>
            <s-col span="sm-12 md-12 lg-10 10" style="padding-top:30px; padding-right: 0px;padding-bottom: 0px;">
              <div class="row-left-edit row-block-0-6-0-6">
                <button tabindex="-1" type="submit" [disabled]="!configSettingForm.dirty || configSettingForm.invalid"
                        class="primary" style="width:100px;" id="btnSave" AutomationAttribute="Ava_Settings_Button_Save"
                        onclick="javascript: return ValidateForm();">
                  <span class="button-text">
                    Save
                  </span>
                </button>
              </div>
            </s-col>
          </s-row>
        </s-box>
      </s-col>
      <s-col span="sm-12 md-12 lg-5 5">
        <img src="/assets/images/qbo-earth-new.png" width="420px" style="margin-top: 96px;margin-left:30px;"
             alt="QuickBooks Online integration with Avalara" />
      </s-col>
    </s-row>
  </form>
  <!--<form [formGroup]="configSettingForm" (ngSubmit)="onSubmit()" *ngIf="this.sourceParam !=''">
    <s-row style="display: block !important;padding: 0% 3% 0% 3%;">
      <s-col span="sm-12 12" style="padding-bottom: 0px;">
        <h3 style="line-height:1rem"> Configure the QuickBooks connector </h3>
        <h4 style="color: #c3c3c3;">
          Once you complete the configuration, all the future transactions will
          calculate tax automatically based on the settings you enable.
        </h4>
      </s-col>
    </s-row>
    <s-row>
      <s-col span="sm-12 md-12 lg-8 8">
        <s-alert *ngIf="showAlert" status="{{alertCssClass}}">
          <div id="alert_panel" role="alert">
            <div class="panel-heading">{{alertMessage}}</div>
          </div>
        </s-alert>
        <s-row>
          <s-col span="sm-6 md-6 lg-6 6" style="padding-bottom: 0px;">
            <div style="display: block !important">
              <div class="large-6 columns" style="padding-left:0px !important">
                <div class="row row-left-edit" style="display: block !important">
                  <div class="row" style="display: none !important;padding: 0% 8% 0% 8%;">
                    <div>
                      <label class="control-label">Account ID</label>
                    </div>
                    <div>
                      <input id="_AvaTaxAccountNumber"
                             AutomationAttribute="Ava_Settings_Input_AvaTaxAccountId"
                             class="form-control control-textbox-ConnectorSettings creds"
                             formControlName="AvaTaxAccountNumber">
            <li class="col-md-12"
                *ngIf="configSettingForm.get('AvaTaxAccountNumber').touched && configSettingForm.get('AvaTaxAccountNumber').errors?.required"
                style="margin-top:-4px;font-size:smaller;color: rgb(197, 28, 28);margin-left: -22px;">
              Account number is required
            </li>
            <li class="col-md-12"
                *ngIf="configSettingForm.get('AvaTaxAccountNumber').touched && configSettingForm.get('AvaTaxAccountNumber').errors?.pattern"
                style="margin-top:-4px;font-size:smaller;color: rgb(197, 28, 28);margin-left: -22px;">
              Only numbers are allowed
            </li>
            </div>
            </div>
            <div *ngIf="!showValidateAvaTaxAccountButton">
              <div class="row row-block-0-8-0-8" id="dvCompanyList">
                <div>
                  <label class="control-label">Select company you want to connect</label>
                </div>
                <div>
                  <select id="_AvaTaxCompanyCode" formControlName="AvaTaxCompanyCode"
                          style="width:250px;"
                          AutomationAttribute="Ava_Settings_Input_AvaTaxCompanyName">
                    <option *ngFor="let company of avaTaxCompanies"
                            value={{company.companyCode}}>
                      {{company.companyName}}
                    </option>
                  </select>
            <li class="col-md-12"
                *ngIf="configSettingForm.controls['AvaTaxCompanyCode'].invalid"
                style="margin-top:-4px;font-size:smaller;color: rgb(197, 28, 28);margin-left: 0px;list-style: none;">
              Company is required
            </li>
            </div>
            </div>
            <div class="row" id="dvCompanyCode"
                 style="display: none !important;padding: 0% 8% 0% 8%;">
              <div>
                <label class="control-label">Avalara Company Code</label>
              </div>
              <div>
                <div>
                  <h4>
                    {{configSettingForm.controls['AvaTaxCompanyCode'].value}}
                  </h4>
                </div>
              </div>
            </div>
            </div>
            <div class="row row-block-0-8-0-8" *ngIf="showValidateAvaTaxAccountButton">
              <div>
                <div>
                  <span id="spErrorMessage"
                        class="field-validation-error">{{accountValidationMessage}}</span>
                </div>
              </div>
            </div>
            </div>
            <div class="row row-block-0-8-0-8">
              <div class="col-md-12">
                <label class="control-label">
                  Select QuickBooks Sales tax item
                </label>
              </div>
              <div class="col-md-8">
                <select id="_SalesTaxItem" formControlName="SalesTaxItem" class="form-control"
                        style="width:250px;">
                  <option *ngFor="let item of salesTaxItems" value={{item.itemCode}}>
                    {{item.itemName}}
                  </option>
                </select>
            <li class="col-md-12" *ngIf="configSettingForm.controls['SalesTaxItem'].invalid"
                style="margin-top:-4px;font-size:smaller;color: rgb(197, 28, 28);margin-left: 0px;list-style: none;">
              Sales Tax item is required
            </li>
            </div>
            </div>
            <div class="row" style="display: block !important; padding: 5% 8% 0% 8%;">
              <div class="col-md-12">
                <fieldset role="group" aria-labelledby="chkAddress">
                  <input type="checkbox" id="chkAddress"
                         formControlName="IsEnableAddressValidation"
                         name="IsEnableAddressValidation"
                         AutomationAttribute="Ava_Settings_Checkbox_AddressValidation">
                  <label for="chkAddress">Enable address validation</label>
                </fieldset>
              </div>
            </div>
            <div class="row" style="display: block !important; padding: 5% 8% 0% 8%;">
              <div class="col-md-12">
                <h4>Calculate tax for</h4>
              </div>
              <div class="col-md-12">
                <span class="CompanyCode">
                  <fieldset role="radiogroup" aria-labelledby="label2">
                    <label style="margin:0px">
                      <input id="allCustomer" formControlName="IsMakeAllCustomerTaxable"
                             [value]=true checked name="IsMakeAllCustomerTaxable"
                             AutomationAttribute="Ava_Settings_RadioButton_AllCustomers"
                             type="radio" style="margin-bottom:0px !important;">
                      <label for="allCustomer">All Customers</label>
                    </label>
                    <label style="margin:0px">
                      <input id="taxOnlyCustomer"
                             formControlName="IsMakeAllCustomerTaxable" [value]=false
                             name="IsMakeAllCustomerTaxable"
                             AutomationAttribute="Ava_Settings_RadioButton_OnlyTaxableCustomers"
                             type="radio" style="margin-bottom:0px !important;">
                      <label for="taxOnlyCustomer">Only Taxable Customers</label>
                    </label>
                  </fieldset>
                </span>
              </div>
            </div>
            </div>
            </div>
          </s-col>
          <s-col span="sm-12 m-12 lg-6 6" style="display: none;">
            <div class="large-6 columns left-side-panels-ConnectorSettings">
              <div class="row-left-edit" style="display: block !important">
                <div class="small-12 column" style="padding-left:0px !important">
                  <h3 class="page-Subtitle">&nbsp;</h3>
                </div>
              </div>
              <div class="row row-block-0-8-0-8">
                <div class="col-md-12">
                  <label class="control-label">Avalara Username</label>
                </div>
                <div class="col-md-12">
                  <input id='_AvaTaxUserName' AutomationAttribute="Ava_Settings_Input_AvaTaxUsername"
                         class="form-control control-textbox-ConnectorSettings creds"
                         formControlName="AvaTaxUserName">
            <li class="col-md-12"
                *ngIf="configSettingForm.get('AvaTaxUserName').touched && configSettingForm.get('AvaTaxUserName').errors?.required"
                style="margin-top:-4px;font-size:smaller;color: rgb(197, 28, 28);margin-left: 0px;list-style: none;">
              Username is required
            </li>
            </div>
            </div>
            </div>
          </s-col>
        </s-row>
        <br><br>
        <s-row>
          <s-col span="sm-12 md-12 lg-4 4">
            <div class="row" style="display: block !important;padding: 0% 3% 0% 3%;">
              <div class="col-md-12">
                <button tabindex="-1" type="submit"
                        [disabled]="!configSettingForm.dirty || configSettingForm.invalid" class="primary"
                        style="width:100px;display: inline-block;" id="btnSave"
                        AutomationAttribute="Ava_Settings_Button_Save"
                        onclick="javascript: return ValidateForm();">
                  <span class="button-text">
                    Save
                  </span>
                </button>
                <span style="margin-right:30px"></span>
                <button tabindex="-1" type="button" class="secondary" onclick="window.close();"
                        style="width:100px;display: inline-block;">
                  <span class="button-text">
                    Cancel
                  </span>
                </button>
              </div>
            </div>
          </s-col>
        </s-row>
      </s-col>
    </s-row>
  </form>-->
</s-container>
