import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { AuthGuard } from 'src/app/shared/gaurds/auth.gaurds';
import { FormGroup, FormBuilder, ReactiveFormsModule, FormArray, Validators } from '@angular/forms';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { userCredentials, isAuthorizeResponse } from '../models/UserCredentials';
import { configurationModel } from 'src/app/Connector/models/ConfigurationModel';
import { AIAuthTokenRequest } from '../../shared/models/AIAuthToken';
import { AppConfig } from '../../app.config';
import { SpinnerService } from '../../shared/spinner/spinner.service';
@Component({
  selector: 'app-avataxlogin',
  templateUrl: './avataxlogin.component.html',
  styleUrls: ['./avataxlogin.component.css']
})

export class AvaTaxLoginComponent implements OnInit {
  avataxloginSettingForm: FormGroup;
  userCreds: userCredentials = new userCredentials;

  private id: string;
  private realmId: string;
  private requestToken: string;
  private userId: string;
  private session_state: string;
  private state: string;
  private scope: string;
  private id_token: string;
  private code: string;


  errorMessage: string;
  //constructor(private fb: FormBuilder, private authService: AuthenticationService, private router: Router, private authGuard: AuthGuard, private spinnerService: SpinnerService) { }

  constructor(private fb: FormBuilder, private authService: AuthenticationService, private router: Router, private authGuard: AuthGuard, private route: ActivatedRoute, private spinnerService: SpinnerService) { }

  ngOnInit() {
    let token = this.authService.fetchAIAuthTokenFromStorage()
    //if (token != null) {
    //this.initiliazeFormModel();
    //}

    this.spinnerService.show();

    this.id = this.route.snapshot.queryParamMap.get("id")
    this.requestToken = this.route.snapshot.queryParamMap.get("requestToken")
    this.userId = this.route.snapshot.queryParamMap.get("userId")

    this.route.fragment.subscribe(fragment => {
      if (fragment) {
        var hashFragment = fragment;
        var obj = {};

        hashFragment.split('&').forEach(function (elem) {
          var parts = elem.split('=');
          obj[parts[0]] = parts[1];
        });
        this.code = obj['code']
        this.id_token = obj['id_token']
        this.scope = obj['scope']
        this.state = obj['state']
        this.session_state = obj['session_state']
      }
    })

    if (this.code != null && this.id_token != null && this.session_state != null && this.state != null && this.scope != null) {
      let tokenReq: AIAuthTokenRequest = {
        code: this.code,
        id_token: this.id_token,
        session_state: this.session_state,
        state: this.state,
        scope: this.scope,
        realmId : null
      }

      console.log("session_state:", this.session_state);
      
      this.authService.completeAIAuthentication(tokenReq);
      //this.router.navigate(['/home']);

    }
    // else{
    //   if(!this.id && !this.requestToken && !this.userId){
    //     this.authService.isLoggedIn().then(value=>{
    //       if(value){
    //         this.router.navigate(['/avataxlogin']);
    //       }
    //     })
    //   }    
    // }

    this.authService.authorizedForAiLogin(token.realmId, token.session_Id).subscribe(
      (isAuthorizedForAILogin: boolean) => {
        if (isAuthorizedForAILogin) {
          this.AILoginIn();
        }
        else {
          this.spinnerService.hide();
        }
      }
      ,
      (error: any) => {
        this.errorMessage = <any>error;
        this.spinnerService.hide();
        if(this.errorMessage != "unauthorized")
            alert(this.errorMessage);
      },
      () => { this.spinnerService.hide() }
    );

  }

  initiliazeFormModel() {
    // this.avataxloginSettingForm = this.fb.group({
    //   AvaTaxUserName: [{ value: '', disabled: false }, Validators.required],
    //   AvaTaxPassword: [{ value: '', disabled: false }, Validators.required],
    // });
  }

  private getCredentialsFromForm(): userCredentials {
    this.userCreds.username = this.avataxloginSettingForm.get('AvaTaxUserName').value
    this.userCreds.password = this.avataxloginSettingForm.get('AvaTaxPassword').value
    return this.userCreds
  }

  onSubmit() {
    if (this.avataxloginSettingForm.valid) {
      let userCreds: userCredentials
      userCreds = this.getCredentialsFromForm();
      this.saveCredentials(userCreds)
    }
  }

  saveCredentials(userCreds: userCredentials) {
    try {
      this.errorMessage = '';
      this.spinnerService.show();
      let token = this.authService.fetchAIAuthTokenFromStorage()
      if (token) {
        this.authGuard.canActivate().then((res) => {
          this.authService.validateAvaTaxUsers(token.userId, token.session_Id, userCreds).subscribe(
            (isAuthorizeResponse: isAuthorizeResponse) => {
              this.spinnerService.hide();
              if (isAuthorizeResponse.status)
                this.gotoLinkCompanyFlow(isAuthorizeResponse)
              else
                this.errorMessage = isAuthorizeResponse.errorMessage;
            },
            (error: any) => { console.log(error); this.spinnerService.hide(); },
            () => { this.spinnerService.hide(); }
          );
        });
      }
    }
    catch (error) {
      this.spinnerService.hide();
      console.error(error.message)
    }
  }


  gotoLinkCompanyFlow(isAuthorizeResponse: isAuthorizeResponse): void {
    if (isAuthorizeResponse) {
      if (isAuthorizeResponse.status) {
        this.authService.emitChange(true, isAuthorizeResponse.avaTaxUserName, isAuthorizeResponse.qboCompanyName)
        this.router.navigate(['/linkcompany']);
      }
    }
  }

  getBuyTechMarketingUrl() {
    window.open(AppConfig.settings.externalUrls.buyTech_MarketingUrl, '_blank').focus();
  }

  AILoginIn(): void {
    try {
      this.spinnerService.show();
      var token = this.authService.fetchAIAuthTokenFromStorage();
      this.spinnerService.show();
      this.authService.startAIAuthentication(token.realmId, this.requestToken, this.userId).catch(() => this.spinnerService.hide());
      this.spinnerService.show();
    } catch (error) {
      this.spinnerService.hide()
    }
  }
}