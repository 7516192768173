import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, throwError, Observable, of } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { OAuthConnectResponse, OAuthSessionResponse, ExpireTokens } from '../models/OAuthToken';
import { Router, ActivatedRoute } from '@angular/router';
import { AppConfig } from 'src/app/app.config';
import { configurationModel } from 'src/app/Connector/models/ConfigurationModel';
import { catchError } from 'rxjs/operators';
import { userCredentials, isAuthorizeResponse } from 'src/app/Connector/models/UserCredentials';
import { AvaTaxCompany, companyLinked, linkCompanyBody, userStatusBody, calculationStatusBody, HomeConfigLanding } from 'src/app/Connector/models/avataxcompany';
import { Constants } from '../../shared/models/Constants';
import { AIAuthTokenRequest, AIAuthTokenResponse } from '../models/AIAuthToken';
import { stringify } from '@angular/compiler/src/util';
import { SpinnerService } from '../../shared/spinner/spinner.service';
import { CommonService } from '../../Connector/services/common.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private isUserSignedIn: boolean = false;

  private readonly aioAuthBaseUrl: string = AppConfig.settings.OAuthUrls.oAuthBaseUrl + "/api/oauth";

  private readonly oAuthBaseUrl: string = AppConfig.settings.OAuthUrls.oAuthBaseUrl + "/api/signin";
  private readonly oktaAuthBaseUrl: string = AppConfig.settings.OAuthUrls.oAuthBaseUrl + "/api/adminPanel/oktasignin"
  private readonly oktaRevokeTokenUrl: string = AppConfig.settings.OAuthUrls.oAuthBaseUrl + "/api/adminPanel/oktasignin/revoke"

  private readonly authorizedForAiUrl: string = AppConfig.settings.OAuthUrls.oAuthBaseUrl + "/api/avatax/authorizedForAiLogin"

  private readonly getConfigurationUrl: string = AppConfig.settings.OAuthUrls.oAuthBaseUrl + "/api/configuration"
  private readonly saveCredentialsUrl: string = AppConfig.settings.OAuthUrls.oAuthBaseUrl + "/api/avatax/authorize"
  private readonly fetchAvaTaxCompaniesUrl: string = AppConfig.settings.OAuthUrls.oAuthBaseUrl + "/api/avatax/company"
  private readonly submitCompanyUrl: string = AppConfig.settings.OAuthUrls.oAuthBaseUrl + "/api/avatax/linkCompany"
  private appHealthCheckBaseurl = `${AppConfig.settings.avatax.api_base_url}/api/utilities/health`
  private readonly intuitApiUrl = `${AppConfig.settings.OAuthUrls.oAuthBaseUrl}/api/Intuit`;

  private errorMessage: string;

  constructor(private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    private spinnerService: SpinnerService,
    private commonService: CommonService) { }

  // BehaviorSubject to hide/show header panel on the UI
  private showHeaderSource = new BehaviorSubject(true);
  currentShowHeaderValue = this.showHeaderSource.asObservable();
  showHeader(value: boolean) {
    this.showHeaderSource.next(value);
  }

  validateIntuitCompany(tokenReq: OAuthConnectResponse, authToken: string): Observable<boolean> {
    let url = `${this.aioAuthBaseUrl}/ValidateCompany`
    const headers = new HttpHeaders().set('Authorization', `Bearer ${authToken}`)
    return this.http.post<boolean>(url, tokenReq, { headers: headers }).pipe(catchError((e: any) => this.handleError(e)));
  }

  // Avalara Identity Methods

  authorizedForAiLogin(realmId: string, authToken: string): Observable<boolean> {
    let url = `${this.authorizedForAiUrl}/${realmId}`
    const headers = new HttpHeaders().set('Authorization', `Bearer ${authToken}`)
    return this.http.post<boolean>(url, realmId, { headers: headers }).pipe(catchError((e: any) => this.handleError(e)));
  }


  startAIAuthentication(realmId: string, requestToken: string, userId: string): Promise<void> {
    this.spinnerService.show();
    return this.signInAIRedirect(realmId, requestToken, userId).catch((error) => this.handleError(error))
  }

  signInAIRedirect(realmId: string, requestToken: string, userId: string): Promise<any> {
    return this.getSignInAIRedirectUrl(realmId, requestToken, userId).then((url) => {
      if (url != null || url != undefined) {
        this.spinnerService.show();
        document.location.href = url
      }
      else {
        console.log('invalid url')
        this.spinnerService.hide();
      }
    }).catch((error) => this.handleError(error))
  }

  private getSignInAIRedirectUrl(realmId: string, requestToken: string, userId: string): Promise<string> {
    try {
      let promise = new Promise<string>((resolve, reject) => {
        let url: string
        if ((realmId != '' && realmId != undefined && realmId != null) &&
          (requestToken != '' && requestToken != undefined && requestToken != null)) {
          url = `${this.aioAuthBaseUrl}?realmId=${realmId}&requestToken=${requestToken}&userId=${userId}`
        }
        else {
          url = `${this.aioAuthBaseUrl}?realmId=${realmId}`
        }

        console.log("getSignInAIRedirectUrl url:", url);

        this.http.get(url, { responseType: 'text' })
          .toPromise()
          .then(res => {
            resolve(res)
          },
            error => {
              this.handleError(error)
              reject(error)
            })
      })
      return promise


    } catch (error) {
      this.handleError(error)
    }
  }


  oAuthOken(tokenReq: AIAuthTokenRequest): Observable<any> {
    let url = `${this.oAuthBaseUrl}/TestCase`
    return this.http.post<any>(url, tokenReq.code, { }).pipe(catchError((e: any) => this.handleError(e)));
  }

  afterAIAuth(tokenReq: AIAuthTokenRequest): Promise<any> {
    let promise = new Promise<string>((resolve, reject) => {
      try {
        let url = `${this.oAuthBaseUrl}/GetAIValidator`
        this.http.post<any>(url, tokenReq)
        .toPromise()
        .then(
          res => {
            let realmId = localStorage.getItem('realmId');
            console.log("realmId0:", realmId);

            if(res.validationflag != null){
              console.log(res);
              if(res.validationflag == "false"){
                return resolve('company-validation-error');
              }
            }
            else {
            if(res.avaToken != null && res.error == null){
              if(tokenReq.realmId == null || tokenReq.realmId == ""){
                let tokenString = localStorage.getItem('qbo_session')
                var token: OAuthSessionResponse = new OAuthSessionResponse();
                if (tokenString != null || tokenString != undefined) 
                  token = JSON.parse(tokenString)

                token.session_Id = res.appToken + '_' + new Date().getTime();
                token.curSen = token.session_Id;
                token.avat = res.avaToken + '_' + new Date().getTime();
                token.avai = res.idToken;
                token.ava = res.avaAcc;
                token.avau = res.avaUser;
                token.avaut = res.avaType;
                token.isAIConnected = true;
                token.isSetupComplete = false;
                localStorage.setItem('qbo_session', JSON.stringify(token));
                console.log("realmId1:", realmId);
                if (realmId != null || realmId != undefined){
                  return resolve('true');
                }
                else{
                  return resolve('window-close');
                }
              }
              else {
                let tokenString = localStorage.getItem('qbo_session')
                if (tokenString != null || tokenString != undefined) {
                  let token: OAuthSessionResponse = JSON.parse(tokenString)
                  token.session_Id = res.appToken + '_' + new Date().getTime();
                  token.curSen = token.session_Id;
                  token.avat = res.avaToken + '_' + new Date().getTime();
                  token.avai = res.idToken;
                  token.ava = res.avaAcc;
                  token.avau = res.avaUser;
                  token.isAIConnected = true;
                  token.isSetupComplete = res.isSetupComplete;
                  localStorage.setItem('qbo_session', JSON.stringify(token));
                  if (realmId != null || realmId != undefined){
                    console.log("realmId2:", realmId);
                    resolve('true');
                  }
                  else{
                    console.log("realmId3:", realmId);
                    resolve('window-close');
                  }
                }
              }
              resolve('true')
            }
            else {
              console.log("Error occured in token creation", res.error);
              resolve('false');
            }
          }
          },
          error => {
            this.handleError(error)
            reject(error)
          }
        )

        
        //resolve('')
      } catch (error) {
        reject(error)
      }
    });
    return promise;
  }

  completeAIAuthenticationForOnBoarding(tokenReq: AIAuthTokenRequest):void{
    try{
      this.spinnerService.show()
      this.generateIntuitUrl(tokenReq).subscribe(
        (response : any) => {
            if(response){
              this.spinnerService.hide();
              document.location.href = response.intuitUrl;
            }else {
              console.log("Intuit url is not generated");
            }
                              
          this.spinnerService.hide(); 
        },
        (error: any) => {this.errorMessage = <any>error; this.spinnerService.hide();}
      )
    }
    catch (error) {
      console.error(error.message)
      this.spinnerService.hide()
    }    
  }

  completeAIAuthentication(tokenReq: AIAuthTokenRequest):void{
    try{
      this.spinnerService.show()
      this.generateIntuitUrl(tokenReq).subscribe(
        (response : any) => {
            if(response){
              this.spinnerService.hide();
              var left = ( screen.width - window.outerWidth / 1.5 ) / 2;
              var top = ( screen.height - window.outerHeight / 1.5 ) / 4;
              window.open( response.intuitUrl, "centerWindow", 'resizable=yes, width=' + window.outerWidth / 1.5
                + ', height=' + window.outerHeight / 1.5 + ', top=' + top + ', left=' + left);

            }else {
              console.log("Intuit url is not generated");
            }
                              
          this.spinnerService.hide(); 
        },
        (error: any) => {this.errorMessage = <any>error; this.spinnerService.hide();}
      )
    }
    catch (error) {
      console.error(error.message)
      this.spinnerService.hide()
    }    
  }
  isUserAccessAllowed(){
    let tokenString = localStorage.getItem('qbo_session')
    if (tokenString != null || tokenString != undefined) {
      var token: OAuthSessionResponse = JSON.parse(tokenString)
      let allowedRoles = AppConfig.settings.allowedRoles;
      return (allowedRoles.includes(token.avaut))
    }
    return false;
  }

  backToIntegration(){
    let tokenString = localStorage.getItem('qbo_session')
    if (tokenString != null || tokenString != undefined) {
      let token: OAuthSessionResponse = JSON.parse(tokenString)

      //block list the previous token
      let prevToken = token.session_Id;

      token.session_Id = token.curSen;
      token.userId = null;
      token.realmId = null;
      token.isQboConnected = false;
      token.isSetupComplete = false;
      localStorage.setItem('qbo_session', JSON.stringify(token));

      let expireTokens: ExpireTokens[] = [
        { token : prevToken, tokenType: "Jwt" },
      ];

      this.expireJwtToken(false, expireTokens, prevToken).subscribe(
        (model: any) => {},
        (error: any) => { console.log(<any>error); this.spinnerService.hide() },
        () => { }
      );

      //clear header logged in details
      this.emitChange(false);
      window.open('/integration', '_self');
    }
  }

  generateIntuitUrl(tokenReq: AIAuthTokenRequest):Observable<any> {
    const url = `${this.oAuthBaseUrl}`;
    return this.http.post<any>(url,tokenReq).pipe(catchError((e:any)=>this.handleError(e)));
  }

  saveNewAIAuthToken(aiToken: AIAuthTokenResponse): Promise<any> {
    let promise = new Promise((resolve, reject) => {
      let tokenString = localStorage.getItem('qbo_session')
      if (tokenString != null || tokenString != undefined) {
        let token: OAuthSessionResponse = JSON.parse(tokenString)
        token.isAIConnected = true;
        token.isAvaTaxCompanyConfigured = aiToken.isAvaTaxCompanyConfigured;
        localStorage.setItem('qbo_session', JSON.stringify(token));
      }
      else {
        console.log("qbo session not found");
      }
      resolve(0);
    })
    return promise;
  }

  isAITokenValid(token: AIAuthTokenResponse): boolean {
    try {
      if (token != null) {
        let sessionId = token.session_Id
        let sessionTime = sessionId.substring(token.session_Id.lastIndexOf('_') + 1, sessionId.length);
        if (new Date().getTime() - +sessionTime <= 1500000) {
          return true
        }
      }
      return false
    } catch (error) {
      console.log(error)
    }

  }

  validateCompany(realmId): Promise<void> {
    return this.signInRedirect(realmId, "VerifyCompany").catch((error) => this.handleError(error))
  }

  // End Avalara Identity Methods

  startAuthentication(): Promise<void> {
    return this.signInRedirect("", "").catch((error) => this.handleError(error))
  }

  signInRedirect(realmId: string, operationType: string): Promise<any> {
    return this.getSignInRedirectUrl(realmId, operationType).then((url) => {
      if (url != null || url != undefined) {
        console.log(url);
        document.location.href = url

      }
      else {
        console.log('invalid url')
      }
    }).catch((error) => this.handleError(error))
  }

  UpdateCalculationStatus(userId: string, authToken: string, userStatusBody: userStatusBody): Observable<calculationStatusBody> {
    try {

      let url = `${this.getConfigurationUrl}/${userId}`
      const headers = new HttpHeaders().set('Authorization', `Bearer ${authToken}`)
      return this.http.put<calculationStatusBody>(url, userStatusBody, { headers: headers }).pipe(catchError((e: any) => this.handleError(e)));
    } catch (error) {
      this.handleError(error)
    }
  }

  fetchUserConfigurationModel(userId: string, authToken: string): Observable<HomeConfigLanding> {
    try {

      let url = `${this.getConfigurationUrl}/${userId}`
      const headers = new HttpHeaders().set('Authorization', `Bearer ${authToken}`)
      return this.http.get<HomeConfigLanding>(url, { headers: headers }).pipe(catchError((e: any) => this.handleError(e)));

    } catch (error) {
      this.handleError(error)
    }
  }

  validateAvaTaxUsers(userId: string, authToken: string, userCredentials: userCredentials): Observable<isAuthorizeResponse> {
    try {
      let url = `${this.saveCredentialsUrl}/${userId}`
      const headers = new HttpHeaders().set('Authorization', `Bearer ${authToken}`)
      return this.http.post<isAuthorizeResponse>(url, userCredentials, { headers: headers }).pipe(catchError((e: any) => this.handleError(e)));

    } catch (error) {
      this.handleError(error)
    }
  }

  fetchUserAvaTaxCompanies(userId: string, authToken: string): Observable<AvaTaxCompany[]> {
    try {

      let url = `${this.fetchAvaTaxCompaniesUrl}/${userId}`
      const headers = new HttpHeaders().set('Authorization', `Bearer ${authToken}`)
      return this.http.get<AvaTaxCompany[]>(url, { headers: headers }).pipe(catchError((e: any) => this.handleError(e)));

    } catch (error) {
      this.handleError(error)
    }
  }

  submitAvaTaxCompanyCode(userId: string, authToken: string, companyCode: linkCompanyBody): Observable<companyLinked> {
    try {
      let url = `${this.submitCompanyUrl}/${userId}`
      const headers = new HttpHeaders().set('Authorization', `Bearer ${authToken}`)
      return this.http.post<companyLinked>(url, companyCode, { headers: headers }).pipe(catchError((e: any) => this.handleError(e)));
    } catch (error) {
      this.handleError(error)
    }
  }

  private getSignInRedirectUrl(realmId: string, operationType: string): Promise<string> {
    try {
      let promise = new Promise<string>((resolve, reject) => {
        let url: string
        url = `${this.oAuthBaseUrl}?realmId=${realmId}&operation=${operationType}`
        this.http.get(url, { responseType: 'text' })
          .toPromise()
          .then(res => {
            resolve(res)
          },
            error => {
              this.handleError(error)
              reject(error)
            })
      })
      return promise
    } catch (error) {
      this.handleError(error)
    }
  }

  async getOktaAuthUrl(): Promise<any> {
    try {
      let url = `${this.oktaAuthBaseUrl}`;
      let oktaUrl = await this.http.get(url, { headers: null, responseType: 'text' }).toPromise()
      return oktaUrl;
    } catch (error) {
      this.handleError(error)
    }
  }

  completeOktaAuthentication(tokenReq: OAuthConnectResponse): Promise<void> {
    return this.getOktaAuthentication(tokenReq).then((tokenRes) => {

      tokenRes.session_Id = tokenRes.sessionId;
      tokenRes.source = "okta";
      this.saveAIAuthToken(tokenRes);

      this.isLoggedIn().then(value => {
        if (value) {
          this.router.navigate(['/admin/customers']);
        }
      });
    });
  }

  getOktaAuthentication(tokenReq: OAuthConnectResponse): Promise<any> {
    return this.oktaAuthentication(tokenReq);
  }

  oktaAuthentication(tokenReq: OAuthConnectResponse): Promise<any> {
    try {
      let url = `${this.oktaAuthBaseUrl}`
      let promise = new Promise<any>((resolve, reject) => {
        this.http.post<OAuthConnectResponse>(url, tokenReq)
          .toPromise()
          .then(
            res => {
              resolve(res)
            },
            error => {
              this.handleError(error)
              reject(error)
            }
          )
      })
      return promise
    } catch (error) {
      this.handleError(error)
    }
  }

  oktaTokenRevoke(tokenReq: any) {
    this.oktaTokenRevokeResult(tokenReq).subscribe(
      (isRevoked: any) => {
        // token revoked from Okta
        if (isRevoked) {
          //Expire and remove the session from browser
          this.oktaSessionLogOut();
        }
      },
      (error: any) => {
        let errorMessage = <any>error;
        if(errorMessage != "unauthorized")
          alert(errorMessage);
      },
      () => { }
    );
  }

  oktaTokenRevokeResult(tokenReq: any): Observable<any> {
    const url = `${this.oktaRevokeTokenUrl}`;
    const headers = new HttpHeaders().set('Authorization', `Bearer ${tokenReq}`)
    return this.http.get<any[]>(url, { headers: headers }).pipe(catchError((e: any) => this.handleError(e)));
  }

  oktaSessionLogOut(): Promise<void> {
    return this.oktaSignoutRedirect()
      .catch(error => {
        console.error('error while signing out user', error);
      });
  }

  oktaSignoutRedirect(): Promise<any> {
    //add logic to terminate session and redirect to login page
    let promise = new Promise<string>((resolve, reject) => {
      try {
        this.removeAIAuthTokenFromStorage()
        this.isUserSignedIn = false;
        this.emitChange(this.isUserSignedIn);
        //window.location.href = Constants.OktaSignOutUrl;
        //document.location.href = Constants.OktaSignOutUrl;
        window.open(Constants.OktaSignOutUrl, '_self');

        resolve('');
      } catch (error) {
        console.log('oktaSignoutRedirect error', error);
        reject(error)
      }
    });
    return promise;
  }

  //================================================

  completeAuthentication(tokenReq: OAuthConnectResponse): Promise<void> {
    return this.getAIAuthToken(tokenReq).then((tokenRes) => {
      // logic to save the token
      if (tokenRes.isQboDisconnected) {
        this.router.navigate(['/learnmore']);
      }
      else {
        var aiUrl = tokenRes.aiUrl;
        if(aiUrl != null)
          window.location.href = aiUrl;
        else
          alert("Connector authentication failed, ERP company is not found");
      }
    })
  }

  saveAIAuthToken(aiToken: OAuthSessionResponse): Promise<any> {
    let promise = new Promise((resolve, reject) => {
      localStorage.setItem('qbo_session', JSON.stringify(aiToken));
      
      resolve('');
    })
    return promise
  }

  getAIAuthToken(tokenReq: OAuthConnectResponse): Promise<any> {
    return this.generateAIToken(tokenReq)
  }

  private generateAIToken(tokenReq: OAuthConnectResponse): Promise<any> {
    try {
      let url = `${this.aioAuthBaseUrl}`
      let promise = new Promise<any>((resolve, reject) => {
        this.http.post<OAuthConnectResponse>(url, tokenReq)
          .toPromise()
          .then(
            res => {
              resolve(res)
            },
            error => {
              this.handleError(error)
              reject(error)
            }
          )
      })
      return promise
    } catch (error) {
      this.handleError(error)
    }
  }

  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);

      this.commonService.unauthorizationRedirect(error);
      return throwError('unauthorized');
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something went wrong, please try again later.');
  }

  isLoggedIn(): Promise<boolean> {
    return new Promise((resolve, reject) => {

      let token = this.fetchAIAuthTokenFromStorage()
      let oldSignedInStatus: boolean = this.isUserSignedIn;
      let userName: string = '';
      let companyName: string = ''
      if (token) {
        this.isUserSignedIn = true
        // userName = token.userId
        // companyName = token.userId
      }
      else {
        this.isUserSignedIn = false
      }
      // Emit logged in status if pevious and current status doesnt match
      if (oldSignedInStatus != this.isUserSignedIn) {
        this.emitChange(this.isUserSignedIn, userName, companyName,'', token.source);
      }
      resolve(this.isUserSignedIn)
    })
  }

  isTokenValid(token: OAuthSessionResponse): boolean {
    try {
      if (token != null) {
        let sessionId = token.session_Id
        let sessionTime = sessionId.substring(token.session_Id.lastIndexOf('_') + 1, sessionId.length);
        if (new Date().getTime() - +sessionTime <= 1500000) {
          return true
        }
      }
      return false
    } catch (error) {
      console.log(error)
    }
  }

  fetchAIAuthTokenFromStorage(): OAuthSessionResponse {
    let tokenString = localStorage.getItem('qbo_session')
    if (tokenString != null || tokenString != undefined) {
      let token: OAuthSessionResponse = JSON.parse(tokenString)
      if (this.isTokenValid(token)) {
        token.session_Id = token.session_Id.substring(0, token.session_Id.lastIndexOf('_'));
        if(token.avat !="" && token.avat != null)
          token.avat = token.avat.substring(0, token.avat.lastIndexOf('_'));
        if(token.curSen !="" && token.curSen != null)
          token.curSen = token.curSen.substring(0, token.curSen.lastIndexOf('_'));
        return token;
      }
      else {
        this.removeAIAuthTokenFromStorage()
      }
    }
    return null
  }

  removeAIAuthTokenFromStorage() {
    localStorage.removeItem('qbo_session')
  }

  expireJwtToken(self: boolean, expireTokens: ExpireTokens[], authToken: string): Observable<any> {
    try {
      let url = `${this.oAuthBaseUrl}/Logout/${self}`;
      const headers = new HttpHeaders().set('Authorization', `Bearer ${authToken}`)
      return this.http.post<any>(url, expireTokens, { }).pipe(catchError((e: any) => this.handleError(e)));

    } catch (error) {
      this.handleError(error)
    }
  }

  expireJwtTokenAsync(self, expireTokens, isSignInPage){
    let token = this.fetchAIAuthTokenFromStorage();
    if(token?.session_Id != null){
      this.expireJwtToken(self,expireTokens, token.session_Id).subscribe(
        (model: any) => {
         // window.close();
        },
        (error: any) => { console.log(<any>error); this.spinnerService.hide() },
        () => { }
      );
    }
  }

  logOut(self, expireTokens, isSignInPage){
    this.expireJwtTokenAsync(self, expireTokens, isSignInPage);
  }

  signoutRedirect(onSignInPage): Promise<any> {
    //add logic to terminate session and redirect to login page
    let promise = new Promise<string>((resolve, reject) => {
      try {
        this.removeAIAuthTokenFromStorage();
        this.isUserSignedIn = false
        this.emitChange(this.isUserSignedIn, "", "","");
        if(!onSignInPage)
          this.router.navigate(['/learnmore']);
        resolve('')
      } catch (error) {
        reject(error)
      }
    });
    return promise;
  }

  //Event emitters to emit changes as observable. Subcribing components can listen to the changes
  private emitChangeSource = new Subject<{ isLoggedIn: boolean, userName: string, companyName: string, userType: string, source: string }>();
  changeEmitted$ = this.emitChangeSource.asObservable();

  emitChange(isLoggedIn: boolean, userName: string = '', companyName: string = '',userType: string = '', source: string = '') {
    this.emitChangeSource.next({ isLoggedIn: isLoggedIn, userName: userName, companyName: companyName,userType: userType, source: source });
  }

  disconnectQBO(userId: string, authToken: string): Promise<any> {
    try {
      let promise = new Promise((resolve, reject) => {
        let url = `${this.oAuthBaseUrl}/${userId}`
        const headers = new HttpHeaders().set('Authorization', `Bearer ${authToken}`)
        this.http.delete(url, { headers: headers }).toPromise().then(() => {
          resolve(true)
        },
          error => {
            this.handleError(error)
            reject(error)
          })
      })

      return promise
    } catch (error) {
      this.handleError(error)
    }
  }

  disconnectQBOfromApp(realmId: string): Promise<any> {
    try {
      let promise = new Promise((resolve, reject) => {
        let url = `${this.oAuthBaseUrl}/Disconnect/${realmId}`
        //const headers = new HttpHeaders().set('Authorization', `Bearer ${authToken}`)
        this.http.delete(url, { }).toPromise().then(() => {
          resolve(true)
        },
          error => {
            this.handleError(error)
            reject(error)
          })
      })

      return promise
    } catch (error) {
      this.handleError(error)
    }
  }

  //App health function
  public getAppHealthStatus(): Promise<string> {

    try {
      let promise = new Promise<string>((resolve, reject) => {
        this.http.get(this.appHealthCheckBaseurl, { responseType: 'text' })
          .toPromise()
          .then(() => {
            resolve("OK")
          },
            error => {
              this.handleError(error)
              reject("FAIL")
            })
      })
      return promise


    } catch (error) {
      this.handleError(error)
    }
  }

  public getUpdatedConfigInfo(realmId: string): Promise<any> {
    let promise = new Promise<any>((resolve,reject) =>{
      try {
        const tokenString = localStorage.getItem('qbo_session');
        if (tokenString != null || tokenString != undefined) {
          var token: OAuthSessionResponse = JSON.parse(tokenString);
          let authToken = token.session_Id.substring(0, token.session_Id.lastIndexOf('_'));
          const headers = new HttpHeaders().set('Authorization', `Bearer ${authToken}`)
          this.http.get<any>(`${this.intuitApiUrl}/getConfiguration/${realmId}`, {headers: headers}).toPromise()
          .then(
            res => resolve (res),
            error => reject(error)
          )
        }
      } catch (error) {
        reject(error)
      }
    })
    return promise
  }

}
