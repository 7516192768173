import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ConnectorModule } from './Connector/connector.module';
import { SharedModule } from './shared/shared.module';
import {HttpClientModule} from '@angular/common/http';
import { AuthCallbackComponent } from './auth-callback/auth-callback.component';
import { AppConfig } from './app.config';
import { ApphealthComponent } from './apphealth/apphealth.component';
import { AdminSigninComponent } from './adminsign/adminsign.component';
import { CustomersComponent } from './Connector/customers/customers.component';
import { ReconciliationComponent } from './Connector/reconciliation/reconciliation.component'
import { ItemManagementComponent } from './Connector/itemmanagement/itemmanagement.component'
import { ExemptionComponent } from './Connector/exemption/exemption.component'
import { IntegrationComponent } from './Connector/integration/integration.component'
import { NgbModule, NgbTypeaheadModule, NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import { DataTablesModule } from 'angular-datatables';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BatchAddressComponent } from './Connector/batchaddress/batchaddress.component';
import { SignOutComponent } from './Connector/signout/signout.component';
import { ToggleButtonComponent } from './shared/tools/toggle-button.component';
import { OnBoardingComponent } from './Connector/on-boarding/on-boarding.component';
import { UtilitiesComponent } from './utilities/utilities.component';


export function initializeApp(appConfig: AppConfig) {
  return () => appConfig.load();
}
@NgModule({
  declarations: [
    AppComponent,
    AuthCallbackComponent,
    ApphealthComponent,
    ReconciliationComponent,
    ItemManagementComponent,
    ExemptionComponent,
    IntegrationComponent,
    AdminSigninComponent,
    CustomersComponent,
    BatchAddressComponent,
    SignOutComponent,
    ToggleButtonComponent,
    OnBoardingComponent,
    UtilitiesComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ConnectorModule,
    SharedModule,
    HttpClientModule,
    NgbModule,
    NgbTypeaheadModule,
    NgbTooltipModule,
    DataTablesModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [
    AppConfig,
    { provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppConfig], multi: true }
    ],
  bootstrap: [AppComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AppModule { }
