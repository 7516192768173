import { Component, OnInit, ViewEncapsulation, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser'
import { AuthenticationService } from '../../shared/services/authentication.service';
import { ActivatedRoute } from "@angular/router"
import { AppConfig } from '../../app.config';
import { OnBoardingService } from 'src/app/shared/services/on-boarding.service';

@Component({
    selector: 'app-learnmore',
    templateUrl: './learnmore.component.html',
    styleUrls: ['./learnmore.component.css'],
    encapsulation: ViewEncapsulation.None
  })

  export class LearnMoreComponent implements OnInit  {
    
    learMoreVideoLink: any
    sourceParam: string;

    constructor(private authService: AuthenticationService, private route: ActivatedRoute,
       private sanitizer: DomSanitizer,private onBoardingService: OnBoardingService) { 
      this.learMoreVideoLink = this.sanitizer.bypassSecurityTrustResourceUrl(AppConfig.settings.externalUrls.learnMore_VideoLink);
    }

    ngOnInit() {    
      window.close();
      this.sourceParam = this.route.snapshot.paramMap.get("source");
      this.sourceParam = this.sourceParam == null ? "" : this.sourceParam;
  
      if (this.sourceParam != "") {
        $("#connectorHeader").hide();
        $("#dvLoginDetail").hide();
        $("#avaHeader").show();
        $("#avaHeader-content").show();
      }
      
      let token = localStorage.getItem('qbo_session')
        if (token != null || token != undefined) {
          localStorage.removeItem("qbo_session");
        }
    }
    
    signIn(): void {
      try {
        this.onBoardingService.removeOnboarding();
        this.authService.startAuthentication();
      } catch (error) { console.log(error); }
    }

    getLearMoreSupportLink() {
      window.open(AppConfig.settings.externalUrls.learnMore_Support, '_blank').focus();
    }

    getLearMoreGetStartedLink() {
      window.open(AppConfig.settings.externalUrls.learnMore_GetStarted, '_blank').focus();
    }

  }

