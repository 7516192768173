import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router"
import { AuthenticationService } from '../shared/services/authentication.service';
import { OAuthSessionResponse } from '../shared/models/OAuthToken';
import { AIAuthTokenRequest } from '../shared/models/AIAuthToken';
import { SpinnerService } from '../shared/spinner/spinner.service';
import { OnBoardingService } from '../shared/services/on-boarding.service';
import { AppConfig } from '../app.config';

@Component({
  selector: 'ai-app-auth-callback',
  templateUrl: './ai-auth-callback.component.html',
  styleUrls: ['./ai-auth-callback.component.css']
})
export class AIAuthCallbackComponent implements OnInit {

  private session_state: string;
  private state: string;
  private scope: string;
  private id_token: string;
  private code: string;

  constructor(private authService: AuthenticationService, private router: Router, 
    private route: ActivatedRoute, private spinnerService: SpinnerService,
    private onBoardingService: OnBoardingService) { }

  ngOnInit() {
    this.route.fragment.subscribe(fragment => {
      if (fragment) {
        console.log("fragment:", fragment);
        var hashFragment = fragment;
        var obj = {};

        hashFragment.split('&').forEach(function (elem) {
          var parts = elem.split('=');
          obj[parts[0]] = parts[1];
        });
        this.code = obj['code']
        this.id_token = obj['id_token']
        this.scope = obj['scope']
        this.state = obj['state']
        this.session_state = obj['session_state']
      }
    })

    if (this.route.snapshot.queryParamMap.get('code') != undefined && this.route.snapshot.queryParamMap.get('code') != null) {
      this.code = this.route.snapshot.queryParamMap.get('code');
      this.id_token = this.route.snapshot.queryParamMap.get('id_token');
      this.scope = this.route.snapshot.queryParamMap.get('scope');
      this.state = this.route.snapshot.queryParamMap.get('state');
      this.session_state = this.route.snapshot.queryParamMap.get('session_state');
    }

    if (this.code != null && this.session_state != null && this.state != null && this.scope != null) {
      this.spinnerService.show();
      let realmId = "";
      let tokenString = localStorage.getItem('qbo_session')
      if (tokenString != null || tokenString != undefined) {
        let token: OAuthSessionResponse = JSON.parse(tokenString)
        realmId = token.realmId;
      }

      let tokenReq: AIAuthTokenRequest = {
        code: this.code,
        id_token: this.id_token,
        session_state: this.session_state,
        state: this.state,
        scope: this.scope,
        realmId: realmId
      }

      this.authService.afterAIAuth(tokenReq).then((resp) => {
        if (this.onBoardingService.isCompleted()) {
          this.onBoardingService.removeOnboarding();
        }
        else if (this.onBoardingService.isAIStarted()) {
          localStorage.removeItem("realmId");
          this.onBoardingService.setAISuccessStatus();
          this.onBoardingService.startIntegration();
        }
        else if (this.onBoardingService.isIntegrationStarted()) {
          this.onBoardingService.setIntegrationSuccessStatus();
          this.onBoardingService.startCompletion();
        }
        else if(resp == 'company-validation-error'){
          alert("Unauthorized user attempting to access the ERP company. Please login from the Avalara portal.");

          var win = window.open(AppConfig.settings.avatax.portal_integration_url, "_self");
          win.top.close();
        }
        else if (resp == 'window-close') {
          window.parent.location.href = "/integration";
          window.close();
          window.open('', '_self').opener.location.href = "/integration";
        }
        else if (resp == 'true') {
          window.location.href = "/home";
        }
        else {
          window.location.href = "/learnmore";
          alert("Avalara authentication is failed, please try again with correct credentials.");
        }
      });
    }
  }
}
