<s-container>
    <br>
    <s-row>
        <s-col span="sm-12 lg-12 12" style="padding: 0px 105px;
        margin-top: 30px;">
            <form [formGroup]="avataxloginSettingForm" (ngSubmit)="onSubmit()">
                <s-row style="height:265px;">
                    <s-col span="sm-12 lg-6 6" style="background-color: #e8e8e8;padding-bottom: 0px;">
                        <div class="small-6 columns padded-landing-content padding-avalara-Integration"
                            style="padding-left:35px;padding-right:35px;">
                            <!-- <div class="row padding-controls-Top"> -->
                            <div class="row" style="padding-top:30px;">
                                <span style="color:#8F8F93;font-size:18px;">New to Avalara AvaTax? </span><br />
                            </div>
                            <div class="row" style="padding-top:25px;padding-left:00px;">
                                <input type="button" (click)="getBuyTechMarketingUrl()" value="Learn More" style="width:140px"
                                    class="button primary" />
                            </div>
                        </div>
                    </s-col>
                    <s-col span="sm-12 lg-6 6" style="background-color: #f5f5f5;padding-bottom: 0px;">
                        <div class="small-6 columns padded-landing-content padding-avalara-Integration"
                            style="padding-left:35px;padding-right:35px;">
                            <!-- <div class="row padding-controls-Top"> -->
                            <div class="row" style="padding-top:30px;">

                                <span style="color:#8F8F93;font-size:18px;">Please sign in with AvaTax
                                    Account</span><br />
                                
                                    <br>
                                <button AutomationAttribute="Ava_AILogIn_Button" tabindex="-1" (click)="AILoginIn()"
                                    class="primary" id="btnAISave">
                                    <span class="button-text">
                                        AvaTax SignIn
                                    </span>
                                </button>
                            </div>
                        </div>
                    </s-col>
                </s-row>
            </form>
        </s-col>
    </s-row>
</s-container>