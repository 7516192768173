<s-container>
    <div class="header-learnmore">
      <a routerLink="." (click)="getLearMoreSupportLink()">Support</a>  
    </div>
    <div class="header-learnmore-buttons">   
        <a routerLink="." (click)="getLearMoreGetStartedLink()" class="button secondary get-started-btn">Get Started</a>
        <!-- <a class="button primary signin" (click)='signIn()'></a> -->

        <button class="button primary signin" AutomationAttribute="Ava_ConnectToQBO_Button" tabindex="-1"
                    id="btnSignIn" (click)='signIn()'>Sign In</button>
    </div>
    <s-row>
        <br> <br>
    </s-row>
    <s-row>
        <s-col span="sm-12 lg-12 12" class="learnmore-container">
            <s-row>
                <s-col span="sm-12 lg-5 5">
                    <img src="/assets/images/quickbooks-avalara-logo.svg" width="300px"  />
                    <h1 style="line-height: 2.55rem;font-weight: 700;"> Sales tax<br>integration for<br>QuickBooks </h1>
                    <div style="font-size: 18px;">
                        Calculate tax, address validation,<br>
                        manage exemption certificates,<br>
                        and more, right from the<br>
                        accounting software
                    </div>
                    <br>
                </s-col>
                
                <s-col span="sm-12 lg-7 7" class="videoIframeContainer">
                    <iframe class="videoHomepage videoIframe" [src]="learMoreVideoLink"></iframe>
                </s-col>
            </s-row>
        </s-col>
    </s-row>
    
</s-container>
