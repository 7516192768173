<s-container>
    <form novalidate id="frmBatchAddress">

        <a [routerLink]="[ '/home']" *ngIf="this.sourceParam==''">
            &#8592; back
        </a>

        <div class="row">
            <h2 class="page-title header-title"> Bulk Address Validation </h2>
        </div>
        <br>
        <div style="padding:0px;">
            <s-row>
                <s-col span="3" class="s-col-padding-bottom-08">
                    <button tabindex="-1" class="primary" (click)="SendForAddressValidation()" AutomationAttribute="BatchAddress_Button_SendToSync">
                        <span class="button-text">
                            Validate Address(es)
                        </span>
                    </button>
                    <a class="text-underline refresh-data" *ngIf="isCustomerList" (click)="searchQboCustomers()">
                        <s-icon name="refresh"></s-icon> &nbsp;Refresh data
                    </a>
                </s-col>
                <s-col span="4" class="filter-item s-col-padding-bottom-08">
                    <label>
                        <div class="label-search-title">Search: </div>
                        
                        <input type="text" id="filterCustomerText" name="filterCustomerText" class="search-text" [(ngModel)]="filterCustomerText" > 
                            

                        <button class="secondary search-button"  (click)="filterCustomer('filter')"
                            AutomationAttribute="BulkAddress_Button_filterItems">
                            <s-icon name="search"></s-icon>
                        </button>
                    </label>
                </s-col>
                <s-col span="5" class="text-right s-col-padding-bottom-08">

                    Filters - &nbsp;&nbsp;

                    <button class="secondary" (click)="filterCustomer('Validated')"
                    [ngClass]="{'statusBtn': statusClass=='Success'}"  AutomationAttribute="BulkAddress_Button_filterItems_Validated">
                        <span class="button-text">
                            Validated Address
                        </span>
                    </button>
                    <button class="secondary margin-left-0" (click)="filterCustomer('Not Validated')" 
                    [ngClass]="{'statusBtn': statusClass=='Pending'}"  AutomationAttribute="BulkAddress_Button_filterItems_Pending">
                        <span class="button-text">
                            Not Validated 
                        </span>
                    </button>
                    <button class="secondary margin-left-0" (click)="filterCustomer('Validation Failed')" 
                    [ngClass]="{'statusBtn': statusClass=='Failed'}"  AutomationAttribute="BulkAddress_Button_filterItems_Failed">
                        <span class="button-text">
                            Validation Failed
                        </span>
                    </button>

                </s-col>
            </s-row>
            <s-row>
                <s-col>
                    <div class="row table-outer">
                        <table datatable #dt1 class="card-table" [dtOptions]="dtOptions[0]" [dtTrigger]="dtTrigger">
                            <thead>
                                <tr>
                                    <th width="10%">
                                        <label>
                                            <input type="checkbox" [checked]="isSelectAllChecked" (change)="selectAll($event)" />
                                            <span class="checkbox-select-text"> SELECT</span>
                                        </label>
                                    </th>
                                    <th width="25%">Customer Name</th>
                                    <th width="25%">Company</th>
                                    <th width="25%">Address</th>
                                    <th class="text-align-center" width="25%">Validation Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="row-collapse" *ngFor="let data of qboCustomerList">
                                    <td class="checkbox-chkall">
                                        <fieldset role="group" aria-labelledby="label4">
                                            <input type="checkbox" id="chkAll" name="chkAll" [checked]="data.isSelected"
                                            (change)="selectCustomerValue($event.target);"
                                            value="{{data.id}}" />
                                        </fieldset>
                                    </td>
                                    <td class="vertical-align-top">{{data.customerName}}</td>
                                    <td class="vertical-align-top">{{data.companyName}}</td>
                                    <td class="vertical-align-top">{{data.customerAddress}}</td>
                                    <td class="status-box">
                                        <span class="status-{{removeSpace(data.status)}}">{{data.status}}</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </s-col>
            </s-row>
        </div>
    </form>
</s-container>