<link rel="Stylesheet" type="text/css" href="//plugin-qbo.intuitcdn.net/harmony-ui-components/23.0.18/dist/css/hui.min.css" />

<div class="tableRow" style="float: right" id="avalaraOuterDiv">
  <div class="gridButtons" style="display: block" id="avaTax_div">
      <div style="float: right; margin-left: 5px; display: block">
          <div *ngIf="ShowErrorButton" style="float: left" id="showAvaTaxErrorBtnDiv">
              <button id="showAvaTaxErrorBtn" (click)="showAvaTaxError()" class="button ha-button mini"
                      type="button" style="width: auto !important; font-weight: bold; color: red !important;">
                  View AvaTax Error
              </button>
          </div>
          <div  *ngIf="ShowEstimateTaxButton" style="float: left; margin-left: 5px;" id ="showEstimateTaxBtnDiv">
              <button id="estimateTaxBtn" (click)="estimateTax()" class="button ha-button mini btnEstimateTax" type="button">
                  Estimate Tax
              </button>
          </div>
      </div>
  </div>
  <div style="width: 100%; text-align: left; display: none; margin-top: 10px;" id="avaTax_loading_div">
      <span>AvaTax is loading.....</span>
      <div>
          <img src="../../Images/ajax-loader_doc.gif" style="padding-left: 40px; text-align: center" />
      </div>
  </div>
  <div style="width: 100%; text-align: left; display: none; color: Red" id="avaTax_Error_div">
      <div>
          <br />
          <span>AvaTax configuration is not found</span>
      </div>
  </div>
</div>

