export class AvaTaxCompany {
    companyCode : string
    companyName : string
    isActive : boolean
}

export class HomeConfigLanding 
{
    isEnableAddressValidation : boolean
    status : string
    editConfig : boolean
    avaTaxUserName : string
    qboCompanyName : string
   

}

export class companyLinked{
    isSubmitted : boolean
}

export class linkCompanyBody{
    companyCode : string
}

export class userStatusBody{
    status : boolean
}

export class calculationStatusBody{
    calculationStatus : string
}
