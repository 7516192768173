import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import { AdminQboCustomer, customerConfiguration } from '../models/customerModel';
import { configurationModel } from '../models/ConfigurationModel';
import { userStatusBody } from '../models/avataxcompany';
import { userCredentials } from '../models/UserCredentials';
import { AvaTaxAccountValidationResponse } from '../models/AvaTaxAccountValidationResponse';
import { IHttpService } from '../../shared/services/IHttpService';
import { AppConfig } from '../../app.config';
import { Constants } from '../../shared/models/Constants';
import { CommonService } from '../services/common.service';

@Injectable({
    providedIn: 'root'
})
export class CustomerService implements IHttpService{
    private customerBaseUrl =  `${AppConfig.settings.avatax.api_base_url}/api/Admin`;

    constructor(private http: HttpClient, private commonService: CommonService) { }
 
    getQboCustomers(authToken:string):Observable<AdminQboCustomer[]>{
        const url = `${this.customerBaseUrl}/qboCustomers`;
        const headers = new HttpHeaders().set('Authorization', `Bearer ${authToken}`)
        return this.http.get<AdminQboCustomer[]>(url,{headers: headers}).pipe(catchError((e:any)=>this.handleError(e)));
    }

    disconnectCustomer(id:string, authToken:string):Observable<any>{
      const url = `${this.customerBaseUrl}/disconnect/${id}`;
      const headers = new HttpHeaders().set('Authorization', `Bearer ${authToken}`)
      return this.http.delete<any>(url,{headers: headers}).pipe(catchError((e:any)=>this.handleError(e)));
    }

    getConfiguration(id:string, authToken:string):Observable<any> {
      const url = `${this.customerBaseUrl}/config/${id}`;
      const headers = new HttpHeaders().set('Authorization', `Bearer ${authToken}`)
      return this.http.get<any>(url,{headers: headers}).pipe(catchError((e:any)=>this.handleError(e)));
    }

    saveConfiguration(customerConfiguration : customerConfiguration, authToken:string): Observable<any>{
      const url = `${this.customerBaseUrl}/config`;
      const headers = new HttpHeaders().set('Authorization', `Bearer ${authToken}`)
      return this.http.put(url,customerConfiguration,{headers: headers}).pipe(catchError((e:any)=>this.handleError(e)));
    }

      handleError(error: HttpErrorResponse) {

        if(error.status === 401 || error.status === 403) {
          window.location.href = Constants.OktaSignOutUrl;
        }

        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('An error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          // The response body may contain clues as to what went wrong,
          console.error(
            `Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);

          this.commonService.unauthorizationRedirect(error);
          return throwError('unauthorized');
        }
        // return an observable with a user-facing error message
        return throwError(
          'Something went wrong, please try again later.');
      };
}