import { Pipe, PipeTransform } from '@angular/core';
import { AdditionalNexuses } from '../models/NexusSetup';

@Pipe({
    name: 'nexusWhereContent'
})
export class NexusWhereContent implements PipeTransform{
    transform(value:AdditionalNexuses): string{
          return ((value.stateName !== null || value.stateName !== '') ? value.stateName : value.stateCode) + ', ' + ((value.countryName !== null && value.countryName !== '') ? value.countryName : value.countryCode);  
    }
}