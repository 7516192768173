import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import { AvaTaxCompany,companyLinked,linkCompanyBody} from '../models/avataxcompany';
 
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { AuthGuard } from 'src/app/shared/gaurds/auth.gaurds';
@Component({
  selector: 'app-linkcompany',
  templateUrl: './linkcompany.component.html',
  styleUrls: ['./linkcompany.component.css']
})

export class LinkCompanyComponent  implements OnInit {
  linkCompanyForm: FormGroup; 
  linkedCompanyData : linkCompanyBody = new linkCompanyBody;
  avaTaxCompanies : AvaTaxCompany[] = [];
  isCompanyPresent : boolean = false;
  errorMessage : any;
 
  constructor(private fb: FormBuilder, private authService: AuthenticationService,private router : Router,private authGuard : AuthGuard) { }

  ngOnInit() {
    let token = this.authService.fetchAIAuthTokenFromStorage()
    if(token){
      this.initiliazeFormModel();
      this.GetAvaTaxCompanies();
    }
  }

  GetAvaTaxCompanies(){
    try {
      let token = this.authService.fetchAIAuthTokenFromStorage()
      if(token){
        this.authGuard.canActivate().then((res)=>{
          this.authService.fetchUserAvaTaxCompanies(token.userId,token.session_Id).subscribe(
            (result: AvaTaxCompany[]) => this.displayAvaTaxCompanies(result),
            (error: any) => {
              this.errorMessage = <any>error; 
              if(this.errorMessage != "unauthorized")
                alert(this.errorMessage);
              },
            ()=>{}
           );
      });
      }
    } 
    catch (error) {
      console.error(error.message)
    }  
  }

  displayAvaTaxCompanies(avaTaxCompaniesList: AvaTaxCompany[]) : void {
   
      if(avaTaxCompaniesList.length > 1)
      {
        this.isCompanyPresent = true;
        this.avaTaxCompanies = avaTaxCompaniesList;
        this.linkCompanyForm.patchValue({
          AvaTaxCompanyCode: avaTaxCompaniesList[0].companyCode,
        })
      } 
      else if(avaTaxCompaniesList.length  == 1)
      {
        this.authService.isLoggedIn().then(value=>{
          if(value){
            this.router.navigate(['/home']);
          }
        })
      }
      else if(avaTaxCompaniesList == null || avaTaxCompaniesList.length  == 0)
      {
        this.isCompanyPresent = false;
      }
        
  }

  initiliazeFormModel(){
    this.linkCompanyForm = this.fb.group({
      AvaTaxCompanyCode : [{value : '', disabled : false},Validators.required],
    });
  }

  onSubmit(){
    if (this.linkCompanyForm.valid) {
      console.log("Form Submitted!");
      let configSettings : linkCompanyBody
      configSettings = this.getSelectedCompanySettings();
      this.submitLinkedCompany(configSettings)
    }
  }

  private getSelectedCompanySettings(): linkCompanyBody{
     this.linkedCompanyData.companyCode = this.linkCompanyForm.get('AvaTaxCompanyCode').value
     return this.linkedCompanyData
  }

 submitLinkedCompany(selectedCompanyCode: linkCompanyBody){
 try {
      let token = this.authService.fetchAIAuthTokenFromStorage()
      if(token){
        this.authGuard.canActivate().then((res)=>{
          this.authService.submitAvaTaxCompanyCode(token.userId,token.session_Id, selectedCompanyCode).subscribe(
            (companyLinked: companyLinked) => this.gotoHomePage(companyLinked),
            (error: any) => {this.errorMessage = <any>error; 
              if(this.errorMessage != "unauthorized")
                alert(this.errorMessage);},
            ()=>{}
           );
      });
      }
    } 
    catch (error) {
      console.error(error.message)
    }  
  }

  gotoHomePage(companyLinked: companyLinked) : void {
    if(companyLinked)
    {
      this.authService.isLoggedIn().then(value=>{
        if(value){
          this.router.navigate(['/home']);
        }
      })
    } 
  }
}