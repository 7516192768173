
export class userCredentials {
  username: string;
  password: string;
}

export class isAuthorizeResponse {
  status: boolean;
  avaTaxUserName: string;
  qboCompanyName: string;
  errorMessage: string;
}