import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {

  constructor() { }
  private _spinnerSubject = new Subject<ISpinnerState>();

  spinnerState = this._spinnerSubject.asObservable();

  show() {
    this._spinnerSubject.next(<ISpinnerState>{ show: true });
  }

  hide() {
    this._spinnerSubject.next(<ISpinnerState>{ show: false });
  }
}

export interface ISpinnerState {
  show: boolean
}
