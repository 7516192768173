import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OnBoardingStatus } from 'src/app/shared/enums/on-boarding-status';
import { OnboardingDetails } from 'src/app/shared/models/Onboarding';
import { OnBoardingService } from 'src/app/shared/services/on-boarding.service';

@Component({
  selector: 'app-on-boarding',
  templateUrl: './on-boarding.component.html',
  styleUrls: ['./on-boarding.component.css']
})
export class OnBoardingComponent implements OnInit {

  constructor(private route: ActivatedRoute, private onBoardingService: OnBoardingService) { }

  ngOnInit(): void {

    localStorage.removeItem("qbo_session");
    localStorage.removeItem("realmId");

    const connectorId = this.route.snapshot.queryParamMap.get('connectorId');
    const accountId = this.route.snapshot.queryParamMap.get('accountId');
    const avataxCompanyId = this.route.snapshot.queryParamMap.get('avataxCompanyId');
    
    if (connectorId && accountId && avataxCompanyId) {
      const onboardingDetails: OnboardingDetails = {
        connectorId: connectorId,
        accountId: accountId,
        avataxCompanyId: avataxCompanyId,
        status: OnBoardingStatus.NotStarted,
        success: false
      };
      this.onBoardingService.startAI(onboardingDetails);
    } else {
      const msgJson: OnboardingDetails = { connectorId: connectorId, accountId: accountId, avataxCompanyId: avataxCompanyId, status: OnBoardingStatus.NotStarted, success: false };
      this.onBoardingService.postMesssageToParent(JSON.stringify(msgJson));
    }

  }
}
