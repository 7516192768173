<main>
    <s-container>
        <s-row style="padding-top: 20%">
            <s-col span="12" class="text-center">
                <span class="blink">
                    <img src="/assets/images/ava-logo.png" width="100px" height="100px" />
                    <h1 style="line-height: 2.55rem;font-weight: 700">Initiating QuickBooks company integration</h1>
                </span>
            </s-col>
        </s-row>
    </s-container>
</main>