<s-container>

    <s-row style="padding: 3px 105px;">
        <s-col span="sm-12 lg-12 12">

            <form [formGroup]="linkCompanyForm" (ngSubmit)="onSubmit()">
                <div class="row" style="padding-top: 35px;">
                    <div class="small-12 column">
                        <h2 class="page-title"> Let's make sure we setup the correct Tax Profile for your transactions
                        </h2>
                    </div>
                </div>

                <s-row>
                    <s-col span="sm-12 lg-12 12" style="background-color: #f5f5f5; padding-top: 20px;
                    padding-bottom: 20px;">

                        <div class="row">
                            <div class="small-12 column">
                                <span class="pglargetextleftpanel" style="font-size:16px;">
                                    AvaTax found the following companies created in your Avalara account. Select one
                                    of these companies to record the transactions originating from QuickBooks Online.
                                </span>
                            </div>
                        </div>
                        <div class="row" style="padding-top:25px;">
                            <div class="col-md-2 column">
                                <label class="control-label">Select company to link -</label>
                            </div>
                            <div class="col-md-3 column" style="margin-right: 40%;">
                                <select id="_AvaTaxCompanyCode" AutomationAttribute="Ava_LinkCompanyPage_DropDown_SelectCompany"
                                    formControlName="AvaTaxCompanyCode" class="form-control">
                                    <option *ngFor="let company of avaTaxCompanies" value={{company.companyCode}}>
                                        {{company.companyName}}
                                    </option>
                                </select>
                                <span *ngIf="!isCompanyPresent"
                                    style="margin-top:-25px;font-size:smaller;color: rgb(197, 28, 28);margin-left: 0px;">Company
                                    Not Present</span>
                            </div>
        
                        </div>
                        <div class="row" style="padding-top:25px !important">
                            <button type="submit" AutomationAttribute="Ava_LinkCompanyPage_Button_LinkCompany"
                                [disabled]="linkCompanyForm.invalid" class="primary" id="btnSave">
                                <span class="button-text">
                                    Link Company
                                </span>
                            </button>
                        </div>


                    </s-col>
                </s-row>

               
                <div class="row">
                    <div class="col-md-1">
                        <div class="form-group field-validation-error">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="small-12 column">
                        <div id="dv_inactive_company" class="Existing-User-Inactive-Company"
                            style="color:Red;text-align:justify">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="small-12 column">
                        <div id="div_partial_LinkCompany">

                            <span class="pglargetextleftpanel" style="font-size:16px;"></span>
                        </div>

                    </div>
                </div>
            </form>

        </s-col>
    </s-row>
</s-container>