import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
    providedIn: 'root'
})
export class DialogService {

    constructor(@Inject(DOCUMENT) private document: Document) { }

    showDialogBox(id, message = ''){
        let messageBox = this.document.getElementById(id);
          if(typeof messageBox['showModal'] === 'function') {
            if(message != ''){
                // @ts-ignore: dynamic method
                this.document.getElementById(id).getElementsByClassName('dialog-msg-content').value = message;
            }
            // @ts-ignore: dynamic method
            messageBox.showModal();
          }
    }

    hideDialogBox(id){
        let messageBox = this.document.getElementById(id);
          if(typeof messageBox['close'] === 'function') {
            // @ts-ignore: dynamic method
            messageBox.close();
          }
    }
}