<div class="disconnectedPage">
  <div class="page-container">
    <h1>QuickBooks Disconnected</h1>

    <div class="contained-bg">
      <p>
        Your QuickBooks integration has been disconnected. As a result, you will no longer be able to send invoices to Avalara.
      </p>
      <p>
        If you wish to re-establish the connection between Avalara and your QuickBooks account, please refer to our help guide <a (click)="getReconnectDocumentation()">here</a>.
      </p>
      <p>
        For general Avalara support, kindly reach out to your <a (click)="getAvalaraSupportHelp()">Account Manager.</a>
      </p>
    </div>
  </div>
</div>
