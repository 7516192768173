import { Component, OnInit, Injectable, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { DeactivationGuarded } from 'src/app/shared/gaurds/candeactivate.guard';
import { SpinnerService } from '../../shared/spinner/spinner.service';
import { HttpParams } from '@angular/common/http';
import { DataTableDirective } from "angular-datatables";
import { AdminQboCustomer, configurationModel, customerConfiguration } from '../models/customerModel';
import { AvaTaxCompany, userStatusBody } from '../models/avataxcompany';
import { salesTaxItem } from '../models/QBOUsers';
import { OAuthSessionResponse } from '../../shared/models/OAuthToken';
import { AuthenticationService } from '../../shared/services/authentication.service';

import { CustomerService } from '../services/customers.service'
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-admincustomers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.css']
})
export class CustomersComponent implements OnInit {

  errorMessage: any;
  showAlert: boolean = false
  alertMessage: string
  alertCssClass: string;

  oAuthToken: OAuthSessionResponse;

  @ViewChildren(DataTableDirective)
  dtElements: QueryList<DataTableDirective>;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  qboCustomers: AdminQboCustomer[] = [];

  closeResult: string;
  configForm: FormGroup;
  configModel: configurationModel;
  avaTaxCompanies: AvaTaxCompany[];
  salesTaxItems: salesTaxItem[];

  avaTaxCompanyCode: string;
  avaTaxAccountNumber: string;
  userStatusBody: userStatusBody = new userStatusBody;
  isDisconnected: boolean = false;
  apiThrottleTimeStamp: string = "";
  isApiThrottleExceeded: boolean = false;

  constructor(
    private fb: FormBuilder,
    private modalService: NgbModal,
    private router: Router,
    private authService: AuthenticationService,
    private spinnerService: SpinnerService,
    private customerService: CustomerService,
    private datePipe: DatePipe
  ) {

  }

  ngOnInit() {
    this.oAuthToken = this.authService.fetchAIAuthTokenFromStorage();
    if (this.oAuthToken) {
      this.initiliazeFormModel();
      this.initializeDatatables();
      this.getCustomers(this.oAuthToken.session_Id);

      let table = $('#DataTables_Table_0').DataTable({
        drawCallback: () => {
          $('.paginate_button.next').on('click', () => {

          });
        }
      });
    }
    else {
      alert('Session is timed out. Please log in again.')
      this.router.navigate(['/admin/signin']);
    }
  }


  initiliazeFormModel() {
    this.configForm = this.fb.group({
      IsEcoSystemDisabled: [{ value: true, disabled: false }],
      IsEnableAddressValidation: [{ value: true, disabled: false }],
      IsMakeAllCustomerTaxable: [{ value: true, disabled: false }],
      SalesTaxItem: [{ value: '', disabled: false }, Validators.required],
      IsUserActive: [{ value: true, disabled: false }],
      IsApiThrottleExceeded: [{ value: true, disabled: false }]
    });
  }


  initializeDatatables() {
    this.dtOptions = {
      retrieve: true,
      paging: true,
      lengthChange: true,
      searching: true,
      pageLength: 10,
      columnDefs: [{ targets: 3, orderable: false }],
      pagingType: 'simple_numbers',
      order: [[1, 'desc']],
      serverSide: false,
      processing: false,
    }
  }

  objectToHttpParams(obj: any) {
    return Object.entries(obj || {}).reduce((params, [key, value]) => {
      return params.set(
        key,
        value === 'object' ? JSON.stringify(value) : String(value)
      );
    }, new HttpParams());
  }

  getCustomers(oAuthToken): void {
    try {
      var current = this;
      this.spinnerService.show();
      this.customerService.getQboCustomers(oAuthToken).subscribe(
        (model: AdminQboCustomer[]) => {
          current.qboCustomers = model;
          current.rerender();

          this.spinnerService.hide();
        },
        (error: any) => {
          this.errorMessage = <any>error;
          this.spinnerService.hide();
          if(this.errorMessage != "unauthorized")
            alert(this.errorMessage);
        },
        () => { }
      );
    }
    catch (error) {
      console.error(error.message)
      this.spinnerService.hide()
    }
  }

  rerender(): void {
    this.dtElements.forEach((dtElement: DataTableDirective) => {
      dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.clear();
        dtInstance.destroy();
        this.dtTrigger.next();
      });
    });
  }

  ngAfterViewInit() {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  clearConfigForm() {
    this.configForm.reset();
    this.avaTaxCompanyCode = "";
    this.avaTaxAccountNumber = "";
  }



  resetSelected() {
    $('#dvEdtConfig').hide();
    $('#dvCustList').css('width', '100%');

    $("#dvCustList").find('table > tbody tr').toArray().forEach(element => {
      element.classList.remove('row-selected');
    });
  }

  rowSelected(realmId: string) {

    // $('#dvEdtConfig').hide();
    // $('#dvCustList').css('width','100%');

    window.scrollTo(1200, 200);

    $("#dvCustList").find('table > tbody tr').toArray().forEach(element => {
      element.classList.remove('row-selected');
      if (element.children[0].innerHTML.trim() == realmId.trim()) {
        element.classList.add('row-selected');
      }
    });
  }

  getConfig(id: string, realmId: string): void {
    this.oAuthToken = this.authService.fetchAIAuthTokenFromStorage();
    if (this.oAuthToken) {
      setTimeout(() => { this.rowSelected(realmId); }, 20);

      this.clearConfigForm();
      try {
        this.spinnerService.show()
        this.customerService.getConfiguration(id, this.oAuthToken.session_Id).subscribe(
          (configModel: any) => this.displayConfiguration(configModel),
          (error: any) => {
            this.errorMessage = <any>error;
            this.spinnerService.hide();
            if(this.errorMessage != "unauthorized")
              alert(this.errorMessage);
          },
          () => { this.spinnerService.hide() }
        );
      }
      catch (error) {
        console.error(error.message)
        this.spinnerService.hide()
      }
    }
    else {
      alert('Session is timed out. Please log in again.')
      this.router.navigate(['/admin/signin']);
    }
  }

  displayConfiguration(model) {
    if (model) {
      
      $('#dvCustList').css('width', '65%');
      $('#dvEdtConfig').show();

      let configModel = model.configuration;
      let userStatus = model.userStatus == "Active" ? true : false;
      let isApiThrottleExceeded = model.isApiThrottleExceeded;

      this.isApiThrottleExceeded = isApiThrottleExceeded;
      this.apiThrottleTimeStamp = this.datePipe.transform(model.apiThrottleTimeStamp, "MM/dd/yyyy hh:mm a");

      if (configModel) {
        if (this.configForm) {
          this.configForm.reset();
        }

        this.configModel = configModel;
        this.avaTaxCompanies = configModel.avaTaxCompanies;
        this.salesTaxItems = configModel.salesTaxItems;
        this.isDisconnected = model.userStatus == "Disconnected";

        const taxItemControl = this.configForm.get('SalesTaxItem');
        if (this.salesTaxItems)
          taxItemControl.setValidators([Validators.required]);
        else
          taxItemControl.setValidators(null);

        taxItemControl.updateValueAndValidity();

        this.configForm.patchValue({
          IsEnableAddressValidation: this.configModel.configuration.isEnableAddressValidation,
          IsMakeAllCustomerTaxable: this.configModel.configuration.isMakeAllCustomerTaxable,
          IsEcoSystemDisabled: this.configModel.configuration.isEcoSystemDisabled,
          SalesTaxItem: this.configModel.configuration.taxItemId,
          IsUserActive: userStatus,
          IsApiThrottleExceeded: isApiThrottleExceeded
        });

        this.avaTaxCompanyCode = this.configModel.avaTaxDetails.avaTaxCompanyCode;
        this.avaTaxAccountNumber = this.configModel.avaTaxDetails.avaTaxAccountNumber;
      }
    }
  }

  onSubmit() { }

  updateConfig() {
    this.oAuthToken = this.authService.fetchAIAuthTokenFromStorage();
    if (this.oAuthToken) {
      if (this.configForm.valid) {
        console.log("Form Submitted!");
        let customerConfig: customerConfiguration;
        customerConfig = new customerConfiguration();
        customerConfig.configurationModel = new configurationModel();
        customerConfig.userStatusBody = new userStatusBody();

        customerConfig.configurationModel = this.getModifiedConfig();
        customerConfig.userStatusBody.status = this.configForm.get('IsUserActive').value;
        customerConfig.isApiThrottleExceeded = this.configForm.get('IsApiThrottleExceeded').value;
        this.saveCustomerConfiguration(customerConfig);
      }
    }
    else {
      alert('Session is timed out. Please log in again.')
      this.router.navigate(['/admin/signin']);
    }
  }

  private getModifiedConfig(): configurationModel {
    this.configModel.configuration.isEnableAddressValidation = this.configForm.get('IsEnableAddressValidation').value
    this.configModel.configuration.isMakeAllCustomerTaxable = this.configForm.get('IsMakeAllCustomerTaxable').value
    this.configModel.configuration.isEcoSystemDisabled = this.configForm.get('IsEcoSystemDisabled').value
    this.configModel.configuration.taxItemId = this.configForm.get('SalesTaxItem').value
    return this.configModel;
  }

  saveCustomerConfiguration(customerConfiguration: customerConfiguration): void {

    try {
      var current = this;
      this.spinnerService.show()
      this.customerService.saveConfiguration(customerConfiguration, this.oAuthToken.session_Id).subscribe(
        (result: any) => {
          if (result) {
            console.log('Settings Saved');
            this.displayAlert('alert alert-success', "Settings saved successfully !");
          }
          else {
            console.log('Error occurred while saving the setiings');
            this.displayAlert('alert alert-warning', "Error occurred while saving the setiings.");
          }

          current.qboCustomers.forEach(data => {
            if (data.id == customerConfiguration.configurationModel.id) {
              data.status = customerConfiguration.userStatusBody.status ? "Active" : "Down";
            }
          });

          this.spinnerService.hide();
          this.configForm.markAsPristine();
          this.resetSelected();

        },
        (error: any) => { this.errorMessage = <any>error; this.spinnerService.hide(); this.displayAlert('alert alert-danger', this.errorMessage) }
      )
    }
    catch (error) {
      console.error(error.message)
      this.spinnerService.hide()
    }

  }


  disconnectCustomer(id, username) {
    this.oAuthToken = this.authService.fetchAIAuthTokenFromStorage();
    if (this.oAuthToken) {
      var confirmed = confirm("Are you sure you want to disconnect the user - " + username + "?, it can not be connected via Admin panel again.");
      if (confirmed == true) {
        try {
          var current = this;
          this.spinnerService.show();
          this.customerService.disconnectCustomer(id, this.oAuthToken.session_Id).subscribe(
            (result: any) => {
              if (result) {
                console.log('Customer is disconnected successfully!');
                this.displayAlert('alert alert-success', "Customer is disconnected successfully!");
              }

              current.qboCustomers.forEach(data => {
                if (data.id == id) {
                  data.status = "Disconnected";
                }
              });

              this.spinnerService.hide();
              this.configForm.markAsPristine();
              this.resetSelected();
            },
            (error: any) => { this.errorMessage = <any>error; this.spinnerService.hide(); this.displayAlert('alert alert-danger', this.errorMessage) }
          )
        }
        catch (error) {
          console.error(error.message);
          this.spinnerService.hide();
        }
      }
    }
    else {
      alert('Session is timed out. Please log in again.')
      this.router.navigate(['/admin/signin']);
    }
  }

  dismissAlert() {
    this.showAlert = false
  }

  displayAlert(cssClass: string, alertMessage: string) {
    this.showAlert = true
    this.alertMessage = alertMessage
    this.alertCssClass = cssClass
    var scrollToTop = window.setInterval(function () {
      var pos = window.pageYOffset;
      if (pos > 0) {
        window.scrollTo(0, pos - 20); // how far to scroll on each step
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 16); // how fast to scroll (this equals roughly 60 fps)
  }
}