import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { userCredentials } from '../models/UserCredentials';
import { AvaTaxAccountValidationResponse } from '../models/AvaTaxAccountValidationResponse';
import { IHttpService } from '../../shared/services/IHttpService';
import { AppConfig } from '../../app.config';
import { OAuthConnectResponse, OAuthSessionResponse } from '../../shared/models/OAuthToken';
import { Constants } from '../../shared/models/Constants';
import { DialogService } from '../services/dialog.service';

@Injectable({
    providedIn: 'root'
})
export class CommonService implements IHttpService {
    private readonly signinUrl: string = AppConfig.settings.OAuthUrls.oAuthBaseUrl + "/api/signin";

    constructor(private http: HttpClient, private dialogService: DialogService) { }

    unauthorizationRedirect(error){
        if(error.status === 401){
            console.log('Http error:', error);
            this.dialogService.showDialogBox('unauthorizedErrorDialog');
          }
        }

        extendJwtDuration(): Promise<void> {
            return new Promise((resolve, reject) => {
                console.log('extendJwtDuration');
                let tokenString = localStorage.getItem('qbo_session');
                if (tokenString) {
                    console.log('tokenString', tokenString);
                    let token: OAuthSessionResponse = JSON.parse(tokenString);
                    console.log('token', token);
                    console.log('token.session_Id', token.session_Id);
                    
                    token.session_Id = token.session_Id.substring(0, token.session_Id.lastIndexOf('_'));
                    if (token) {
                        this.extendJwtDurationAsync(token.realmId, token.session_Id).subscribe(
                            (response: any) => {
                                token.session_Id = response.jwtToken + '_' + new Date().getTime();
                                localStorage.setItem('qbo_session', JSON.stringify(token));
                                console.log('extended');
                                resolve(); // Resolve the promise when done
                            },
                            (error: any) => {
                                console.log("extendJwtDuration error:" + error);
                                reject(error); // Reject the promise on error
                            }
                        );
                    } else {
                        resolve(); // Resolve if token is not found
                    }
                } else {
                    resolve(); // Resolve if no tokenString
                }
            });
        }
        

    getHelpLink(urlText){
        return Constants[urlText];
    }


    extendJwtDurationAsync(realmId: string, authToken:string): Observable<any> {
        const url = `${this.signinUrl}/ExtendJwtDuration/${realmId}`;
        const headers = new HttpHeaders().set('Authorization', `Bearer ${authToken}`)
        return this.http.post<any>(url, "",{headers: headers}).pipe(catchError((e: any) => this.handleError(e)));
    }

    handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
                `Backend returned code ${error.status}, ` +
                `body was: ${error.error}`);

            this.unauthorizationRedirect(error);
            return throwError('unauthorized');
        }
        // return an observable with a user-facing error message
        return throwError(
            'Something went wrong, please try again later.');
    };
}