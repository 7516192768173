import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';

export interface DeactivationGuarded {
  canDeactivate():  Promise<boolean> | boolean;
}


@Injectable({
  providedIn: 'root'
})
export class CandeactivateGuard implements CanDeactivate<DeactivationGuarded>   {
  canDeactivate(
    component: DeactivationGuarded
  ): Observable<boolean> | Promise<boolean> | boolean {
    return component.canDeactivate ? component.canDeactivate() : true;
  }
}
